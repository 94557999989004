import React from 'react'

import PropTypes from 'prop-types'

import styles from './SimpleTable.module.css'

import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PhotoIcon from '@material-ui/icons/Photo'
import Avatar from '@material-ui/core/Avatar'
import TableContainer from "@material-ui/core/TableContainer"

import { Button, TextField, Tooltip } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import Pagination from '@material-ui/lab/Pagination';
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
class SimpleTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
  }

  redirectTo(item) {
    this.setState({ redirect: true })
    this.setState({ pathItem: item })
  }

  handleChildClick(e) {
    e.stopPropagation();
  }

  render() {
    const pathName = '/' + this.props.model.replace(' ', '_').toLowerCase() + 's/'
    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        return (<Redirect
          to={
            this.props.model.toLowerCase() !== 'payroll' ? (
              this.props.model === 'Payroll Adjustment' ? (
                {
                  pathname: 'edit/' + this.state.pathItem.account_id,
                  state: { item: this.state.pathItem }
                }) : (
                {
                  pathname: pathName + this.state.pathItem.id,
                  state: { id: this.state.pathItem.id, item: this.state.pathItem }
                })
            ) : ({
              pathname: pathName + this.state.pathItem.id + '/payroll_details',
              state: { id: this.state.pathItem.id, item: this.state.pathItem }
            })
          }
        />)
      }
    }

    return (
      <>
        <>
          <div className={this.props.removeLabel !== true ? styles.tableTitle : styles.tableTitleNone}>
            <div className={styles.groupTileSearch}>
              <div className={styles.tileBody}>
                {this.props.icon}
                <h2 className={styles.title}>
                  <div className={styles.head}>
                    <div>{this.props.noActionHeader ? (null) : (this.props.model.replace('_', ' ') + 's')}</div>
                  </div>
                </h2>
              </div>
              {this.props.withSearch ? (
                <div>
                  <div className={styles.searchBody}>
                    <div className={styles.searchList}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={<div className={styles.searchBar}><SearchIcon />
                          <div>Search</div>
                        </div>}
                        size="small"
                        value={this.props.q}
                        className={styles.searchField}
                        onKeyPress={this.props._handleKeyPress}
                        onChange={this.props.handleChanges}
                        name="global_search"
                      >
                      </TextField>
                      {
                        this.props.model.toLowerCase() !== "document" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={styles.advancedSearch}
                            onClick={this.props.handleAdvanced}
                          >
                            Advanced Search
                          </Button>
                        ) : null
                      }

                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={this.props.clearSearch}
                      >
                        <SettingsBackupRestoreIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.rightAction}>
            {
                this.props.withGenerateLeave ? (
                <div className={styles.actionButton}>
                <Button
								variant="outlined"
								color="primary"
								type="submit"
								onClick={this.props.generateAnnualLeaves}
							>
								Generate Leave Credits
							</Button>
              </div>
                ) : null
              }
              {
                this.props.withPagination ? (
                  this.props.totalPages !== 1 ? (
                    <Pagination
                      className={styles.paginationWidth}
                      size="small"
                      count={this.props.totalPages}
                      page={this.props.currentPage}
                      color="primary"
                      onChange={this.props.handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  ) : null
                ) : null
              }

              {this.props.withPrint ? (
                <div className={styles.buttonDisbursement}>
                  {this.props.urlPrint.reports_url !== undefined ? (
                    <Tooltip title="Print Sale register" arrow>
                      <a href={`${this.props.urlPrint.reports_url}`} target="_blank"
                        rel="noopener noreferrer">
                        <PrintIcon />
                      </a>
                    </Tooltip>
                  ) : null}
                  {this.props.urlPrint.export_excel_url !== undefined ? (
                    <Tooltip title="Download excel File" arrow>
                      <a href={`${this.props.urlPrint.export_excel_url}`} target="_blank"
                        rel="noopener noreferrer">
                        <CloudDownloadIcon />
                      </a>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
               {this.props.model === "Tax Table" ? (
                <Button variant="outlined" color="primary" onClick={this.props.switchTable}><b>Switch table</b></Button>
              ) : null}
              {this.props.backIndex ? (
                <Tooltip title="Back To index" arrow>
                  <Link
                    to={{
                      pathname: '/payrolls/' + this.props.backToIndex + '/payroll_details'
                    }}
                  >
                    <ArrowBackIosOutlinedIcon />
                  </Link>
                </Tooltip>
              ) : (null)}
              {this.props.model.toLowerCase() !== 'disbursement register' ? (
                this.props.model.toLowerCase() !== 'check voucher' ? (
                  this.props.noActionHeader ? (
                    this.props.withAdd ? (
                      <div>
                        <IconButton color="inherit" aria-label="menu"
                          id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                          name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                          onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                          <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>

                      </div>
                    ) : (
                      null
                    )
                  ) : (

                    <div>
                      <IconButton color="inherit" aria-label="menu"
                        id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                        name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                        onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                        <AddCircleOutlineIcon fontSize="large" />
                      </IconButton>

                    </div>
                  )
                ) : (
                  this.props.checkVoucherPayee ? (
                    <Link
                      to={{
                        pathname: '/payees/' + this.props.payeeId + '/check_vouchers/new',
                        state: {
                          item: this.props.initialItem,
                        }
                      }}
                    >
                      <AddCircleOutlineIcon fontSize="large" />
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: '/check_voucher/new',
                        state: {
                          item: this.props.initialItem,
                        }
                      }}
                    >
                      <AddCircleOutlineIcon fontSize="large" />
                    </Link>
                  )
                )
              ) : null
              }
            </div>
          </div>
          <div className={styles.title}>
            {
              this.props.withResultsLabel ? (
                this.props.totalResults ? (
                  this.props.totalResults > 1 ?
                    (this.props.totalResults + ' results') :
                    (this.props.totalResults + ' result')
                ) :
                  (this.props.totalResults === 0 ? ('No Results found') : null)
              ) : null
            }
          </div>
          <TableContainer className={this.props.forPrint ? styles.tableBodyForPdf : this.props.customClassName} style={{ maxHeight: '575px', position: 'relative' }}>
            <Table size="small" stickyHeader aria-label="customized sticky table" className={styles.TableBody}
              name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
              <TableHead className={this.props.classNameHeader ? styles.tableHeadBody : styles.tableSetHead}>
                <TableRow>
                  {
                    this.props.headers.length > 0 ? (
                      this.props.headers.map((header) => (
                        header.toLowerCase() === 'actions' ? (
                          <TableCell key={header} className={styles.actions}
                            align="center"><strong>{header}</strong></TableCell>
                        ) : (
                          header.toLowerCase() === 'amount' ? (
                            <TableCell key={header} className={styles.actions}
                              align="right"><strong>Amount</strong></TableCell>) : (
                            header.includes('.') ? (
                              header.includes("name") ? (
                                this.props.model === "Leave Credit" ? (
                                  <TableCell key={header}
                                    className={styles.cell}><strong>Leave Type</strong></TableCell>
                                ) : (
                                  header.toLowerCase() === "employee.name_formal" ? (
                                    this.props.model.toLowerCase()==="recurrence"?(
                                      <TableCell key={header}
                                      className={styles.sameHeader}><strong>Employee </strong></TableCell>
                                    ):
                                   ( <TableCell key={header}
                                      className={styles.sameHeader}><strong>Employee Name</strong></TableCell>)
                                  ) : (
                                    header.toLowerCase() === "tax_status.name" ? (
                                      <TableCell key={header}
                                        className={`${styles.taxStatus} ${styles.cell}`}><strong>Tax Status</strong></TableCell>
                                    ) : (
                                      header.toLowerCase() === "leave_type.name" ? (
                                        <TableCell key={header}
                                          className={styles.cell}><strong>Leave Type</strong></TableCell>
                                      ) : (
                                        header.toLowerCase() === "officer.name" ? (
                                          <TableCell key={header}
                                            className={styles.cell}><strong>Approving Officer</strong></TableCell>
                                        ) : (
                                          header.toLowerCase() === "branch.name" ? (
                                            <TableCell key={header}
                                              className={styles.cell}><strong>Branch</strong></TableCell>
                                          ) : (
                                            header.toLowerCase() === "department.name" ? (
                                              <TableCell key={header}
                                                className={styles.cell}><strong>Department</strong></TableCell>
                                            ) : (
                                              header.toLowerCase() === "account.name" ? (
                                                <TableCell key={header} className={styles.cell}><strong>Account</strong></TableCell>
                                              ) : (
                                                header.toLowerCase() === "department.short_name" ? (
                                                  <TableCell key={header} className={styles.cell}><strong>Short Name</strong></TableCell>
                                                ) : (
                                                  <TableCell key={header} className={styles.cell}><strong>{header.toLowerCase().replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              ) : (
                                header.toLowerCase() === "employee.reference_no" ? (
                                  <TableCell key={header}
                                    className={styles.employeeRef}><strong>Employee No</strong></TableCell>
                                ) : (
                                  header.toLowerCase() === "employee.bank_account_no" ? (
                                    <TableCell key={header} className={styles.employeeRef}><strong>Account No.</strong></TableCell>
                                  ) : (
                                    <TableCell key={header}
                                      className={styles.cell}><strong>{header.split('.')[1].replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                                  )
                                )
                              )
                            ) : (
                              header.toLowerCase() === "without_pay" ? (
                                <TableCell key={header}
                                  className={styles.cell}><b>Paid</b>
                                </TableCell>
                              ) : (
                                header.toLowerCase() === "is_active" ? (
                                  <TableCell key={header}
                                    className={styles.cell}><b>Active</b>
                                  </TableCell>
                                ) : (
                                  this.props.model === "Leave Credit" && header.toLowerCase() === "no_of_days" ? (
                                    <TableCell key={header} align="center"
                                      className={styles.cell}><strong>Leave Credits</strong></TableCell>
                                  ) : (
                                    this.props.model === "Leave Credit" && header.toLowerCase() === "days_approved" ? (
                                      <TableCell key={header} align="center"
                                        className={styles.cell}><strong>Approved Leaves</strong></TableCell>
                                    ) : (
                                      this.props.model === "Leave Credit" && header.toLowerCase() === "days_applied" ? (
                                        <TableCell key={header} align="center"
                                          className={styles.cell}><strong>Pending for Approval</strong></TableCell>
                                      ) : (
                                        this.props.model === "Leave Credit" && header.toLowerCase() === "days_remaining" ? (
                                          <TableCell key={header} align="center"
                                            className={styles.cell}><strong>Remaining
                                              Credits</strong></TableCell>
                                        ) : (
                                          header.toLowerCase() === "without_pay" ? (
                                            <TableCell key={header} align="center"
                                              className={styles.cell}><strong>With Pay</strong></TableCell>
                                          ) : (
                                            header.toLowerCase() === "employee_bir" ? (
                                              <TableCell key={header} align="center"
                                                className={styles.cell}><strong>BIR</strong></TableCell>
                                            ) : (
                                              header.toLowerCase().includes("effect") ? (
                                                <TableCell key={header}
                                                  className={styles.cell}><strong>Effectivity
                                                    Date</strong></TableCell>
                                              ) : (
                                                header.toLowerCase().includes("employee_pagibig_no") ? (
                                                  <TableCell key={header} className={styles.sameHeader}><strong>Pagibig
                                                    No</strong></TableCell>
                                                ) : (
                                                  header.toLowerCase().includes("employee_sss_no") ? (
                                                    <TableCell key={header} className={styles.sameHeader} align="center"><strong>SSS No</strong></TableCell>
                                                  ) : (
                                                    header.toLowerCase().includes("employee_philhealth_no") ? (
                                                      <TableCell key={header} className={styles.sameHeader}><strong>Philhealth Id No</strong></TableCell>
                                                    ) : (
                                                      header.toLowerCase().includes("employee_pagibig") || header.toLowerCase().includes("employee_sss") || header.toLowerCase().includes("employee_philhealth") ? (
                                                        <TableCell key={header}
                                                          className={styles.sameHeader}><strong>Employee &#39;<span className={styles.withS}>s </span> Contribution</strong></TableCell>
                                                      ) : (
                                                        header.toLowerCase().includes("employer_pagibig") || header.toLowerCase().includes("employer_sss") || header.toLowerCase().includes("employer_philhealth") ? (
                                                          <TableCell key={header}
                                                            className={styles.sameHeader}><strong>Employer &#39;<span className={styles.withS}>s </span>
                                                              Contribution</strong></TableCell>
                                                        ) : (
                                                          header.toLowerCase().includes("total_pagibig") || header.toLowerCase().includes("total_sss") || header.toLowerCase().includes("total_philhealth") ? (
                                                            <TableCell key={header}
                                                              className={styles.cell}><strong>Total</strong></TableCell>

                                                          ) : (
                                                            header === "Employee name" ? (
                                                              <TableCell key={header}
                                                                         className={styles.cell}><b>User</b>
                                                              </TableCell>
                                                            ) : (
                                                            header.toLowerCase().includes("employer_ecc") ? (
                                                              <TableCell key={header}
                                                                className={styles.cell}><strong>ECC</strong></TableCell>

                                                            ) : (
                                                              header.toLowerCase() === "payroll_income" ? (
                                                                <TableCell key={header} className={styles.incomeCell}
                                                                  colSpan={3}>
                                                                  <div>Income</div>
                                                                  <TableCell key={header} align="center"><b>Basic</b>
                                                                  </TableCell><TableCell align="center" key={header}
                                                                  ><b>Overtime</b>
                                                                  </TableCell><TableCell align="center" key={header}
                                                                  ><b>Other</b>
                                                                  </TableCell>
                                                                </TableCell>
                                                              ) : (
                                                                header.toLowerCase() === "payroll_deductions" ? (
                                                                  <TableCell key={header} className={styles.incomeCell}
                                                                    colSpan={5}>
                                                                    <div>Deductions</div>
                                                                    <TableCell align="center" key={header}
                                                                    ><b>HDMF</b>
                                                                    </TableCell><TableCell align="center" key={header}
                                                                    ><b>PHILHEALTH</b>
                                                                    </TableCell><TableCell align="center" key={header}
                                                                    ><b>SSS</b>
                                                                    </TableCell><TableCell align="center" key={header}
                                                                      className={styles.withTax}><b>Withholding
                                                                        Tax</b>
                                                                    </TableCell><TableCell align="center" key={header}
                                                                    ><b>Other</b>
                                                                    </TableCell>
                                                                  </TableCell>
                                                                ) : (
                                                                  header.toLowerCase() === "over_percentage"?
                                                                   <TableCell key={header}
                                                                    className={styles.cell}><b>Status</b>
                                                                   </TableCell>:
                                                                  header.toLowerCase() === "column_no"?
                                                                  <TableCell key={header}
                                                                   className={styles.cell}><b>Column</b>
                                                                  </TableCell>:
                                                                 header.toLowerCase() === "starts_at"?
                                                                 <TableCell key={header}
                                                                  className={styles.cell}><b>Start Date</b>
                                                                 </TableCell>:
                                                                header.toLowerCase() === "valid_at"?
                                                                <TableCell key={header}
                                                                 className={styles.cell}><b>Validity Date</b>
                                                                </TableCell>:
                                                                header.toLowerCase() === "image_file_name"?
                                                                <TableCell key={header}
                                                                 className={styles.cell}><b>Filename</b>
                                                                </TableCell>:
                                                               <TableCell key={header}
                                                                 className={styles.cell}><b>{header.replace('_', ' ').replace('_', ' ')}</b>
                                                               </TableCell>
                                                                )
                                                              )
                                                              )
                                                            )
                                                          )
                                                        )
                                                      )
                                                    )
                                                  )
                                                )
                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )

                              )
                            )
                          )
                        )
                      )
                      )
                    ) : (
                      null
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                {
                  this.props.items
                    .map((item, index) => (
                      <>
                        <TableRow key={index} onClick={this.props.model.toLowerCase() === 'payroll'
                        || this.props.model.toLowerCase() === 'employee'
                        || this.props.model.toLowerCase() === 'memo' || this.props.model.toLowerCase() === 'payroll adjustment' ? () => this.redirectTo(item) :null}
                          className={this.props.model.toLowerCase() === 'payroll'
                            || this.props.model.toLowerCase() === 'employee'
                            || this.props.model.toLowerCase() === 'memo' || this.props.model.toLowerCase() === 'payroll adjustment' ? styles.linkable : styles.notLinkable}>
                          {
                            this.props.headers.length > 0 ? (
                              this.props.headers.map((header, idx) => (
                                header.includes(".") ? (
                                  header === "legal_case.client_uids" ? (
                                    <TableCell key={header + idx.toString}
                                      className={styles.cell}>
                                      {this.getClientNames(item.legal_case)}
                                    </TableCell>
                                    ) : (
                                      header.toLowerCase() === "officer.name" ?
                                      <TableCell key={header + idx.toString}
                                          className={styles.cell}>
                                          {item.name_formal}
                                        </TableCell>:
                                      header === "employee.name_formal" && this.props.model.toLowerCase() === "recurrence" ? (
                                        <TableCell key={header + idx.toString}
                                          className={styles.cell}>
                                          {
                                            index>0?(
                                              this.props.items[index-1].employee.name_formal === item.employee.name_formal? null: item.employee.name_formal
                                            ):item.employee.name_formal
                                          }
                                        </TableCell>) :
                                        (
                                          <TableCell key={header} className={styles.cell}>
                                            <>{item[header.split(".")[0]] ? (item[header.split(".")[0]][header.split(".")[1]]) : ("n/a")}</>
                                          </TableCell>
                                        )
                                    )
                                ) : (
                                  header.toLowerCase().includes("amount") || header.toLowerCase().includes("pay") || header.toLowerCase().includes("income")
                                    || header.toLowerCase().includes("sss") || header.toLowerCase().includes("bir") || header.toLowerCase().includes("pagibig")
                                    || header.toLowerCase().includes("philhealth") || header.toLowerCase().includes("deductions") || header.toLowerCase().includes("rate")
                                    || header.toLowerCase() === "over_time"|| header.toLowerCase() === "exemption"|| header.toLowerCase() === "over_percentage" ? (

                                    header.toLowerCase() === "payroll_period" ? (
                                      this.props.model === "Payroll" || this.props.model === "Employee Payroll" ? (
                                        <TableCell key={header}
                                          className={styles.cell}>
                                          {moment(item.payroll.date_from).format('MMM DD')}-{moment(item.payroll.date_to).format('DD YYYY')}
                                        </TableCell>
                                      ) : (
                                        <TableCell key={header}
                                          className={styles.cell}>
                                          {item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                        </TableCell>
                                      )

                                    ) :
                                      header.toLowerCase() === "without_pay" ? (
                                        <TableCell key={header} className={styles.linkable}>
                                          {item.without_pay === true ? ("Yes") : ("No")}
                                        </TableCell>
                                      ) : (
                                        header.toLowerCase() === "payroll_income" ? (
                                          <>
                                            <TableCell key={header}
                                              className={styles.amount}>
                                              {item["basic_income"] ? (parseFloat(item["basic_income"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                            </TableCell>
                                            <TableCell key={header}
                                              className={styles.amount}>
                                              {item["over_time"] ? (parseFloat(item["over_time"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                            </TableCell>
                                            <TableCell key={header}
                                              className={styles.amount}>
                                              {item["other_income"] ? (parseFloat(item["other_income"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                            </TableCell>
                                          </>
                                        ) :
                                          header.toLowerCase() === "payroll_deductions" ? (
                                            <>
                                              <TableCell align="center" key={header}
                                                className={styles.amount}>
                                                {item["employee_pagibig"] ? (parseFloat(item["employee_pagibig"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                              </TableCell>
                                              <TableCell align="center" key={header}
                                                className={styles.amount}>
                                                {item["employee_philhealth"] ? (parseFloat(item["employee_philhealth"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                              </TableCell>
                                              <TableCell align="center" key={header}
                                                className={styles.amount}>
                                                {item["employee_sss"] ? (parseFloat(item["employee_sss"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                              </TableCell>
                                              <TableCell align="center" key={header}
                                                className={styles.amount}>
                                                {item["employee_bir"] ? (parseFloat(item["employee_bir"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                              </TableCell>
                                              <TableCell align="center" key={header}
                                                className={styles.amount}>
                                                {item["other_deductions"] ? (parseFloat(item["other_deductions"])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                              </TableCell>
                                            </>
                                          ) :
                                            header.toLowerCase() === "payroll_type" ? (
                                              index > 0 ? (
                                                item.payroll_type === this.props.items[index-1].payroll_type ? (
                                                  <TableCell key={header}
                                                  className={styles.cell}>
                                                </TableCell>
                                                ) : (
                                                  <TableCell key={header}
                                                    className={styles.cell}>
                                                    {item[header.toLowerCase()] ? (<b>{item[header.toLowerCase()].replace('_', ' ')}</b>) : ("n/a")}
                                                  </TableCell>
                                                )
                                              ) : (
                                                <TableCell key={header}
                                                  className={styles.cell}>
                                                  {item[header.toLowerCase()] ? (<b>{item[header.toLowerCase()].replace('_', ' ')}</b>) : ("n/a")}
                                                </TableCell>
                                              )
                                            ) : (
                                              <TableCell key={header} className={styles.amount}>
                                                {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                              </TableCell>
                                            )
                                      )

                                  ) : (
                                    header.toLowerCase().includes("total_sss") || header.toLowerCase().includes("total_pagibig") || header.toLowerCase().includes("total_philhealth") ? (
                                      <TableCell key={header} className={styles.amount}>
                                        {item[header.toLowerCase()] ? (parseFloat(item[header.toLowerCase()])).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : ("n/a")}
                                      </TableCell>
                                    ) : (
                                      header.toLowerCase() === "deduction" ? (
                                        <TableCell key={header} className={styles.linkable}>
                                          {item.is_deduction === true ? ("Yes") : ("No")}
                                        </TableCell>
                                      ) : (
                                        header.toLowerCase() === "taxable" ? (
                                          <TableCell key={header} className={styles.linkable}>
                                            {item.is_taxable === true ? ("Yes") : ("No")}
                                          </TableCell>
                                        ) : (
                                          header.toLowerCase() === "employee_names" ? (
                                            item.employee_names ? (
                                              item.employee_names.length > 0 ? (
                                                <TableCell className={styles.cell}>
                                                  {item.employee_names.map((employee, idx) => (
                                                    <li key={idx}
                                                      className={styles.cell}>
                                                      {employee}
                                                    </li>
                                                  ))}
                                                </TableCell>
                                              ) : null
                                            ) : null

                                          ) : (
                                            header.toLowerCase().includes("date") || header.toLowerCase() === "starts_at"|| header.toLowerCase() === "valid_at" ? (
                                              header === "inclusive_dates" ? (
                                                <TableCell key={header}
                                                  className={styles.cell}>
                                                  {item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                                </TableCell>
                                              ) : (<TableCell key={header}
                                                className={styles.cell}>
                                                {item[header.toLowerCase()] ? (new Date(item[header.toLowerCase()]).toLocaleDateString('en-Us', { month: '2-digit', day: '2-digit', year: '2-digit' })) : ("n/a")}
                                              </TableCell>)

                                            ) : (
                                              header.toLowerCase() === "active" || header.toLowerCase() === "is_active" || header.toLowerCase() === "without_pay" ? (
                                                <TableCell key={header}
                                                  className={styles.cell}>
                                                  {item[header.toLowerCase()] ? ("Yes") : ("No")}
                                                </TableCell>
                                              ) : (
                                                this.props.model === "Leave Credit" && header.toLowerCase() === "days_approved" ? (
                                                  <TableCell key={header}
                                                    align="center"
                                                    className={styles.cell}>
                                                    {item[header.toLowerCase()].toString()}
                                                  </TableCell>
                                                ) : (
                                                  this.props.model === "Leave Credit" && header.toLowerCase() === "days_applied" ? (
                                                    <TableCell key={header}
                                                      align="center"
                                                      className={styles.cell}>
                                                      {item[header.toLowerCase()].toString()}
                                                    </TableCell>
                                                  ) : (
                                                    this.props.model === "Leave Credit" && header.toLowerCase() === "days_remaining" ? (
                                                      <TableCell key={header}
                                                        align="center"
                                                        className={styles.cell}>
                                                        {item[header.toLowerCase()].toString()}
                                                      </TableCell>
                                                    ) : (
                                                      this.props.model === "Leave Credit" && header.toLowerCase() === "no_of_days" ? (
                                                        <TableCell key={header}
                                                          align="center"
                                                          className={styles.cell}>
                                                          {item[header.toLowerCase()].toString()}
                                                        </TableCell>
                                                      ) : (
                                                        header.toLowerCase() === "without_pay" ? (
                                                          <TableCell key={header}
                                                            align="center"
                                                            className={styles.cell}>
                                                            {item[header.toLowerCase()] ? ("No") : ("Yes")}
                                                          </TableCell>
                                                        ) : (
                                                          header.toLowerCase() === "column_no" || header.toLowerCase() === "rank" ? (
                                                            <TableCell key={header}
                                                              align="center"
                                                              className={styles.cell}>
                                                              {item[header.toLowerCase()] ? (item[header.toLowerCase()].toString()) : ("n/a")}
                                                            </TableCell>
                                                          ) : (
                                                            header.toLowerCase() === "image_file_name" ? (
                                                              <TableCell key={header}
                                                                className={styles.imageCell}>
                                                                {item[header.toLowerCase()] ? (
                                                                   <div className={styles.flex}> <Avatar src={item.image.normal}>
                                                                   <PhotoIcon  size="small" />
                                                                   </Avatar>
                                                                      <a rel="noreferrer" href={item.image.normal} target="_blank"> {item.image_file_name}</a></div>
                                                                  ) : ("n/a")}
                                                              </TableCell>
                                                            ) : (
                                                              header === "Employee name" ? (
                                                                item.employee_name.length > 0 ? (
                                                                  <TableCell key={header} className={styles.employeeNameWidth}>
                                                                    {item.employee_name.map((name) => (
                                                                        <li key={name.id}> {name.name_formal}</li>
                                                                      )
                                                                    )}
                                                                  </TableCell>
                                                                ):<TableCell> <li>n/a</li></TableCell>
            
                                                              ) : (
                                                                  <TableCell key={header} className={styles.cell}>
                                                                    {item[header.toLowerCase()] ? (item[header.toLowerCase()].replace('_', ' ')) : ("n/a")}
                                                                  </TableCell>
                                                                )
                                                              )
                                                            )

                                                          )
                                                        )

                                                    )

                                                  )
                                                )

                                              )
                                            )
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                              )
                            ) : (
                              null
                            )
                          }
                          <TableCell onClick={this.handleChildClick} size="small" className={this.props.model.toLowerCase() !== 'payroll detail' || this.props.model.toLowerCase() !== 'recurrence'
                           || this.props.model.toLowerCase() !== 'memo' ? styles.actionButtonHoverable : styles.fullWitdActionHoverable}>
                            {
                              this.props.withShowPopUp ? (
                                <Tooltip title="Show" arrow>
                                  <IconButton
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                    size="small"
                                    color="primary"
                                    onClick={() => this.props.handleShowPopUp(item)}>
                                    <VisibilityIcon color="primary" fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              ) : (null)
                            }
                            {this.props.nodelete ? null : item.can_delete === true ? (
                              this.props.model.toLowerCase() !== "leave record" ? (
                                <Tooltip title="Delete" arrow>
                                  <IconButton
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                    size="small"
                                    color="secondary"
                                    onClick={() => this.props.onOpenDeletePopup(item, this.props.model)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null
                            ) : null}
                            {item.can_edit === true ? (
                              this.props.withEdit ? (
                                <Tooltip title="Edit" arrow>
                                  <IconButton
                                    size="small"
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                    color="primary"
                                    onClick={() => this.props.handleClick(item, false, this.props.model)}>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : (null)) : null
                            }
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                }
              </TableBody>
              {this.props.ifTotal ? (
                <TableRow>
                  <TableCell className={styles.textAlignRight}></TableCell>
                  <TableCell className={styles.textAlignRight}><b>Total :</b></TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.getBasic}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.overTime}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.otherIncome}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.gross}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.hdmf}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.philHealth}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.sss}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.bir}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.otherDec}</TableCell>
                  <TableCell className={styles.totalTextAlignRight}>{this.props.net}</TableCell>
                </TableRow>
              ) : null}
            </Table>
          </TableContainer>
        </>
      </>

    )
  }
}

export default SimpleTable

SimpleTable.propTypes = {
  model: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  journalEntries: PropTypes.array,
  onOpenDeletePopup: PropTypes.func,
  handleClick: PropTypes.func,
  initialItem: PropTypes.object,
  item: PropTypes.object,
  withShow: PropTypes.bool,
  icon: PropTypes.object,
  withEdit: PropTypes.bool,
  withShowPopUp: PropTypes.bool,
  withSearch: PropTypes.bool,
  customClassName: PropTypes.string,
  handleShowPopUp: PropTypes.func,
  handleAdvanced: PropTypes.func,
  handleChanges: PropTypes.func,
  _handleKeyPress: PropTypes.func,
  clearSearch: PropTypes.func,
  q: PropTypes.string,
  prevPath: PropTypes.string,
  withPagination: PropTypes.bool,
  handlePageClick: PropTypes.func,
  pageCount: PropTypes.number,
  displayedPages: PropTypes.number,
  displayedRange: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  changeIndex: PropTypes.func,
  showState: PropTypes.bool,
  withStateButton: PropTypes.bool,
  withCalendar: PropTypes.bool,
  handlePageChange: PropTypes.func,
  toggleView: PropTypes.func,
  checkVoucherPayee: PropTypes.bool,
  withPrint: PropTypes.bool,
  urlPrint: PropTypes.string,
  currentPage: PropTypes.number,
  activePage: PropTypes.number,
  totalResults: PropTypes.number,
  withResultsLabel: PropTypes.bool,
  removeLabel: PropTypes.bool,
  payeeId: PropTypes.number,
  noActionHeader: PropTypes.bool,
  withAdd: PropTypes.bool,
  linkable: PropTypes.bool,
  getTotalAmount: PropTypes.string,
  getBasic: PropTypes.string,
  overTime: PropTypes.string,
  gross: PropTypes.string,
  hdmf: PropTypes.string,
  philHealth: PropTypes.string,
  sss: PropTypes.string,
  bir: PropTypes.string,
  otherIncome: PropTypes.string,
  net: PropTypes.string,
  otherDec: PropTypes.string,
  ifTotal: PropTypes.bool,
  classNameHeader: PropTypes.bool,
  backIndex: PropTypes.bool,
  backToIndex: PropTypes.string,
  nodelete: PropTypes.bool,
  switchTable: PropTypes.func,
  withGenerateLeave: PropTypes.bool,
  forPrint: PropTypes.bool,
  generateAnnualLeaves: PropTypes.func,
}
