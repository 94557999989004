import React from 'react'
import styles from './Divisions.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button
} from '@material-ui/core'

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class DivisionsForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <div className={styles.groupFields}>
            <div className={styles.branchField}>
              <Autocomplete
                value={this.props.updateBranch}
                id="branch_id"
                size="small"
                fullWidth
                name="branch_id"
                className={styles.formTextfield}
                options={this.props.branches.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                renderInput={(params) => (
                  <TextField {...params} name="branch_id" variant="outlined" label="Branch Name" required/>
                )}
              />
            </div>
            <div className={styles.departmentField}>
            <Autocomplete
              value={this.props.updateDepartment}
              id="department_id"
              size="small"
              disabled={!this.props.departments.length>0}
              fullWidth
              name="department_id"
              className={styles.formTextfield}
              options={this.props.departments}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocomplete}
              
              renderInput={(params) => (
                <TextField {...params} name="user_ids" variant="outlined" label="Department" required/>
              )}
            />
            </div>
          </div>
          <div className={styles.groupFields}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />

            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="short_name"
              fullWidth="true"
              label="Short Name"
              onChange={this.props.onchange}
              value={this.props.item.short_name}
              id="short_name"
            />
          </div>
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="description"
            fullWidth="true"
            label="Description"
            onChange={this.props.onchange}
            value={this.props.item.description}
            id="description"
          />
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"
                  id="divisions-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default DivisionsForm

DivisionsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  branches: PropTypes.array,
  departments: PropTypes.array,
  item: PropTypes.object,
  updateBranch: PropTypes.object,
  updateDepartment: PropTypes.object,
  submit: PropTypes.func
}
