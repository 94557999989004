import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import AnnouncementForm from './AnnouncementForm'
import { Redirect } from 'react-router'

class AnnouncementNewAndUpdate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            load: false,
            announcement: {
                id: '',
                title: '',
                content: ''
            },
            redirect: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleCKEditor = this.handleCKEditor.bind(this)
    }


    componentDidMount() {
        if (this.props.match.params.id !== undefined) {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/announcements/' + this.props.match.params.id,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ announcement: resp.data })
                this.loadAnnouncementForm()
            }).catch(error => console.log(error.response))

        } else {
            this.loadAnnouncementForm()
        }
    }

    loadAnnouncementForm() {
        this.setState({ load: true })
    }

    handleInputChange(e) {
        this.setState({
            announcement: {
                ...this.state.announcement,
                [e.target.name]: e.target.value
            }
        })
    }


    handleAutocomplete(event, values) {
        this.setState({ branch: values })
    }


    handleSubmit() {
        const item = this.state.announcement
        var method = ''
        var url = ''

        if (item.id === '') {
            method = 'post'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/announcements/'
        } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/announcements/' + item.id
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {

            this.setState({redirect: true})
            this.setState({ openPopup: false })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleCKEditor(event, editor){
        const data = editor.getData()
        this.setState({
          announcement: {
            ...this.state.announcement,
            content: data
          }
        })
      }



    render() {
        const { redirect } = this.state

        if (redirect)
            return (<Redirect to={{
                pathname: '/announcements/'
            }} />)
        return (

            this.state.load ? (
               <AnnouncementForm
                item={this.state.announcement}
                handleChange={this.handleInputChange}
                submit={this.handleSubmit}
                handleCKEditor={this.handleCKEditor}
               />
            ) : null
        )
    }
}

export default AnnouncementNewAndUpdate

AnnouncementNewAndUpdate.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    location: PropTypes.object
}
