import React from 'react'
import styles from './Employees.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
class SalaryHistoryForm extends React.Component {
    render() {
        return (
            <div>
                <form>
                    <div className={styles.flex}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.leftTextField}
                            variant="outlined"
                            size="small"
                            name="date_effective"
                            fullWidth={true}
                            label="Date Effective"
                            onChange={this.props.handleSalaryChange}
                            value={this.props.salaries_attributes.date_effective}
                            id="employee-date_effective"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <NumberFormat
                            customInput={TextField}
                            autoComplete="off"
                            variant="outlined"
                            className={styles.amountBody}
                            size="small"
                            name="rate"
                            fullWidth
                            label="Rate"
                            thousandSeparator={true}
                            onValueChange={this.props.handleRateChange}
                            value={this.props.salaries_attributes.rate}
                            decimalSeparator="."
                            fixedDecimalScale={true}
                            prefix={'₱'}
                        />
                    </div>
                    <TextField
                        autoComplete="off"
                        className={styles.textFields}
                        variant="outlined"
                        size="small"
                        name="remarks"
                        fullWidth
                        label="Remarks"
                        onChange={this.props.handleSalaryChange}
                        value={this.props.salaries_attributes.remarks}
                        id="employee-salary_remarks"
                    />
                </form>
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="ApprovingOfficer-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
                </div>
            </div>
        )
    }
}
export default SalaryHistoryForm

SalaryHistoryForm.propTypes = {
    salaries_attributes: PropTypes.object,
    handleRateChange: PropTypes.func,
    submit: PropTypes.func,
    handleSalaryChange: PropTypes.func
}
