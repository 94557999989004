import React from 'react'
import styles from './LeaveTypes.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button
} from '@material-ui/core'

class LeaveTypesForm extends React.Component {
  render() {
    console.log(this.props.item)
    return (
      <div>
        <form>
          <div className={styles.groupFields}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />

            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              type="number"
              name="no_of_days"
              fullWidth="true"
              label="Number of Day"
              onChange={this.props.onchange}
              value={this.props.item.no_of_days}
              id="no_of_days"
            />
          </div>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"
                  id="leaveType-submit" color="primary"
                  disabled={!this.props.item.name || !this.props.item.no_of_days}
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default LeaveTypesForm

LeaveTypesForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func
}
