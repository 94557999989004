// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import LoanReportSearchForm from './LoanReportSearchForm'
import SimpleTableGroupBy from '../../shared/SimpleTable/SimpleTableGroupBy'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
export const Component = withRouter(() => {

})
class LoanReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Loan Report',
      loanReports: [],
      loanReport: {},
      employees: [],
      selectedEmployees: [],
      title: '',
      load: false,
      tableHead: ['account_name', 'loan_date', 'maturity_date', 'principal_amount', 'loan_payment', 'balance'],
      withShow: false,
      withEdit: true,
      groupLabel: 'Employee Name',
      mdWidth: 'md',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      redirectToShow: false,
      searchLoanReport: {},
      totalPages: 1,
      currentPage: 1,
      withPagination: true,
      totalResults: 0,
      withResultsLabel: false,
      payrollAccounts: [],
      urlPrint: {}

    }
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.search = this.search.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleAccounts = this.handleAccounts.bind(this)
  }

  componentDidMount() {
    var url = ''
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/payroll_adjustments/search?'
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?is_active=true&per_page=50000',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ employees: resp.data.employees })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_accounts',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ payrollAccounts: resp.data })
          this.setState({ load: true })
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))
    } else {
      var searchItem = {}
      url = process.env.REACT_APP_API_DOMAIN + '/v1/payroll_adjustments/search?'
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('start_date') !== null && query.get('start_date') !== '') {
        searchItem["start_date"] = query.get('start_date')
        urlOptions.push("start_date=" + query.get('start_date'))
      }
      if (query.get('end_date') !== null && query.get('end_date') !== '') {
        searchItem["end_date"] = query.get('end_date')
        urlOptions.push("end_date=" + query.get('end_date'))
      }
      if (query.get('account_id') !== null && query.get('account_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_accounts/' + query.get('account_id'),
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          searchItem["payrollAccount"] = resp.data
        }).catch(error => console.log(error.response))
        urlOptions.push("account_id=" + query.get('account_id'))
      }
      if (query.get('employee_ids') !== undefined && query.get('employee_ids') !== null) {
        var empids = query.get('employee_ids').split(',')
        var searchItemEmployees = []
        empids.map((queryEmployee) => {
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + queryEmployee,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            searchItemEmployees.push(resp.data)
          }).catch(error => console.log(error.response))
        })
        searchItem["employees"] = searchItemEmployees
        urlOptions.push("employee_ids=" + query.get('employee_ids'))
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      this.setState({ currentPage: parseInt(query.get('page')) })
      this.setState({ withResultsLabel: true })
      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({ searchLoanReport: searchItem })
      this.loadLoanReports(url)
    }
  }

  loadLoanReports(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newItems = []
      resp.data.payroll_adjustments.map((payroll) => {
        var item = {}
        item = payroll
        if (payroll.recurrence_id !== null) {
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/recurrences/' + payroll.recurrence_id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            item["recurrence"] = resp.data
          }).catch(error => console.log(error.response))
        }
        newItems.push(item)
      })
      this.setState({ loanReports: newItems })
      this.setState({ urlPrint: resp.data.meta })
      this.setState({ currentPage: resp.data.meta.current_page })
      this.setState({ totalResults: resp.data.meta.total_count })
      this.setState({ totalPages: resp.data.meta.total_pages })

    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?is_active=true&per_page=50000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employees: resp.data.employees })

    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_accounts',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payrollAccounts: resp.data })

    }).catch(error => console.log(error.response))
    this.setState({ load: true })
  }


  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }
  handleAutocomplete(event, values) {
    this.setState({ selectedEmployees: values })
  }


  handleSearchInput(e) {
    if (e.target.name === "start_date") {
      var dateStart = moment(e.target.value).format('YYYY-MM-DD')
      var lastDay = moment(dateStart).endOf('month').format('YYYY-MM-DD')
      this.setState({
        searchLoanReport: {
              ...this.state.searchLoanReport,
              [e.target.name]: e.target.value,
              end_date: lastDay
          }
      })
  }
  else {
      this.setState({
      searchLoanReport: {
        ...this.state.searchLoanReport,
        [e.target.name]: e.target.value
      }
    })
  }
  }

  search() {
    this.setState({ load: false })
    var url = process.env.REACT_APP_API_DOMAIN + "/v1/payroll_adjustments/search?"
    var searchUrl = ''
    var solrUrl = ''
    var options = []
    var searchOpts = []
    if (this.state.searchLoanReport.start_date !== undefined && this.state.searchLoanReport.start_date !== '') {
      options.push("start_date=" + this.state.searchLoanReport.start_date)
      searchOpts.push("start_date=" + this.state.searchLoanReport.start_date)
    }
    if (this.state.searchLoanReport.end_date !== undefined && this.state.searchLoanReport.end_date !== '') {
      options.push("end_date=" + this.state.searchLoanReport.end_date)
      searchOpts.push("end_date=" + this.state.searchLoanReport.end_date)
    }

    if (this.state.searchLoanReport.payrollAccount !== null && this.state.searchLoanReport.payrollAccount !== undefined && this.state.searchLoanReport.payrollAccount.id !== undefined && this.state.searchLoanReport.payrollAccount !== '') {
      options.push("account_id=" + this.state.searchLoanReport.payrollAccount.id)
      searchOpts.push("account_id=" + this.state.searchLoanReport.payrollAccount.id)
    }
    if (this.state.searchLoanReport.employees !== null && this.state.searchLoanReport.employees !== undefined) {
      if (this.state.searchLoanReport.employees.length !== null) {
        var empids = ""
        this.state.searchLoanReport.employees.map((empid, idx) => {
          if (idx === this.state.searchLoanReport.employees.length - 1) {
            empids = empids + empid.id
          } else {
            empids = empids + empid.id + ','
          }
        })
        options.push("employee_ids=" + empids)
        searchOpts.push("employee_ids=" + empids)
      }
    }
    options.map((option, idx) => {
      if (idx > 0) {
        searchUrl = searchUrl + "&" + option
      } else {
        searchUrl = searchUrl + option
      }
    })
    searchOpts.map((option, idx) => {
      if (idx > 0) {
        solrUrl = solrUrl + "&" + option
      } else {
        solrUrl = solrUrl + option
      }
    })
    url = url + solrUrl
    this.loadLoanReports(url)
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchUrl)
    history.push({ search: searchUrl })
  }

  handlePageChange(event, value) {
    var url = ''
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/payroll_adjustments/search?'
      this.setState({ withResultsLabel: false })
    } else {
      var searchItem = {}
      url = process.env.REACT_APP_API_DOMAIN + '/v1/payroll_adjustments/search?'
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('start_date') !== null && query.get('start_date') !== '') {
        searchItem["start_date"] = query.get('start_date')
        urlOptions.push("start_date=" + query.get('start_date'))
      }
      if (query.get('end_date') !== null && query.get('end_date') !== '') {
        searchItem["end_date"] = query.get('end_date')
        urlOptions.push("end_date=" + query.get('end_date'))
      }
      if (query.get('account_id') !== null && query.get('account_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_accounts/' + query.get('account_id'),
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          searchItem["account"] = resp.data
        }).catch(error => console.log(error.response))
        urlOptions.push("account_id=" + query.get('account_id'))
      }
      if (query.get('employee_ids') !== undefined && query.get('employee_ids') !== null) {
        var empids = query.get('employee_ids').split(',')
        var searchItemEmployees = []
        empids.map((queryEmployee) => {
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + queryEmployee,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            searchItemEmployees.push(resp.data)
          }).catch(error => console.log(error.response))
        })
        searchItem["employees"] = searchItemEmployees
        urlOptions.push("employee_ids=" + query.get('employee_ids'))
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      this.setState({ currentPage: parseInt(query.get('page')) })
      this.setState({ withResultsLabel: true })
      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({ searchLoanReport: searchItem })
    }

    this.loadLoanReports(url + "&page=" + value)
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("page", value)
    history.push({ search: params.toString() })
  }


  clearSearch() {
    this.setState({ searchLoanReport: { start_date: '', end_date: '', payrollAccount: "", employees: [] } })
    this.setState({ loanReports: [], urlPrint: {} })
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.search()
    }
  }

  handleAccounts(event, values) {
    if (event.target.id.includes("payrollAccounts")) {
      this.setState({
        searchLoanReport: {
          ...this.state.searchLoanReport,
          payrollAccount: values
        }
      })
    } else {
      this.setState({
        searchLoanReport: {
          ...this.state.searchLoanReport,
          employees: values
        }
      })
    }

  }

  render() {
    function groupArrayOfObjects(list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
    var dataArray = this.state.loanReports.filter(item => item.payroll_account.is_deduction === true)
    var clientsGroupBy = groupArrayOfObjects(dataArray, "name");
    return (
      <>
        {
          this.state.load ? (
            <div>
              <LoanReportSearchForm
                employees={this.state.employees}
                item={this.state.searchLoanReport}
                handleChange={this.handleSearchInput}
                search={this.search}
                clearSearch={this.clearSearch}
                handleKeyPress={this.handleKeyPress}
                handleAutocomplete={this.handleAccounts}
                payrollAccounts={this.state.payrollAccounts}

              />

              <SimpleTableGroupBy
                initialItem={this.state.initialProduct}
                items={clientsGroupBy}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShowPopUp={this.state.withShowPopUp}
                handleShowPopUp={this.handleShowPopUp}
                withEdit={this.state.withEdit}
                withPagination={true}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                groupLabel={this.state.groupLabel}
                loopedHeaders={true}
                urlPrint={this.state.urlPrint}
                withPrint={true}
                icon={<PaymentOutlinedIcon/>}

              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default withRouter(LoanReports)
LoanReports.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
