import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Typography } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp'
import style from '../../App.module.css'
import clsx from 'clsx'
class Appbar extends React.Component {
    render() {
        return (
            <AppBar
                position="fixed"
                className={clsx(style.appBar, {
                    [style.appBarShift]: this.props.open
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.props.toggleDrawer}
                        edge="start"
                        className={clsx(style.menuButton, {
                            [style.hide]: this.props.open
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap
                        style={{ flexGrow: 1 }}
                    >
                        {this.props.title}
                    </Typography>
                    <div className={style.headerCurrentUser}>
                        <h3><b>Welcome, <span>{this.props.currentUser.name}</span></b></h3>
                    </div>
                  <Tooltip title="logout"><IconButton
                    onClick={this.props.logOut}
                    style={{ color: '#fff' }}
                    aria-label="logout"
                  ><ExitToAppSharpIcon/></IconButton></Tooltip>
                </Toolbar>
            </AppBar>
            )
    }
}
export default Appbar

Appbar.propTypes = {
    toggleDrawer: PropTypes.func,
  logOut: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    currentUser: PropTypes.object,
}
