// eslint-disable-next-line
import React from 'react'

import PropTypes from 'prop-types'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import EmployeeForm from './EmployeeForm.js'
import EmployeesSearch from './EmployeesSearch'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'

import axios from 'axios'
import { withRouter, Redirect } from 'react-router-dom'

import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

export const Component = withRouter(() => {

})

class Employees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Employee',
            employees: [],
            openPopup: false,
            openDeletePopup: false,
            employee: {},
            initialEmployee: { id: '', name: '', address: '', classification: 'vatable', tin: '', business_style: '' },
            title: '',
            load: false,
            tableHead: ['reference_no','name_formal', 'branch.name', 'department.name', 'position.name', "is_active"],
            withShow: false,
            withEdit: true,
            maxWidth: 'sm',
            error_messages: '',
            notify: {},
            isOpen: false,
            message: '',
            type: '',
            searchEmployee: {is_active: true},
            draftEmployees: [],
            totalPages: 1,
            currentPage: 1,
            withPagination: true,
            totalResults: 0,
            withResultsLabel: false,
            redirect: false,
            departments: [],
            positions: []
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
        this.handleDeleteClose = this.handleDeleteClose.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handlePositions = this.handlePositions.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
    }

    componentDidMount() {
        var url = ''
        if (this.props.location.search === '') {
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?is_active=true&per_page=500'
            this.setState({ withResultsLabel: false })
        } else {
            var searchItem = {}
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?'
            const query = new URLSearchParams(this.props.location.search);
            var urlOptions = []
            if (query.get('name') !== null && query.get('name') !== '') {
                searchItem["name"] = query.get('name')
                urlOptions.push("name=*" + query.get('name') + '*')
            }
            if (query.get('department_name') !== undefined && query.get('department_name') !== null && query.get('department_name') !== '') {
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    var deptSearch = {}
                     resp.data.map((dept)=>{
                        if(dept.name===query.get('department_name')){
                            deptSearch = dept
                        }
                     })
                     searchItem["department"] = deptSearch

                })
                urlOptions.push("department_name=*" + query.get('department_name') + '*')
            }
            if (query.get('position_name') !== undefined && query.get('position_name') !== null && query.get('position_name') !== '') {
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/positions',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    var postSearch = {}
                     resp.data.map((post)=>{
                        if(post.name===query.get('position_name')){
                            postSearch = post
                        }
                     })
                     searchItem["position"] = postSearch

                })
                urlOptions.push("position_name=*" + query.get('position_name') + '*')
            }

            if (query.get('employment_status') !== null && query.get('employment_status') !== '') {
                searchItem["employment_status"] = query.get('employment_status')
                urlOptions.push("employment_status=" + query.get('employment_status'))
            }
            if (query.get('is_active') !== null && query.get('is_active') !== '') {
                searchItem["is_active"] = JSON.parse(query.get('is_active'))
                urlOptions.push("is_active=" + query.get('is_active'))
            }else{
                urlOptions.push("is_active=true")
                searchItem["is_active"] = true
            }
            if (query.get('page') !== null && query.get('page') !== '') {
                urlOptions.push("page=" + query.get('page'))
            }
            this.setState({ currentPage: parseInt(query.get('page')) })
            this.setState({ withResultsLabel: true })

            if (urlOptions.length > 0) {
                urlOptions.map((urlOption, idx) => {
                    if (idx > 0) {
                        url = url + "&" + urlOption
                    } else {
                        url = url + urlOption
                    }
                })
            }
            this.setState({ searchEmployee: searchItem })
        }
        this.loadEmployees(url)
    }

    loadEmployees(url) {
        axios({
            method: 'get',
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employees: resp.data.employees,totalPages: resp.data.meta.total_pages,totalResults: resp.data.meta.total_count,load: true })
        }).catch(() => {
					this.setState({isOpen: true,message: " Unexpected Error Problem Occurred",type: 'error'})
				})
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ departments: resp.data })
        }).catch(() => {
					this.setState({isOpen: true,message:  " Unexpected Error Problem Occurred",type: 'error'})
				})
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/positions',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ positions: resp.data })
        }).catch(() => {
					this.setState({isOpen: true,message: " Unexpected Error Problem Occurred",type: 'error'})
				})
        
    }

    handleSubmit() {
        const item = this.state.employee
        var method = ''
        var url = ''
        if (item.id === '') {
            method = 'post'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/'
        } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + item.id
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {

            const newData = resp.data
            this.setState({
                employees: this.state.employees.filter(employee => employee.id !== item.id)
            })
            this.setState({ employees: [...this.state.employees, newData] })
            this.setState({ openPopup: false })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleSearch() {
        var url = process.env.REACT_APP_API_DOMAIN + "/v1/employees/search?"
        var searchUrl = ''
        var solrUrl = ''
        var options = []
        var searchOpts = []
        var withResultsLabel = false
        if (this.state.searchEmployee.name !== undefined && this.state.searchEmployee.name !== '') {
            options.push("name=" + this.state.searchEmployee.name)
            searchOpts.push("name=*" + this.state.searchEmployee.name + '*')
            withResultsLabel = true
        }
        if (this.state.searchEmployee.department !== undefined &&this.state.searchEmployee.department !== null && this.state.searchEmployee.department.name !== '') {
            options.push("department_name=" + this.state.searchEmployee.department.name)
            searchOpts.push("department_name=" + this.state.searchEmployee.department.name)
            withResultsLabel = true
        }
        if (this.state.searchEmployee.position !== undefined &&this.state.searchEmployee.position !== null && this.state.searchEmployee.position.name !== '') {
            options.push("position_name=" + this.state.searchEmployee.position.name)
            searchOpts.push("position_name=" + this.state.searchEmployee.position.name )
            withResultsLabel = true
        }
        if (this.state.searchEmployee.employment_status !== undefined && this.state.searchEmployee.employment_status !== '') {
            options.push("employment_status=" + this.state.searchEmployee.employment_status)
            searchOpts.push("employment_status=" + this.state.searchEmployee.employment_status)
            withResultsLabel = true
        }
        if (this.state.searchEmployee.is_active !== undefined && this.state.searchEmployee.is_active !== '') {
            options.push("is_active=" + this.state.searchEmployee.is_active)
            searchOpts.push("is_active=" + this.state.searchEmployee.is_active)
            withResultsLabel = true
        }

        options.map((option, idx) => {
            if (idx > 0) {
                searchUrl = searchUrl + "&" + option
            } else {
                searchUrl = searchUrl + option
            }
        })
        searchOpts.map((option, idx) => {
            if (idx > 0) {
                solrUrl = solrUrl + "&" + option
            } else {
                solrUrl = solrUrl + option
            }
        })

        url = url + solrUrl
        axios({
            method: "get",
            url: url ,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employees: resp.data.employees,withResultsLabel: withResultsLabel,totalResults: resp.data.meta.total_count })
            this.setState({ totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page })
        }).catch(error => console.log(error.response))

        const { history } = this.props
        const params = new URLSearchParams()
        params.append("search", searchUrl)
        history.push({ search: searchUrl })
    }

    handleSearchInputChange(e) {
        this.setState({
            searchEmployee: {
                ...this.state.searchEmployee,
                [e.target.name]: e.target.value
            }
        })
    }

    handleDeleteItem() {
        const DeleteItemId = this.state.employee.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                employees: this.state.employees.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ openDeletePopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => console.log(error.response))
    }

    handleClose() {
        this.setState({ openPopup: false })
        this.setState({ openDeletePopup: false })
        this.setState({ error_messages: '' })
    }

    handleCreateorUpdateItem(item) {
        this.setState({ employee: item })
        this.setState({ redirect: true })
    }

    handleInputChange(e) {
        this.setState({
            employee: {
                ...this.state.employee,
                [e.target.name]: e.target.value
            }
        })
    }

    handleOpenDeletePopup(item, model) {
        this.setState({ model: model })
        this.setState({ employee: item })
        this.setState({ openDeletePopup: true })
    }

    handleDeleteClose() {
        this.setState({ openDeletePopup: false })
    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ isOpen: false })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSearch()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ withResultsLabel: false })
        this.loadEmployees(process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?&is_active=true')
        this.setState({ searchEmployee: { name: '', department:null,position: {}, employment_status: '', is_active: ''} })

    }

    handlePageChange(event, value) {
        var url = ''
        if (this.props.location.search === '') {
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?'
            this.setState({ withResultsLabel: false })
        } else {
            var searchItem = {}
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?'
            const query = new URLSearchParams(this.props.location.search);
            var urlOptions = []
            if (query.get('name') !== null && query.get('name') !== '') {
                searchItem["name"] = query.get('name')
                urlOptions.push("name=*" + query.get('name') + '*')
            }
            if (query.get('department_name') !== undefined && query.get('department_name') !== null && query.get('department_name') !== '') {
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    var deptSearch = {}
                     resp.data.map((dept)=>{
                        if(dept.name===query.get('department_name')){
                            deptSearch = dept
                        }
                     })
                     searchItem["department"] = deptSearch

                })
                urlOptions.push("department_name=" + query.get('department_name'))
            }
            if (query.get('position_name') !== undefined && query.get('position_name') !== null && query.get('position_name') !== '') {
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/positions',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    var postSearch = {}
                     resp.data.map((post)=>{
                        if(post.name===query.get('position_name')){
                            postSearch = post
                        }
                     })
                     searchItem["position"] = postSearch

                })
                urlOptions.push("position_name=" + query.get('department_name'))
            }

            if (query.get('employment_status') !== null && query.get('employment_status') !== '') {
                searchItem["employment_status"] = query.get('employment_status')
                urlOptions.push("employment_status=" + query.get('employment_status'))
            }
            if (query.get('is_active') !== null && query.get('is_active') !== '') {
                urlOptions.push("is_active=" + query.get('is_active'))
            }
            if (query.get('page') !== null && query.get('page') !== '') {
                urlOptions.push("page=" + query.get('page'))
            }
            this.setState({ currentPage: parseInt(query.get('page')) })
            this.setState({ withResultsLabel: true })

            if (urlOptions.length > 0) {
                urlOptions.map((urlOption, idx) => {
                    if (idx > 0) {
                        url = url + "&" + urlOption
                    } else {
                        url = url + urlOption
                    }
                })
            }
            this.setState({ searchEmployee: searchItem })
        }
        axios({
            method: 'get',
            url: url + '?page=' + value,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employees: resp.data.employees })
            this.setState({ currentPage: value })
        })

        const { history } = this.props
        const params = new URLSearchParams()
        params.append("page", value)
        history.push({ search: params.toString() })
    }

    handleAutocomplete(event,values){
        this.setState({searchEmployee: {
            ...this.state.searchEmployee,
            department: values
        }})
    }
    handlePositions(event, values){
        this.setState({searchEmployee: {
            ...this.state.searchEmployee,
            position: values
        }})
    }
    render() {
        const { redirect } = this.state
        var pathName = ''


        if (this.state.employee.id === "") {
            pathName = "/employee/new"
        } else {

            pathName = "/employees/" + this.state.employee.id + "/edit"
        }
        if (redirect)
            return (<Redirect to={{
                pathname: pathName
            }} />)
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <EmployeesSearch
                                clearSearch={this.clearSearch}
                                item={this.state.searchEmployee}
                                search={this.handleSearch}
                                handleChange={this.handleSearchInputChange}
                                handleKeyPress={this.handleKeyPress}
                                departments={this.state.departments}
                                positions={this.state.positions}
                                handleAutocomplete={this.handleAutocomplete}
                                handlePositions={this.handlePositions}
                            />
                            <SimpleTable
                                handleClick={this.handleCreateorUpdateItem}
                                initialItem={this.state.initialEmployee}
                                items={this.state.employees}
                                model={this.state.model}
                                onOpenDeletePopup={this.handleOpenDeletePopup}
                                headers={this.state.tableHead}
                                withShow={this.state.withShow}
                                withEdit={this.state.withEdit}
                                icon={<PeopleOutlineIcon fontSize="large" />}
                                currentPage={this.state.currentPage}
                                totalPages={this.state.totalPages}
                                handlePageChange={this.handlePageChange}
                                withPagination={this.state.withPagination}
                                totalResults={this.state.totalResults}
                                withResultsLabel={this.state.withResultsLabel}
                            />
                            <SimplePopUp
                                openPopup={this.state.openPopup}
                                title={this.state.title}
                                handleClose={this.handleClose}
                                maxWidth={this.state.maxWidth}
                            >
                                <EmployeeForm error={this.state.error_messages} item={this.state.employee} submit={this.handleSubmit} onchange={this.handleInputChange} />
                            </SimplePopUp>

                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openDeletePopup}
                                item={this.state.employee}
                                delete={this.handleDeleteItem}
                                handleDeleteClose={this.handleClose}
                                model={this.state.model}
                            />
                        </div>
                    ) : (
                      <ProgressBar model={this.state.model} />
                    )}
										<AlertMessage
													notify={this.state.notify}
													handleCloseAlert={this.handleCloseAlert}
													isOpen={this.state.isOpen}
													type={this.state.type}
													message={this.state.message}
												/>
            </>
        )
    }
}

export default withRouter(Employees)

Employees.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
