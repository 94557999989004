// eslint-disable-next-line
import style from '../../CssGlobalVariables/globalCss.css'
import React from 'react'

import PropTypes from 'prop-types'

import styles from './SimpleTable.module.css'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from "@material-ui/core/TableContainer"
import Pagination from '@material-ui/lab/Pagination';
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility'
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
class SimpleTableGroupBy extends React.Component {
  render() {
    // const pathName = '/' + this.props.model.replace(' ', '_').toLowerCase() + 's/'
    return (
      <>
        <>
          <div className={styles.tableTitle}>
            <div className={styles.groupTileSearch}>
              <div className={styles.tileBody}>
                {this.props.icon}
                <h2 className={styles.title}>
                  <div className={styles.head}>
                    <div>
                      {this.props.directoryClassName? "Directory" : this.props.model?(this.props.model.replace('_', ' ') + 's'):null}</div>
                  </div>
                </h2>
              </div>
            </div>
            <div className={styles.rightAction}>
              {
                this.props.withPagination ? (
                  this.props.totalPages !== 1 ? (
                    <Pagination
                      className={styles.paginationWidth}
                      size="small"
                      count={this.props.totalPages}
                      page={this.props.currentPage}
                      color="primary"
                      onChange={this.props.handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  ) : null
                ) : null
              }
              {this.props.withPrint ? (
                <div className={styles.buttonDisbursement}>
                  {this.props.urlPrint.reports_url !== undefined ? (
                    <Tooltip title={`Print ${this.props.model}`} arrow>
                      <a href={`${this.props.urlPrint.reports_url}`} target="_blank"
                        rel="noopener noreferrer">
                        <PrintIcon />
                      </a>
                    </Tooltip>
                  ) : null}
                  {this.props.urlPrint.export_excel_url !== undefined ? (
                    <Tooltip title={`Download Excel File for ${this.props.model}`} arrow>
                      <a href={`${this.props.urlPrint.export_excel_url}`} target="_blank"
                        rel="noopener noreferrer">
                        <CloudDownloadIcon />
                      </a>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
              {this.props.withAdd ? (
                      <div>
                        <IconButton color="inherit" aria-label="menu"
                          id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                          name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_add`}
                          onClick={() => this.props.handleClick(this.props.initialItem, true, this.props.model)}>
                          <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>

                      </div>
                    ) : (
                      null
                    )}
            </div>
          </div>

          <TableContainer className={this.props.customClassName} style={{maxHeight: '575px', position: 'relative'}}>
            <Table size="small"  aria-label="customized sticky table"
                   name={this.props.model.toLowerCase().replace(/ /g, '_') + "_table"}>
              <TableHead className={styles.tableHeadBody}>
                <TableRow>
                  <TableCell className={styles.groupcell}>
                    <b>{this.props.groupLabel}</b>
                  </TableCell>
                  {
                    this.props.loopedHeaders?(
                      <TableCell className={styles.groupHeader}>{
                      this.props.headers.length>0?(
                        this.props.headers.map((header,index)=>(
                          header.toLowerCase()==="account_name"?(
                          <TableCell key={index} className={header}>
                            <b>Account</b>
                          </TableCell>
                          ):(
                          header.toLowerCase()==="loan_payment"?(
                            <TableCell key={header} className={header} colSpan={2}>
                              <div>Payments</div>
                              <TableCell key={header} align="center"><b>Date</b>
                              </TableCell><TableCell align="center" key={header}
                              ><b>Amount</b>
                              </TableCell>
                              </TableCell>
                          ):(
                            <TableCell key={index} className={header}>
                              <b>{header.toLowerCase().replace("_"," ").replace("."," ")}</b>
                            </TableCell>
                            )
                          )
                        ))
                      ):null
                  }</TableCell>
                    ):(
                  this.props.headers.length > 0 ? (
                    this.props.headers.map((header, i) => (

                        header.toLowerCase() === 'inclusive_dates'  ||  header.toLowerCase() === 'reason' ||  header.toLowerCase() === 'without_pay'
                        ||  header.toLowerCase() === 'officer.name'||  header.toLowerCase() === 'request_status'||  header.toLowerCase() === 'leave_type.name'  ? (
                          i < 1 &&
                          <TableCell key={header} className={styles.customCell} align="center">
                            <TableCell  className={styles.leave_type}>
                              <b>Leave Type</b>
                            </TableCell>
                            <TableCell  className={styles.inclusive_dates}>
                              <b>Inclusive Dates</b>
                            </TableCell>
                            <TableCell  className={styles.without_pay}>
                              <b>With Pay</b>
                            </TableCell>
                            <TableCell  className={styles.reason}>
                              <b>Reason</b>
                            </TableCell>
                            <TableCell  className={styles.officer}>
                              <b>Officer</b>
                            </TableCell>
                            <TableCell  className={styles.request_status}>
                              <b>Status</b>
                            </TableCell>
                          </TableCell>
                        ) : (
                          header.includes("name_formal") || header.includes("position.name") || header.includes("office_no") || header.includes("email") ? (
                            i < 1 &&
                            <TableCell className={styles.directoryBody}>
                              <TableCell key={header} className={styles.nameFormal}><strong>Employee Name</strong></TableCell>
                              <TableCell key={header} className={styles.position}><strong>Position</strong></TableCell>
                              <TableCell key={header} className={styles.offNum}><strong>Office Number</strong></TableCell>
                              <TableCell key={header} className={styles.email}><strong>Email</strong></TableCell>
                            </TableCell>
                          ) : (
                            header.includes("leave_type_name") || header.includes("no_of_days")|| header.includes("year_of_effect")  ? (
                              i < 1 &&
                              <TableCell className={styles.directoryBody}>
                                <TableCell key={header} className={styles.nameFormal}><strong>Leave Type</strong></TableCell>
                                <TableCell key={header} className={styles.position}><strong>No Of Days</strong></TableCell>
                                <TableCell key={header} className={styles.position}><strong>Year of Effect</strong></TableCell>
                              </TableCell>
                            ) : (
                            header.includes('.') ? (
                              header.includes("names") ? (
                                <TableCell key={header}
                                           className={styles.leave_type}><strong>Leave Type</strong></TableCell>
                              ) : (
                                <TableCell key={header} className={styles.cell}><strong>{header.split('.')[1].replace('_', ' ').replace('.', ' ')}</strong></TableCell>
                              )
                            ) : (
  
                              <TableCell key={header}
                                         className={styles.cell}><b>{header.replace('_', ' ').replace('_', ' ')}</b>
                              </TableCell>
                            )
                          )
                        )
                        )
                      )
                    )
                  ) : (
                    null
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody className={styles.tableBody}>
                <>
                  {Object.keys(this.props.items).sort(function (a, b) {
                    if (a < b) return -1;
                    if (a > b) return 1;
                    return 0;
                  }).map((item, i) => (
                    <TableRow key={i} className={styles.tableRowGroupBy}>
                      <TableCell className={this.props.directoryClassName? styles.directoryClassName : styles.groupByName}>
                        {item}
                      </TableCell>
                      {
                        this.props.loopedHeaders?(
                          this.props.items[item].sort(
                            (a, b) => new Date(a.date_from).getTime() - new Date(b.date_from).getTime()
                        ).map((content)=>(
                            <Table key={content}>{
                            this.props.headers.map((header,idx)=>(
                              header.includes("date")?(
                                this.props.model==="Loan Report"?(
                                  content.recurrence_id?(
                                    content.recurrence!==undefined?(
                                    <TableCell key={`${header}-${content}-${idx}`} className={header}>
                                    {content.recurrence[header.toLowerCase()] ? (new Date(content.recurrence[header.toLowerCase()]).toLocaleDateString('en-Us', { month: '2-digit', day: '2-digit', year: '2-digit' })) : ("n/a")}
                                    </TableCell>):(
                                      <TableCell key={`${header}-${content}`} className={header}>
                                      n/a
                                      </TableCell>
                                    )
                                    ): <TableCell key={`${header}-${content}`} className={header}>
                                    n/a
                                    </TableCell>
                                  ):<TableCell align="center" key={`${header}-${content}`} className={header}>
                                  {content[header.toLowerCase()] ? (new Date(content[header.toLowerCase()]).toLocaleDateString('en-Us', { month: '2-digit', day: '2-digit', year: '2-digit' })) : ("n/a")}
                                  </TableCell>
                              ):(
                                header.toLowerCase() === "payroll_period" ? (
                                    <TableCell key={header}
                                      className={styles.groupcell}>
                                      {moment(content.date_from).format('MMM DD')}-{moment(content.date_to).format('DD YYYY')}
                                    </TableCell>
                                  ) :(
                                header.toLowerCase()==="account_name"?(
                                <TableCell key={`${header}-${content}`} className={header}>
                                   {content[header.toLowerCase()]?(content[header.toLowerCase()]):('n/a')}
                                </TableCell>
                                ):(
                                header.toLowerCase()==="loan_payment"?(
                                  <TableCell className={styles.dataPaymentBody}>
                                    <TableCell key={`${header}-${content}`} className={styles.paymentDate}>
                                      {moment(content.date_from).format('MMM DD')}-{moment(content.date_to).format('DD YYYY')}
                                    </TableCell>
                                    <TableCell align="right" key={`${header}-${content}`}
                                               className={styles.paymentAmount}>
                                      {content["amount"] ? (parseFloat(content["amount"]).toLocaleString(navigator.language, {minimumFractionDigits: 2})) : ("n/a")}
                                    </TableCell>
                                  </TableCell>
                                ):(
                                  header.toLowerCase()==="principal_amount"?(
                                    this.props.model==="Loan Report"?(
                                      content.recurrence_id?(
                                        content.recurrence?(
                                        <TableCell align="right" key={`${header}-${content}`} className={header}>
                                         {content.recurrence["principal_amount"]?(parseFloat(content.recurrence["principal_amount"]).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("n/a")}
                                        </TableCell>
                                        ):(<TableCell align="right" key={`${header}-${content}`} className={header}>
                                        n/a
                                        </TableCell>)
                                        ): <TableCell align="right" key={`${header}-${content}`} className={header}>
                                        n/a
                                        </TableCell>
                                      ):<TableCell align="right" key={`${header}-${content}`} className={header}>
                                      {content["principal_amount"]?(parseFloat(content["principal_amount"]).toLocaleString(navigator.language, { minimumFractionDigits: 2 })) : ("n/a")}
                                      </TableCell>
                                  ):(
                                <TableCell align="center" key={`${header}-${content}`} className={header}>
                                   {content[header.toLowerCase()]?(content[header.toLowerCase()]):('n/a')}
                                </TableCell>
                                  )
                                )
                                )
                              )
                              )
                              ))
                              }</Table>
                          ))

                        ):(
                        this.props.items[item].sort(function (a, b) {if(a.name_formal !== undefined) {
                          if ( a.name_formal.toLowerCase()  < b.name_formal.toLowerCase()) return -1;
                          if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
                          return 0;
                        }}).map((client) => (
                        <>
                          <Table key={client} className={styles.groupByTableBody}>
                            {
                              this.props.headers.length > 0 ? (
                                this.props.headers.map((header, idx) => (
                                  header.includes(".") ? (
                                    header === "action.names" ? (
                                      <TableCell key={header + idx.toString}
                                                 className={styles.header}>
                                        <>{client[header.split(".")[0]] ? (client[header.split(".")[0]][header.split(".")[1]]) : ("n/a")}</>
                                      </TableCell>
                                    ) : (
                                      <TableCell key={header} className={header}>
                                        <>{client[header.split(".")[0]] ? (client[header.split(".")[0]][header.split(".")[1]]) : ("n/a")}</>
                                      </TableCell>
                                    )
                                  ) : (header.toLowerCase() === "without_pay" ? (
                                      <TableCell key={header} className={styles.without_pay}>
                                        {client.without_pay === true ? ("Yes") : ("No")}
                                      </TableCell>
                                    ) : (
                                      this.props.model === "Leave Credit" ? (
                                        header.toLowerCase() === "leave_type" ? (
                                          <TableCell key={header} className={styles.nameFormal}>
                                            {client['leave_type_name'] ? (client['leave_type_name']) : ("n/a")}
                                          </TableCell>
                                        ) : (
                                          header.toLowerCase() === "no_of_days" ? (
                                            <TableCell key={header} className={styles.position}>
                                              {client["no_of_days"] ? (client["no_of_days"]) : ("n/a")}
                                            </TableCell>
                                          ):
                                          header.toLowerCase() === "year_of_effect" ? (
                                            <TableCell key={header} className={styles.position}>
                                              {client["year_of_effect"] ? (client["year_of_effect"].toString()) : ("n/a")}
                                            </TableCell>
                                          ):
                                          <TableCell key={header + idx.toString} className={styles.position}>
                                        {client[header.toLowerCase()]}
                                      </TableCell>
                                        )
                                      ) : (

                                      <TableCell key={header + idx.toString} className={header}>
                                        {client[header.toLowerCase()]}
                                      </TableCell>
                                    )
                                    )
                                  )
                                ))) : null
                            }
                            <TableCell size="small" className={this.props.model.toLowerCase() !== 'leave record' ? styles.fullWitdActionGroupByHoverable : styles.fullWitdActionGroupByHoverable}>
                              {
                                this.props.withShowPopUp ? (
                                  <Tooltip title="Add Group" arrow>
                                    <IconButton
                                      id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                      name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_showpopup`}
                                      size="small"
                                      color="primary"
                                      onClick={() => this.props.handleShowPopUp(client)}>
                                      <VisibilityIcon color="primary" fontSize="small"/>
                                    </IconButton>
                                  </Tooltip>
                                ) : (null)
                              }
                              {this.props.nodelete ? null : 
                              
                                <Tooltip title="Delete" arrow>
                                  <IconButton
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_delete`}
                                    size="small"
                                    color="secondary"
                                    onClick={() => this.props.onOpenDeletePopup(client, this.props.model)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            {
                              this.props.withEdit ? (
                                <Tooltip title="Edit" arrow>
                                  <IconButton
                                    size="small"
                                    id={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                    name={`${this.props.model.toLowerCase().replace(/ /g, '_')}_edit`}
                                    color="primary"
                                    onClick={() => this.props.handleClick(this.props.items[item], false, this.props.model)}>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null
                            }
                            </TableCell>
                           
                          </Table>
                          {/*<TableRow*/}
                          {/*  className={this.props.model.toLowerCase() !== 'leave record' ? styles.actionButtonGroupByHoverable : styles.fullWitdActionGroupByHoverable}*/}
                          {/*  align="right">*/}
                          {/*  */}
                          {/*</TableRow>*/}
                        </>
                      )))}
                    </TableRow>
                    
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </>

    )
  }
}

export default SimpleTableGroupBy

SimpleTableGroupBy.propTypes = {
  model: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  icon: PropTypes.string,
  groupLabel: PropTypes.string,
  totalPages: PropTypes.func,
  currentPage: PropTypes.func,
  handlePageChange: PropTypes.func,
  withShowPopUp: PropTypes.func,
  handleShowPopUp: PropTypes.func,
  customClassName: PropTypes.func,
  withPagination: PropTypes.func,
  loopedHeaders: PropTypes.bool,
  directoryClassName: PropTypes.bool,
  withPrint: PropTypes.bool,
  urlPrint: PropTypes.object,
  nodelete: PropTypes.bool,
  withEdit: PropTypes.bool,
  handleClick: PropTypes.func,
  onOpenDeletePopup: PropTypes.func,
  withDelete: PropTypes.bool,
  withAdd: PropTypes.bool,
  initialItem: PropTypes.object,
}
