import React from 'react'
import styles from './Employees.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
    MenuItem,
    Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class EmployeesSearch extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="name"
                            fullWidth
                            onKeyPress={this.props.handleKeyPress}
                            label="Name"
                            onChange={this.props.handleChange}
                            value={this.props.item.name}
                            id="employee-name"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            value={this.props.item.department}
                            id="department"
                            name="department"
                            fullWidth
                            className={styles.autoCompleteField}
                            size="small"
                            options={(this.props.departments).sort(function (a, b) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                            })}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.name === value.name}
                            onChange={this.props.handleAutocomplete}
                            renderInput={(params) => (
                                <TextField {...params} name="department" variant="outlined" label="Department" />
                            )}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            value={this.props.item.position}
                            id="position"
                            name="position"
                            fullWidth
                            className={styles.autoCompleteField}
                            size="small"
                            options={(this.props.positions).sort(function (a, b) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                            })}
                            getOptionLabel={(option) => option.name }
                            getOptionSelected={(option, value) => option.name === value.name}
                            onChange={this.props.handlePositions}
                            renderInput={(params) => (
                                <TextField {...params} name="position" variant="outlined" label="Position" />
                            )}
                        />

                    </Grid>
                    <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="employment_status"
                                fullWidth
                                select
                                label="Employment Status"
                                onChange={this.props.handleChange}
                                value={this.props.item.employment_status}
                                id="employee-employment_status"
                            >
                                <MenuItem value="">&nbsp;</MenuItem>
                                <MenuItem value="regular">Regular</MenuItem>
                                <MenuItem value="probationary">Probationary</MenuItem>
                                <MenuItem value="separated">Separated</MenuItem>
                                <MenuItem value="contractual">Contractual</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                autoComplete="off"
                                variant="outlined"
                                size="small"
                                name="is_active"
                                fullWidth
                                select
                                label="Active"
                                onChange={this.props.handleChange}
                                value={this.props.item.is_active}
                                id="employee-is_active"
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={3}>
                            <div className={styles.actionButton}>
                    <Button variant="outlined" id="employee-search" color="primary" onClick={() => this.props.search()} >Search</Button>
                    <Button variant="outlined" id="employee-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
                </div></Grid>
                </Grid>
            </div>
        )
    }
}
export default EmployeesSearch

EmployeesSearch.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    positions: PropTypes.array,
    departments: PropTypes.array,
    search: PropTypes.func,
    clearSearch: PropTypes.func,
    handleKeyPress: PropTypes.func,
    handleAutocomplete: PropTypes.func,
    handlePositions: PropTypes.func
}
