// eslint-disable-next-line
import React from 'react'

import Cookies from 'js-cookie';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import axios from 'axios'
import style from './App.module.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import ProgressBar from './shared/ProgressBar/ProgressBar';
import HomeIcon from '@material-ui/icons/Home'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@material-ui/icons/EventBusyOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Positions from "./pages/Positions/Positions";
import Departments from "./pages/Departments/Departments";
import Divisions from "./pages/Divisions/Divisions";
import PayrollAccounts from "./pages/PayrollAccounts/PayrollAccounts";
import SideNavMenu from "./shared/SideNav/SideNavMenu";
import Appbar from './shared/Appbar/Appbar'

import Memos from '../components/pages/Memos/Memos'
import Announcements from './pages/Announcements/Announcements'
import AnnouncementNewAndUpdate from './pages/Announcements/AnnouncementNewAndUpdate'
import Announcement from './pages/Announcements/Announcement'
import Recurrences from './pages/Recurrences/Recurrences'
import PhotoGalleries from './pages/PhotoGallery/PhotoGallery'
import Employees from './pages/Employees/Employees'
import EmployeeNewAndUpdate from './pages/Employees/EmployeeNewAndUpdate'
import LeaveApplications from './pages/LeaveManagement/LeaveApplication/LeaveApplications'
import LeaveRecords from "./pages/LeaveRecord/LeaveRecords";
import EmployeeShow from './pages/Employees/EmployeeShow'
import TaxTables from './pages/TaxTables/TaxTables'
import PayrollList from "./pages/PayrollList/PayrollList";
import PayrollListShow from './pages/PayrollList/PayrollListShow'
import PayrollAdjustmentShow from './pages/PayrollList/PayrollAdjustment/PayrollAdjustmentShow'
import PayrollAdjustment from './pages/PayrollList/PayrollAdjustment/PayrollAdjustment'
import LeaveTypes from "./pages/LeaveTypes/LeaveTypes";
import Memo from './pages/Memos/Memo'
import AccountLink from './shared/ModuleLinks/AccountLink'
import LoanReports from './pages/LoanReports/LoanReports'
import Directories from './pages/Directories/Directories'
import Branches from "./pages/Branches/Branches";
import Permissions from "./pages/Permissions/Permissions"
import PermissionNewUpdate from "./pages/Permissions/PermissionNewUpdate"
import Banks from "./pages/Banks/Banks"
import LeaveCredits from './pages/LeaveCredits/LeaveCredits';
import Home from './pages/Home/Home'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      load: false,
      open: true,
      linkOpen: false,
      token: '',
      CurrentUser: '',
      currentUser: {},
      employee: {},
      signIn: false,
      apps: [],
      singleMenu: [
        { label: 'Home', icon: <HomeIcon />, link: '/' },
        { label: 'Directory', icon: <PermMediaOutlinedIcon />, link: '/directory' },
      ],
      employeeMenu: [
        { label: 'Employee Profiles', icon: <PermContactCalendarOutlinedIcon />, link: '/employees' },
        { label: 'Memo', icon: <BorderColorOutlinedIcon />, link: '/memos' },
        { label: 'Announcement', icon: <FeedbackOutlinedIcon />, link: '/announcements' },
        { label: 'Leave Applications', icon: <InsertInvitationIcon />, link: '/leave_applications' }
      ],
      subMenus: [
        {
          parentLabel: 'Human Resources', parentIcon: <SupervisedUserCircleOutlinedIcon />,
          subLabel: [
            { label: 'Employee Profiles', childIcon: <PermContactCalendarOutlinedIcon />, link: '/employees' },
            { label: 'Memo', childIcon: <BorderColorOutlinedIcon />, link: '/memos' },
            { label: 'Announcement', childIcon: <FeedbackOutlinedIcon />, link: '/announcements' },
            {
              childParentLabel: 'Leave Management', parentIcon: <EventNoteOutlinedIcon />, class: "childParentLabel",
              childLabel: [
                { label: 'Leave Records', childIcon: <ExitToAppOutlinedIcon />, link: '/leave_records' },
                { label: 'Manage Leave Type', childIcon: <EventAvailableOutlinedIcon />, link: '/manage_leave_types' },
                { label: 'Manage Leave Credit', childIcon: <EventBusyOutlinedIcon />, link: '/manage_leave_credits' },
              ]
            },
            { label: 'Photo Gallery', childIcon: <PhotoLibraryOutlinedIcon />, link: '/photo_galleries' },
            { label: 'Recurrence', childIcon: <AutorenewOutlinedIcon />, link: '/recurrences' },

          ],
        },

        {
          parentLabel: 'Payroll', parentIcon: <SupervisorAccountOutlinedIcon />,
          subLabel: [
            { label: 'Payroll List', childIcon: <ListAltOutlinedIcon />, link: '/payrolls' },
            { label: 'Loan Reports', childIcon: <CreditCardOutlinedIcon />, link: '/loan_reports' }
          ]
        },
        {
          parentLabel: 'Payroll Settings', parentIcon: <SettingsOutlinedIcon />,
          subLabel: [
            { label: 'Payroll Account', childIcon: <AccountBoxOutlinedIcon />, link: '/payroll_accounts' },
            { label: 'Tax Table', childIcon: <TableChartOutlinedIcon />, link: '/tax_tables' },
            { label: 'Department', childIcon: <HomeWorkOutlinedIcon />, link: '/departments' },
            { label: 'Division', childIcon: <DnsOutlinedIcon />, link: '/divisions' },
            { label: 'Position', childIcon: <RecentActorsOutlinedIcon />, link: '/positions' },
            { label: 'Branches', childIcon: <SettingsInputCompositeOutlinedIcon />, link: '/branches' },
            { label: 'Banks', childIcon: <AccountBalanceOutlinedIcon />, link: '/banks' },
            { label: 'Permissions', childIcon: <AssignmentIcon />, link: '/permissions' },
          ]
        }
      ],

    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleLink = this.toggleLink.bind(this)
    this.logOut = this.logOut.bind(this)

    this.setLoad = this.setLoad.bind(this)
    this.loadAttribute = this.loadAttribute.bind(this)
  }

  componentDidMount() {
    var token = Cookies.get('token')

    if (token) {
      localStorage.setItem('api_key', token)
      this.loadAttribute()
      this.setState({ load: true })
    } else {
      this.logOut()
    }
  }

  logOut() {
    this.clearSession();
    this.setState({
      anchorEl: null,
      load: false
    })
    location.href = 'https://'+ process.env.REACT_APP_GATEWAY_SUBDOMAIN + process.env.REACT_APP_DOMAIN
  }

  clearSession() {
    localStorage.removeItem('api_key')
    Cookies.remove('token')
    Cookies.remove('current_user')
    Cookies.remove('cashvoucher_prefix')
    Cookies.remove('checkvoucher_prefix')
    Cookies.remove('token', { domain: process.env.REACT_APP_DOMAIN })
    Cookies.remove('current_user', { domain: process.env.REACT_APP_DOMAIN })
    Cookies.remove('cashvoucher_prefix', { domain: process.env.REACT_APP_DOMAIN })
    Cookies.remove('checkvoucher_prefix', { domain: process.env.REACT_APP_DOMAIN })
  }


  setLoad(load) {
    this.setState({ load: load })
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open })
    localStorage.setItem('openSideNav', this.state.open)
  }

  toggleLink() {
    if (this.state.apps.length <= 0) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/apps',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if (resp.data.length > 0) {
          var apps = []
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/user',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(res => {
            if (res.data.app_ids.length > 0) {
              apps = resp.data.filter(({ id }) => res.data.app_ids.includes(id))
              this.setState({ apps: apps })
            }
          })
        }
      })
    }
    this.setState({ linkOpen: !this.state.linkOpen })
  }

  loadAttribute() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/profiles',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employee: resp.data })
    })
    if(Cookies.get('current_user')){
      this.setState({ currentUser: JSON.parse(Cookies.get('current_user')) })
    }
  }

  render() {

    return (
      <>
        {
          this.state.load ? (
            <Router>
              <div className={style.root}>
                <CssBaseline />
                <Appbar
                  toggleDrawer={this.toggleDrawer}
                  open={this.state.open}
                  currentUser={this.state.employee}
                  logOut={this.logOut}
                  title={<React.Fragment>
                    <div className={style.linkList}>
                      <div>
                        {process.env.REACT_APP_TITLE}
                      </div>
                      <AccountLink
                        toggleLink={this.toggleLink}
                        linkOpen={this.state.linkOpen}
                        apps={this.state.apps}
                      />
                    </div>
                  </React.Fragment>
                  }
                />
                <SideNavMenu
                  withSubMenus={this.state.subMenus}
                  menus={this.state.singleMenu}
                  openDrawer={this.state.open}
                  toggleDrawer={this.toggleDrawer}
                  currentUser={this.state.currentUser}
                  employeeMenu={this.state.employeeMenu}
                />

                <div className={this.state.open ? style.openBody : style.closeBody}>
                  <Grid container style={{ flexWrap: 'inherit' }}>
                    <Grid item xs={12}>
                      <main className={style.content}>
                        <Switch>
                          <Route exact path="/">
                            <Home />
                           
                          </Route>
                          <Route exact path="/employees">
                            <Employees />
                          </Route>
                          <Route exact path="/employee/new" component={EmployeeNewAndUpdate} />
                          <Route exact path="/employees/:id/edit" component={EmployeeNewAndUpdate} />
                          <Route exact path="/employees/:id" component={EmployeeShow} />
                          <Route exact path="/memos">
                            <Memos currentUser={this.state.employee} />
                          </Route>
                          <Route exact path="/memos/:id" component={Memo} />
                          <Route exact path="/announcements">
                            <Announcements currentUser={this.state.employee} />
                          </Route>
                          <Route exact path="/announcement/new" component={AnnouncementNewAndUpdate} />
                          <Route exact path="/announcements/:id" component={Announcement} />
                          <Route exact path="/announcements/:id/edit" component={AnnouncementNewAndUpdate} />

                          <Route exact path="/leave_applications">
                            <LeaveApplications />
                          </Route>
                          <Route exact path="/photo_galleries">
                            <PhotoGalleries />
                          </Route>
                          <Route exact path="/directory">
                            <Directories />
                          </Route>
                          {
                            !this.state.currentUser.is_employee ? (
                              <>
                                <Route exact path="/recurrences">
                                  <Recurrences />
                                </Route>
                                <Route exact path="/leave_records">
                                  <LeaveRecords />
                                </Route>
                                <Route exact path="/manage_leave_credits">
                                  <LeaveCredits />
                                </Route>
                                <Route exact path="/manage_leave_types">
                                  <LeaveTypes />
                                </Route>
                                <Route exact path="/payrolls">
                                  <PayrollList />
                                </Route>
                                <Route exact path="/payrolls/:id/payroll_details" component={PayrollListShow} />
                                <Route exact path="/payrolls/:id/payroll_adjustments/new" component={PayrollAdjustment} />
                                <Route exact path="/payrolls/:id/payroll_adjustments/new?payroll_account=:uid" component={PayrollAdjustmentShow} />
                                <Route exact path="/payrolls/:id/payroll_adjustments/edit/:accId" component={PayrollAdjustmentShow} />
                                <Route exact path="/payrolls/:id/payroll_adjustments" component={PayrollAdjustment} />
                                <Route exact path="/loan_reports">
                                  <LoanReports />
                                </Route>
                                <Route exact path="/payroll_accounts">
                                  <PayrollAccounts />
                                </Route>
                                <Route exact path="/tax_tables">
                                  <TaxTables />
                                </Route>
                                <Route exact path="/departments">
                                  <Departments />
                                </Route>
                                <Route exact path="/positions">
                                  <Positions />
                                </Route>
                                <Route exact path="/divisions">
                                  <Divisions />
                                </Route>

                                <Route exact path="/branches">
                                  <Branches />
                                </Route>
                                <Route exact path="/permissions">
                                  <Permissions />
                                </Route>
                                <Route exact path="/banks">
                                  <Banks />
                                </Route>
                                <Route exact path="/permissions/new" component={PermissionNewUpdate} />
                                <Route exact path="/permissions/:permission_id" component={PermissionNewUpdate} />
                              </>
                            )
                              :
                              <Route path="*">
                                Route Not Found
                              </Route>
                          }
                          {/* <Route path="*">
                            Route Not Found
                          </Route> */}
                        </Switch>
                      </main>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Router>
          ) : (<ProgressBar model={"Login to your Account"} />)
        }
      </>
    )
  }
}

export default App
