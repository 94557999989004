// eslint-disable-next-line
// import styles from './Memos.module.css'

import React from 'react'

import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import MemoForm from './MemoForm'
import AllInboxIcon from '@material-ui/icons/AllInbox';

import axios from 'axios'
import PropTypes from 'prop-types'

import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import AnnouncementTable from '../Announcements/AnnouncementTable'
import {Redirect} from 'react-router-dom'
import MemoSearchForm from './MemoSearchForm'
import { withRouter } from 'react-router-dom'
import Cookies from 'js-cookie';
import Memo from './Memo';

export const Component = withRouter(() => {

})
class Memos extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'Memo',
      memos: [],
      openPopup: false,
      openDeletePopup: false,
      memo: {},
      employees: [],
      selectedEmployees: [],
      initialMemo: { id: '', content: '',title: '' },
      title: '',
      load: false,
      tableHead: ['title','officer_name',"employee_names"],
      withShow: false,
      withEdit: true,
      mdWidth: 'md',
      error_messages: '',
      notify: {  },
      isOpen: false,
      message: '',
      type: '',
      redirectToShow: false,
      searchMemo: {
        employee: {}
      },

      totalPages: 1,
      currentPage: 1,
      withPagination: true,
      totalResults: 0,
      withResultsLabel: false,
      approvingOfficers: [],
      officer: {},
      showPopup: false,
      errorMessages: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete=this.handleAutocomplete.bind(this)
    this.handleEditor = this.handleEditor.bind(this)
    this.rowClick=this.rowClick.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.search = this.search.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleEmployees = this.handleEmployees.bind(this)
    this.onAttachmentAdd = this.onAttachmentAdd.bind(this)
    this.handleShowPopup = this.handleShowPopup.bind(this)
  }

  componentDidMount () {
    var url = ''
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/memos/search?'
      this.setState({ withResultsLabel: false})
    } else {
      var searchItem = {}
      url = process.env.REACT_APP_API_DOMAIN + '/v1/memos/search?'
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('created_at_from') !== null && query.get('created_at_from') !== '') {
        searchItem["created_at_from"] = query.get('created_at_from')
        urlOptions.push("created_at_from=" + query.get('created_at_from'))
      }
      if (query.get('created_at_to') !== null && query.get('created_at_to') !== '') {
        searchItem["created_at_to"] = query.get('created_at_to')
        urlOptions.push("created_at_to=" + query.get('created_at_to'))
      }
      if (query.get('sent') !== null && query.get('sent') !== '') {
        searchItem["sent"] = query.get('sent')
        urlOptions.push("sent=" + query.get('sent'))
      }
      if (query.get('title') !== null && query.get('title') !== '') {
        searchItem["title"] = query.get('title')
        urlOptions.push("title=*" + query.get('title')+'*')
      }
      if (query.get('content') !== null && query.get('content') !== '') {
        searchItem["content"] = query.get('content')
        urlOptions.push("content=*" + query.get('content')+'*')
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      if (query.get('employee_ids') !== null && query.get('employee_ids') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/'+query.get('employee_ids'),
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          searchItem["employee"]=resp.data
          })
          urlOptions.push("employee_ids=" + query.get('employee_ids'))
      }
      this.setState({ currentPage: parseInt(query.get('page')) })
      this.setState({ withResultsLabel: true})

      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({ searchMemo: searchItem })
    }
    this.loadMemos(url)
  }

  loadMemos (url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      this.setState({ memos: resp.data.memos })
      this.setState({ currentPage: resp.data.meta.current_page})
      this.setState({ totalResults: resp.data.meta.total_count})
      this.setState({ totalPages: resp.data.meta.total_pages })

      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?is_active=true&per_page=50000',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ employees: resp.data.employees })
        this.setState({ load: true })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
    if(Cookies.get('current_user')){
      if(JSON.parse(Cookies.get('current_user')).is_employee){
      axios({ 
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/approving_officers',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ approvingOfficers: resp.data })
        })
      }
    }
  }

  handleSubmit () {
    const item = this.state.memo
    item['officer_id']= this.state.officer.id
    var method = ''
    var url = ''
    var empIds = []
    if(this.state.selectedEmployees.length>0){
     this.state.selectedEmployees.map((emp)=>{
         empIds.push(emp.id)
     })
    }
    item["employee_ids"]=empIds
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/memos'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/memos/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        memos: this.state.memos.filter(memo => memo.id !== item.id)
      })
      this.setState({ memos: [...this.state.memos, newData] })
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
				Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
					errors[field] = error.response.data.errors[0].detail.errors[field][0]
					if (field.includes('.')) {
						errors[field.split(".")[1]] = error.response.data.errors[0].detail.errors[field][0]
					} else {
						errors[field] = error.response.data.errors[0].detail.errors[field][0]
					}
				})
				this.setState({ errorMessages: errors })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  uploadAttachment(attachment) {
    var file = attachment.file;
    var form = new FormData;
    var endpoint = process.env.REACT_APP_API_DOMAIN + '/v1/photos/'
    form.append("Content-Type", file.type);
    form.append("image", file);

    form.append('title',file.name)
    form.append('description', 'test2')
    form.append('rank', 12)
    form.append('publish', false)

   const xhr = new XMLHttpRequest;
    xhr.open("POST", endpoint, true);
    xhr.setRequestHeader('X-API-ACCESS-TOKEN', localStorage.getItem('api_key'));

    xhr.upload.onprogress = function (event) {
        var progress = event.loaded / event.total * 100;
        return attachment.setUploadProgress(progress);
    };

    xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
            var data = JSON.parse(this.responseText);
            return attachment.setAttributes({
                url: data.image.normal,
                href: data.image.normal
            });
        }
    }
    return xhr.send(form)
  }
  onAttachmentAdd(event) {
    var attachment = event.attachment;
    if (attachment.file) {
        return this.uploadAttachment(attachment);
    }
}

  handleDeleteItem () {
    const DeleteItemId = this.state.memo.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/memos/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        memos: this.state.memos.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose () {
    this.setState({ openPopup: false, showPopup: false})
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: ''})
  }

  handleCreateorUpdateItem (item, isAdd, model) {
    var textTitle = ''
    var selectedEmployees = []
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
      if(this.state.employees.length>0){
          if(item.employee_ids.length>0){
              item.employee_ids.map((empId)=>{
                  this.state.employees.map((emp)=>{
                      if(empId===emp.id){
                          selectedEmployees.push(emp)
                      }
                  })
              })
          }
      }
    }
    this.setState({ selectedEmployees: selectedEmployees})
    this.setState({ openPopup: true })
    this.setState({ memo: item })
    this.setState({ title: textTitle })
  }

  handleInputChange (e) {
    this.setState({
      memo: {
        ...this.state.memo,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup (item, model) {
    this.setState({ model: model })
    this.setState({ memo: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }
  handleAutocomplete(event,values){
    if(event.target.id.includes('employees')){
      this.setState({selectedEmployees: values})
    }
    else{
      this.setState({officer: values})
    }
      
  }

  handleEditor(event, editor){
    this.setState({
      memo: {
        ...this.state.memo,
        content: editor
      }
    })
   
  
  }
  rowClick(item){
    this.setState({redirectToShow: true})
    this.setState({ memo: item})

  }

  handleSearchInput(e){
    this.setState({
      searchMemo: {
        ...this.state.searchMemo,
        [e.target.name]: e.target.value
      }
    })
  }

  search(){
    this.setState({load: false})
    var url = process.env.REACT_APP_API_DOMAIN + "/v1/memos/search?"
    var searchUrl = ''
    var solrUrl = ''
    var options = []
    var searchOpts = []
    var withResultsLabel = false
    if (this.state.searchMemo.created_at_from !== undefined && this.state.searchMemo.created_at_from !== '') {
      options.push("created_at_from=" + this.state.searchMemo.created_at_from)
      searchOpts.push("created_at_from=" + this.state.searchMemo.created_at_from)
      withResultsLabel = true
    }
    if (this.state.searchMemo.created_at_to !== undefined && this.state.searchMemo.created_at_to !== '') {
      options.push("created_at_to=" + this.state.searchMemo.created_at_to)
      searchOpts.push("created_at_to=" + this.state.searchMemo.created_at_to)
      withResultsLabel = true
    }
    if (this.state.searchMemo.sent !== undefined && this.state.searchMemo.sent !== '') {
      options.push("sent=" + this.state.searchMemo.sent)
      searchOpts.push("sent=" + this.state.searchMemo.sent)
      withResultsLabel = true
    }
    if (this.state.searchMemo.title !== undefined && this.state.searchMemo.title !== '') {
      options.push("title=" + this.state.searchMemo.title)
      searchOpts.push("title=*" + this.state.searchMemo.title+"*")
      withResultsLabel = true
    }
    if (this.state.searchMemo.content !== undefined && this.state.searchMemo.content !== '') {
      options.push("content=" + this.state.searchMemo.content)
      searchOpts.push("content=*" + this.state.searchMemo.content+"*")
      withResultsLabel = true
    }
    if (this.state.searchMemo.employee !== null &&this.state.searchMemo.employee !== undefined &&this.state.searchMemo.employee.id !== undefined && this.state.searchMemo.employee !== '') {
      options.push("employee_ids=" + this.state.searchMemo.employee.id)
      searchOpts.push("employee_ids=" + this.state.searchMemo.employee.id)
    }


    options.map((option, idx) => {
      if (idx > 0) {
        searchUrl = searchUrl + "&" + option
      } else {
        searchUrl = searchUrl + option
      }
    })
    searchOpts.map((option, idx) => {
      if (idx > 0) {
        solrUrl = solrUrl + "&" + option
      } else {
        solrUrl = solrUrl + option
      }
    })

    url = url + solrUrl
    axios({
      method: "get",
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ memos: resp.data.memos })
      this.setState({currentPage: resp.data.meta.current_page})
      this.setState({ withResultsLabel: withResultsLabel})
      this.setState({ totalResults: resp.data.meta.total_count})
      this.setState({ totalPages: resp.data.meta.total_pages })
      this.setState({load: true})
    }).catch(error => console.log(error.response))

    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchUrl)
    history.push({ search: searchUrl })
  }

  handlePageChange(event, value) {
    var url = ''
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/memos/search?'
      this.setState({ withResultsLabel: false})
    } else {
      var searchItem = {}
      url = process.env.REACT_APP_API_DOMAIN + '/v1/memos/search?'
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('created_at_from') !== null && query.get('created_at_from') !== '') {
        searchItem["created_at_from"] = query.get('created_at_from')
        urlOptions.push("created_at_from=" + query.get('created_at_from'))
      }
      if (query.get('created_at_to') !== null && query.get('created_at_to') !== '') {
        searchItem["created_at_to"] = query.get('created_at_to')
        urlOptions.push("created_at_to=" + query.get('created_at_to'))
      }
      if (query.get('sent') !== null && query.get('sent') !== '') {
        searchItem["sent"] = query.get('sent')
        urlOptions.push("sent=" + query.get('sent'))
      }
      if (query.get('title') !== null && query.get('title') !== '') {
        searchItem["title"] = query.get('title')
        urlOptions.push("title=*" + query.get('title')+'*')
      }
      if (query.get('content') !== null && query.get('content') !== '') {
        searchItem["content"] = query.get('content')
        urlOptions.push("content=*" + query.get('content')+'*')
      }
      if (query.get('employee_ids') !== null && query.get('employee_ids') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/'+query.get('employee_ids'),
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          searchItem["employee"]=resp.data
          })
          urlOptions.push("employee_ids=" + query.get('employee_ids'))
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      this.setState({ currentPage: parseInt(query.get('page')) })
      this.setState({ withResultsLabel: true})

      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({ searchMemo: searchItem })
    }
    axios({
      method: 'get',
      url: url + '?page=' + value+"&sort_by[name]=asc",
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ memos: resp.data.memos })
      this.setState({ currentPage: value })
      this.setState({ totalResults: resp.data.meta.total_count})
      this.setState({ totalPages: resp.data.meta.total_pages })
    })

    const { history } = this.props
    const params = new URLSearchParams()
    params.append("page", value)
    history.push({ search: params.toString() })
  }


  clearSearch(){
    this.setState({searchMemo: {created_at_from: '',created_at_to: '',title:'',content: '',sent: '',employee: {}}})
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ withResultsLabel: false})
    this.loadMemos(process.env.REACT_APP_API_DOMAIN + '/v1/memos')
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.search()
    }
  }
  handleEmployees(event,values){
    this.setState({
      searchMemo: {
        ...this.state.searchMemo,
        employee: values
      }
    })
  }

  handleShowPopup(item){
    this.setState({ showPopup: true, memo: item })
  }

  render() {

    if(this.state.redirectToShow){
      return (<Redirect to={{
        pathname: '/memos/' + this.state.memo.id
      }} />)
    }
    return (
      <>
        {
          this.state.load ? (
            <div>
              <MemoSearchForm
              employees={this.state.employees}
              item={this.state.searchMemo}
              handleChange={this.handleSearchInput}
              search={this.search}
              clearSearch={this.clearSearch}
              handleKeyPress={this.handleKeyPress}
              handleAutocomplete={this.handleEmployees}
              />

              <AnnouncementTable
                items={this.state.memos}
                icon={<AllInboxIcon fontSize="large" />}
                model="Memos"
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialMemo}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                rowClick={this.rowClick}
                withPagination={true}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                handleShowPopup={this.handleShowPopup}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                items={this.state.memos}
                handleClose={this.handleClose}
                maxWidth="xl"
              >
                <MemoForm
                 error={this.state.errorMessages}
                 item={this.state.memo}
                 submit={this.handleSubmit}
                 handleChange={this.handleInputChange}
                 employees={this.state.employees}
                 selectedEmployees={this.state.selectedEmployees}
                 handleAutocomplete={this.handleAutocomplete}
                 handleEditor={this.handleEditor}
                 onAttachmentAdd={this.onAttachmentAdd}
                 currentUser={this.props.currentUser}
                 officer={this.state.officer}
                 />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.showPopup}
                title={this.state.memo.title}
                handleClose={this.handleClose}
                maxWidth="lg"
              >
                <Memo item={this.state.memo} />
              </SimplePopUp>


              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.memo}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(Memos)
Memos.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  currentUser: PropTypes.object
}
