import React from 'react'
import styles from './PayrollAccounts.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  InputLabel,
  MenuItem,
  InputAdornment
} from '@material-ui/core'

import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';

class PayrollAccountsForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <Autocomplete
            value={this.props.updateAccount}
            id="account_id"
            size="small"
            fullWidth
            name="account_id"
            className={styles.formTextfield}
            options={this.props.accounts.sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            })}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={this.props.handleAutocomplete}
            renderInput={(params) => (
              <TextField {...params} name="account_id" variant="outlined" label="Account Name" required/>
            )}
          />
          <div className={styles.groupFieldsRegister}>
            <FormControl fullWidth="true" className={styles.RegistryField} size="small" required variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Registry Type</InputLabel>
              <Select
                fullWidth
                label="Registry Type"
                variant="outlined"
                value={this.props.item.registry_type}
                onChange={this.props.onchange}
                name="registry_type"
                inputProps={{
                  id: 'outlined-age-native-simple'
                }}
              >
                {
                  this.props.registryTypes.length > 0 ? (
                    this.props.registryTypes.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        >{type}
                      </MenuItem>
                    ))
                  ) : (null)
                }
              </Select>
            </FormControl>
            <TextField
              autoComplete="off"
              required
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth="true"
              label="Name"
              onChange={this.props.onchange}
              value={this.props.item.name}
            />
          </div>
          <div className={styles.groupFields}>
            <div>
            <TextField
              autoComplete="off"
              required
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="rate"
              type="number"
              fullWidth="true"
              label="Rate"
              onChange={this.props.onchange}
              value={this.props.item.rate}
              id="rate"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end" >%</InputAdornment>,
              }}
            />
              <p className={styles.error}>{this.props.error.rate? 'Rate ' + this.props.error.rate : ''}</p>
            </div>
            <FormControlLabel
              control={<Switch
                checked={this.props.item.is_deduction}
                onChange={this.props.handleSwitch}
                name="is_deduction"/>}
              label="Deduction "
              labelPlacement="right"
            />
            <FormControlLabel
              control={<Switch
                checked={this.props.item.is_taxable}
                onChange={this.props.handleSwitch}
                name="is_taxable"/>}
              label="Taxable "
              labelPlacement="right"
            />
          </div>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"
                  disabled={!this.props.item.name || !this.props.item.rate || !this.props.updateAccount.id || !this.props.item.registry_type}
                  id="payrollAccount-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default PayrollAccountsForm

PayrollAccountsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  registryTypes: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.object
}
