import React from 'react'
import styles from './LeaveApplications.module.css'
import PropTypes from 'prop-types'
import {
    TextField,
    Button,
    MenuItem, Grid, Typography
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send';
import { Link } from 'react-router-dom'

class ApprovalForm extends React.Component {
    render() {

        return (
            <div>
                <form>
                    <Grid container spacing={1}>
                        <div className={styles.flex}>
                            <div><b>Employee Name</b></div>
                            <Typography variant="body2">{this.props.item.employee_name}</Typography>
                        </div>

                        <Grid item xs={6}>

                            <div className={styles.flex}>
                                <div><b>Leave Type</b></div>
                                <div>{this.props.item.leave_type.name}</div>
                            </div>
                            <div className={styles.flex}>
                                <div><b>With Pay</b></div>
                                <div>{this.props.item.without_pay ? ("No") : ("Yes")}</div>
                            </div>

                        </Grid>
                        <Grid item xs={6}>

                            <div className={styles.flex}>
                                <div><b>From</b></div>
                                <div>0{new Date(this.props.item.starts_on).toLocaleDateString()}</div>
                            </div>
                            <div className={styles.flex}>
                                <div><b>To</b></div>
                                <div>0{new Date(this.props.item.ends_on).toLocaleDateString()}</div>
                            </div>
                            <div className={styles.flex}>
                                <div><b>Number of days</b></div>
                                <div>{this.props.item.no_of_days}</div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>

                            <div><b>Reason</b></div>
                            <div>{this.props.item.reason}</div>

                        </Grid>
                        <Grid item xs={12}>

                            <div><b>Supporting Document</b></div>
                            <div></div>
                            {
                                this.props.attachments.length > 0 ? (
                                    this.props.attachments.map((file, idx) => (
                                        <Link key={idx} target="_blank" to={file.file_url}>{file.file_name}</Link>
                                    ))
                                ) : null
                            }
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                select
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="new_officer_id"
                                fullWidth
                                label="Forward to Another Officer"
                                onChange={this.props.handleChange}
                                value={this.props.item.new_officer_id || ''}
                                id="leaveApplication-type"

                            >
                                {
                                    this.props.approvingOfficers.length > 0 ? (
                                        this.props.approvingOfficers.map((approvingOfficer) => (
                                            <MenuItem key={approvingOfficer.id} value={approvingOfficer.id}>{approvingOfficer.name}</MenuItem>
                                        ))
                                    ) : null

                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3} className={styles.sendButton}>
                            <Button
                                disabled={!this.props.item.new_officer_id}
                                variant="outlined" color="secondary" size="small"
                                onClick={() => this.props.forward()}
                            ><div className={styles.flexButton}><SendIcon size="small" />Send</div></Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline={true}
                                variant="outlined"
                                rows={3}
                                label="Comment"
                                fullWidth
                                value={this.props.item.comment}
                                onChange={this.props.handleChange}
                                name="comment"
                            />
                        </Grid>
                        <Grid item xs={12} />
                    </Grid>

                </form>
                <div></div>
                <div className={styles.actionButton}>
                    <Button variant="outlined"
                        id="leaveApplication-submit"
                        color="primary" onClick={() => this.props.approve()}
                    >APROVE</Button>
                    <Button variant="outlined"
                        disabled={!this.props.item.comment}
                        id="leaveApplication-submit"
                        color="secondary" onClick={() => this.props.reject()}
                    >REJECT</Button>
                </div>

            </div>
        )
    }
}
export default ApprovalForm

ApprovalForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    children: PropTypes.object,
    submit: PropTypes.func,
    leaveCredits: PropTypes.array,
    approvingOfficers: PropTypes.array,
    handleSwitch: PropTypes.func,
    handleStartDateChange: PropTypes.func,
    handleEndDateChange: PropTypes.func,
    maxDate: PropTypes.func,
    appliedDays: PropTypes.number,
    onFileChange: PropTypes.func,
    cancel: PropTypes.func,
    attachments: PropTypes.array,
    approve: PropTypes.func,
    reject: PropTypes.func,
    forward: PropTypes.func
}
