// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import BanksForm from './BanksForm'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import {withRouter} from 'react-router-dom'


export const Component = withRouter(() => {
})
import BanksSearch from './BanksSearch'

class Banks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Bank',
      banks: [],
      accounts: [],
      openPopup: false,
      openDeletePopup: false,
      bank: {},
      initialBanks: {
        id: '',
        bank_account_number: '',
        name: '',
        address: '',
        account_id: '',
      },
      title: '',
      searchBanks: {},
      withResultsLabel: false,
      withPagination: true,
      load: false,
      tableHead: ['name', 'bank_account_number', 'address', 'account.name', 'account.code'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleBankSearch = this.handleBankSearch.bind(this)
  }


  componentDidMount() {
    var url = ''
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/banks/search'
      this.setState({withResultsLabel: false})
    } else {
      var searchItem = {}
      url = process.env.REACT_APP_API_DOMAIN + '/v1/banks/search?'
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('name') !== null && query.get('name') !== '') {
        searchItem["name"] = query.get('name')
        urlOptions.push("name=*" + query.get('name') + '*')
      }
      if (query.get('address') !== null && query.get('address') !== '') {
        searchItem["address"] = query.get('address')
        urlOptions.push("address=" + query.get('address'))
      }
      if (query.get('bank_account_number') !== null && query.get('bank_account_number') !== '') {
        searchItem["bank_account_number"] = query.get('bank_account_number')
        urlOptions.push("bank_account_number=" + query.get('bank_account_number'))
      }
      if (query.get('account_id') !== null && query.get('account_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/accounts',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          resp.data.map((account) => {
            if (account.id.toLocaleString() === query.get('account_id')) {
              searchItem["account_id"] = account
              urlOptions.push("account_id=" + account.id)
            }
          })
        })
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      this.setState({currentPage: parseInt(query.get('page'))})
      this.setState({withResultsLabel: true})

      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({searchBanks: searchItem})
    }
    this.loadProducts(url)
  }

  loadProducts(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({banks: resp.data.banks})
      this.setState({totalPages: resp.data.meta.total_pages})
      axios({
        method: 'get',
        url:  process.env.REACT_APP_API_DOMAIN + '/v1/accounts/search?per_page=100000',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({accounts: resp.data.accounts})
        this.setState({load: true})
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.bank
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/banks/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/banks/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        banks: this.state.banks.filter(bank => bank.id !== item.id)
      })
      this.setState({banks: [...this.state.banks, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.bank.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/banks/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        banks: this.state.banks.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({updateAccount: item.account})
    this.setState({openPopup: true})
    this.setState({bank: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    if (e.target.name === "name") {
      this.setState({
        bank: {
          ...this.state.bank,
          name: e.target.value,
          name_type: this.state.bank.name + '(' + this.state.bank.type + ')'
        }
      })
    } else {
      this.setState({
        bank: {
          ...this.state.bank,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({bank: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }


  handleSearch() {
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/banks/search?'
    var searchUrl = ''
    var solrUrl = ''
    var options = []
    var searchOpts = []
    var withResultsLabel = false
    if (this.state.searchBanks.name !== undefined && this.state.searchBanks.name !== '') {
      options.push("name=" + this.state.searchBanks.name)
      searchOpts.push("name=*" + this.state.searchBanks.name + '*')
      withResultsLabel = true
    }
    if (this.state.searchBanks.address !== undefined && this.state.searchBanks.address !== '') {
      options.push("address=" + this.state.searchBanks.address)
      searchOpts.push("code=" + this.state.searchBanks.address)
      withResultsLabel = true
    }

    if (this.state.searchBanks.bank_account_number !== undefined && this.state.searchBanks.bank_account_number !== '') {
      options.push("bank_account_number=" + this.state.searchBanks.bank_account_number)
      searchOpts.push("bank_account_number=" + this.state.searchBanks.bank_account_number)
      withResultsLabel = true
    }
    if (this.state.searchBanks.account_id !== undefined && this.state.searchBanks.account_id !== '') {
      options.push("account_id=" + this.state.searchBanks.account_id.id)
      searchOpts.push("account_id=" + this.state.searchBanks.account_id.id)
      withResultsLabel = true
    }


    options.map((option, idx) => {
      if (idx > 0) {
        searchUrl = searchUrl + "&" + option
      } else {
        searchUrl = searchUrl + option
      }
    })
    searchOpts.map((option, idx) => {
      if (idx > 0) {
        solrUrl = solrUrl + "&" + option
      } else {
        solrUrl = solrUrl + option
      }
    })

    url = url + solrUrl
    axios({
      method: "get",
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({banks: resp.data.banks})
      this.setState({withResultsLabel: withResultsLabel})
      this.setState({totalResults: resp.data.meta.total_count})
      this.setState({totalPages: resp.data.meta.total_pages})
    }).catch(error => console.log(error.response))

    const {history} = this.props
    const params = new URLSearchParams()
    params.append("/search", searchUrl)
    history.push({search: searchUrl})
  }

  handleSearchInputChange(e) {
    this.setState({
      searchBanks: {
        ...this.state.searchBanks,
        [e.target.name]: e.target.value,
      }
    })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const {history} = this.props
    history.push({search: ''})
    this.setState({withResultsLabel: false})
    this.loadProducts(process.env.REACT_APP_API_DOMAIN + '/v1/banks/search')
    this.setState({searchBanks: {name: '', address: '', bank_account_number: '', account_id: ''}})
    this.setState({accounts: []})
  }

  handlePageChange(event, value) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/banks/search' + '?page=' + value,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({banks: resp.data.banks})
      this.setState({currentPage: value})
    })

    const {history} = this.props
    const params = new URLSearchParams()
    params.append("page", value)
    history.push({search: params.toString()})
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({updateAccount: values})
      this.setState({
        bank: {
          ...this.state.bank,
          account_id: values.id
        }
      })
    }
  }

  handleBankSearch(event, values) {
    if (values !== null) {
      this.setState({
        searchBanks:
          {
            ...this.state.searchBanks,
            account_id: values
          }
      })
    }
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>

              <BanksSearch
                clearSearch={this.clearSearch}
                item={this.state.searchBanks}
                search={this.handleSearch}
                accounts={this.state.accounts}
                handleChange={this.handleSearchInputChange}
                handleBankSearch={this.handleBankSearch}
                handleKeyPress={this.handleKeyPress}
              />

              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialBanks}
                items={this.state.banks}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                handlePageChange={this.handlePageChange}
                icon={<AccountBalanceOutlinedIcon fontSize="large"/>}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <BanksForm
                  error={this.state.error_messages}
                  updateAccount={this.state.updateAccount}
                  item={this.state.bank}
                  accounts={this.state.accounts}
                  banks={this.state.banks}
                  handleAutocomplete={this.handleAutocomplete}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.bank}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(Banks)
Banks.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
