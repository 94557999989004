import React from 'react'

import Drawer from '@material-ui/core/Drawer'
import clsx from "clsx";
import style from "../../App.module.css"
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import PropTypes from "prop-types";

class SideNavMenu extends React.Component {
  render() {
    return (
      <>
        <Drawer
          variant="permanent"
          className={clsx(style.drawer, {
            [style.drawerOpen]: this.props.openDrawer,
            [style.drawerClose]: !this.props.openDrawer
          })}
          classes={{
            paper: clsx({
              [style.drawerOpen1]: this.props.openDrawer,
              [style.drawerClose2]: !this.props.openDrawer
            })
          }}
        >
          <div className={style.toolbar}>
            <div className={style.companyLogo}></div>
            <IconButton onClick={this.props.toggleDrawer}>
              {style.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
            </IconButton>
          </div>
          <Divider/>
          <List name="sidenav">
            {this.props.menus.map((menu, idx) => (
              <ListItem button key={idx.toLocaleString()} name={menu.link} component={Link} to={menu.link}>
                <Tooltip title={menu.label}>
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={menu.label} className={style.lastLabel} />
              </ListItem>
            ))}
            {
            this.props.currentUser.is_employee?
            this.props.employeeMenu.map((menu, idx) => (
              <ListItem button key={idx.toLocaleString()} name={menu.link} component={Link} to={menu.link}>
                <Tooltip title={menu.label}>
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={menu.label} className={style.lastLabel} />
              </ListItem>
            ))
            :
            this.props.withSubMenus.map((menu) => (
              <ListItem style={{padding: '0'}} key={menu.parentLabel}>
                <Accordion style={{borderRadius: 'unset', boxShadow: 'inherit'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    className={style.flexCustom}
                  >
                    <Tooltip title={menu.parentLabel}>
                      <ListItemIcon>{menu.parentIcon}</ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={menu.parentLabel} className={style.parentLabel}/>
                  </AccordionSummary>
                  {
                  menu.subLabel?(
                  menu.subLabel.map((sub, idx) => (
                    <AccordionDetails key={idx} className={sub.class ? style.subBody : style.none}>
                      <List className={style.listItem}>
                        <ListItem button component={Link} to={sub.link} disableGutters={true}>
                          <>
                            <Tooltip title={sub.label?(sub.label):('')}>
                              <ListItemIcon>{sub.childIcon}</ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={sub.label} className={style.lastLabel}/>

                            {sub.childParentLabel ? (
                              <Accordion className={style.childSubBody}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon/>}
                                  className={style.childSub}
                                >
                                  <Tooltip title={sub.label?(sub.label):('')}>
                                    <ListItemIcon>{sub.parentIcon}</ListItemIcon>
                                  </Tooltip>
                                  <ListItemText primary={sub.childParentLabel}
                                                className={style.childParentLabel}/>
                                </AccordionSummary>
                                {
                                sub.childLabel?(
                                sub.childLabel.map((child) => (
                                  <AccordionDetails
                                    key={child.label}
                                    className={style.none}
                                  >
                                    <List>
                                      <ListItem style={{paddingTop: '0',}} button component={Link}
                                                to={child.link}
                                      >
                                        <Tooltip title={child.label?(child.label):('')}>
                                          <ListItemIcon>{child.childIcon}</ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary={child.label} className={style.lastLabels}/>
                                      </ListItem>
                                    </List>
                                  </AccordionDetails>
                                ))
                                ):null
                              }
                              </Accordion>
                            ) : null}
                          </>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  ))
                  ):null
                }
                </Accordion>
              </ListItem>
            ))
            }
          </List>
          {process.env.REACT_APP_POWERED_BY === true ?
            <div className={this.props.openDrawer === true? style.companyName : style.displayNone }>
            <p>
              {process.env.REACT_APP_POWERED_BY}
            </p>
          </div> :null}
        </Drawer>
      </>
    )
  }
}

export default SideNavMenu

SideNavMenu.propTypes = {
  menus: PropTypes.array,
  withSubMenus: PropTypes.array,
  openDrawer: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  currentUser: PropTypes.object,
  employeeMenu: PropTypes.array,
}
