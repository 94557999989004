import React from 'react'
import styles from './Memos.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button, MenuItem
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'

class MemoSearchForm extends React.Component {
    render() {
        return (
            <div>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <TextField
                                type="date"
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="created_at_from"
                                fullWidth={true}
                                label="Starts at"
                                onChange={this.props.handleChange}
                                value={this.props.item.created_at_from}
                                id="memo-created_at_from"
                                onKeyPress={this.props.handleKeyPress}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="date"
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="created_at_to"
                                fullWidth={true}
                                label="Ends at"
                                onChange={this.props.handleChange}
                                value={this.props.item.created_at_to}
                                onKeyPress={this.props.handleKeyPress}
                                id="memo-created_at_to"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                value={this.props.item.employee}
                                id="employees"
                                className={styles.textFields}
                                name="employees"
                                fullWidth
                                size="small"
                                options={(this.props.employees).sort(function (a, b) {
                                    if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
                                    if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
                                    return 0;
                                })}
                                getOptionLabel={(option) => option.name_formal || ''}
                                getOptionSelected={(option, value) => option.name_formal === value.name_formal}
                                onChange={this.props.handleAutocomplete}
                                renderInput={(params) => (
                                    <TextField {...params} name="employee" variant="outlined" label="Employee" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="sent"
                                fullWidth
                                label="Sent"
                                onChange={this.props.handleChange}
                                value={this.props.item.sent || ''}
                                id="memo-sent"
                            >
                                <MenuItem value="">&nbsp;</MenuItem>
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                autoComplete="off"
                                
                                variant="outlined"
                                size="small"
                                name="title"
                                fullWidth
                                onKeyPress={this.props.handleKeyPress}
                                label="Title"
                                onChange={this.props.handleChange}
                                value={this.props.item.title}
                                id="memo-title"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                autoComplete="off"
                                variant="outlined"
                                size="small"
                                name="content"
                                fullWidth
                                onKeyPress={this.props.handleKeyPress}
                                label="Content"
                                onChange={this.props.handleChange}
                                value={this.props.item.content}
                                id="memo-title"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div className={styles.actionButton}>
                                <Button variant="outlined" id="memo-search" color="primary" onClick={() => this.props.search()} >Search</Button>
                                <Button variant="outlined" id="memo-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                </form>


            </div>
        )
    }
}
export default MemoSearchForm

MemoSearchForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    children: PropTypes.object,
    search: PropTypes.func,
    clearSearch: PropTypes.func,
    selectedEmployees: PropTypes.array,
    employees: PropTypes.array,
    handleAutocomplete: PropTypes.func,
    handleKeyPress: PropTypes.func

}
