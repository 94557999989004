import React from 'react'
import styles from './Branches.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button, Grid
} from '@material-ui/core'
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import BranchDepartmentForm from './BranchDepartmentForm';
class BranchesForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={styles.actionButton}>
                <FormControlLabel
                  control={<Switch
                    size="medium"
                    checked={this.props.item.is_active}
                    onChange={this.props.handleSwitch}
                    name="is_active" />}
                  label="Active ?"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="name"
                fullWidth="true"
                label="Name"
                onChange={this.props.onchange}
                value={this.props.item.name}
                id="branch-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="short_name"
                fullWidth="true"
                label="Short Name"
                onChange={this.props.onchange}
                value={this.props.item.short_name}
                id="short_name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="address"
                fullWidth="true"
                label="Address"
                onChange={this.props.onchange}
                value={this.props.item.address}
                id="address"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="telephone"
                fullWidth="true"
                label="Telephone Number"
                onChange={this.props.onchange}
                value={this.props.item.telephone}
                id="telephone"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="fax_no"
                fullWidth="true"
                label="Fax Number"
                onChange={this.props.onchange}
                value={this.props.item.fax_no}
                id="fax_no"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="email"
                fullWidth="true"
                label="Email"
                onChange={this.props.onchange}
                value={this.props.item.email}
                id="email"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                type="number"
                size="small"
                name="rank"
                fullWidth="true"
                label="Rank"
                onChange={this.props.onchange}
                value={this.props.item.rank}
                id="rank"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="prefix"
                fullWidth="true"
                label="Prefix"
                onChange={this.props.onchange}
                value={this.props.item.prefix}
                id="prefix"
              />
            </Grid>
          </Grid>
        </form>
        <h3>Department :</h3>
        <BranchDepartmentForm
          handleAutocomplete={this.props.handleAutocomplete}
          departments={this.props.departments}
          addDepartment={this.props.addDepartment}
          selectedDepartments={this.props.selectedDepartments}
          submit={this.props.handleSubmitDepartment}
        />

        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name || !this.props.item.address} id="branch-submit" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default BranchesForm

BranchesForm.propTypes = {
  onchange: PropTypes.func,
  handleSwitch: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  departments: PropTypes.array,
  addDepartment: PropTypes.func,
  selectedDepartments: PropTypes.array,
  handleSubmitDepartment: PropTypes.func
}
