import React from 'react'

import axios from 'axios'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'

import PhotoForm from './PhotoForm'
import PhotoIcon from '@material-ui/icons/Photo'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import AlertMessage from '../../shared/Notify/AlertMessage'

class PhotoGalleries extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            model: 'Photo',
            photos: [],
            initialPhoto: { title: '', file: '', description: '' },
            photo: {},
            openPhotoPopup: false,
            openDeletePopup: false,
            load: false,
            tableHead: ['image_file_name', 'description'],
            notify: {},
            isOpen: false,
            message: '',
            type: ''

        }
        this.openPhoto = this.openPhoto.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleOpenDeletePopUp = this.handleOpenDeletePopUp.bind(this)
    }

    componentDidMount() {
        this.loadPhotos(process.env.REACT_APP_API_DOMAIN + '/v1/photos')
    }

    loadPhotos(url) {
        axios({
            method: 'get',
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ photos: resp.data })
            this.setState({ load: true })
        }).catch(error => {this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})})
    }

    openPhoto(item, isAdd, model) {
        this.setState({ photo: item })
        var textTitle = ''
        if (isAdd) {
            textTitle = "Upload " + model
        }
        this.setState({ title: textTitle })
        this.setState({ openPhotoPopup: true })
    }

    onFileChange(e) {
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            photo: {
                ...this.state.photo,
                file: e.target.files[0],
                title: e.target.files[0].name
            }
        })
    }

    handleCheck(e) {
        this.setState({
            photo: {
                ...this.state.photo,
                publish: e.target.checked
            }
        })
    }

    handleSubmit() {
        const item = this.state.photo
        const file = item.file
        const title = item.file.name
        const description = item.description
        const formdata = new FormData()
        formdata.append('image', file)
        formdata.append('title', title)
        formdata.append('description', description)
        formdata.append('rank', item.rank)
        formdata.append('publish', item.publish)

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/photos',
            data: (formdata),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                photos: this.state.photos.filter(attach => attach.id !== item.id)
            })
            this.setState({ photos: [...this.state.photos, newData] })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
            this.setState({ openPhotoPopup: false })
        }).catch(error => {this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})})
    }

    handleChange(e) {
        this.setState({
            photo: {
                ...this.state.photo,
                [e.target.name]: e.target.value
            }
        })
    }

    handleOpenDeletePopUp(item, model) {
        this.setState({ model: model })
        this.setState({ photo: item })
        this.setState({ openDeletePopup: true })
    }

    handleClose() {
        this.setState({ openDeletePopup: false })
        this.setState({ openPhotoPopup: false })
    }

    handleDeleteItem() {
        const DeleteItemId = this.state.photo.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/photos/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                photos: this.state.photos.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ openDeletePopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => {this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})})
    }

    render() {
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <SimpleTable
                                handleClick={this.openPhoto}
                                initialItem={this.state.initialPhoto}
                                items={this.state.photos}
                                model={this.state.model}
                                onOpenDeletePopup={this.handleOpenDeletePopUp}
                                headers={this.state.tableHead}
                                withShow={false}
                                withEdit={false}
                                icon={<PhotoIcon fontSize="large" />}
                            />
                            <SimplePopUp
                                openPopup={this.state.openPhotoPopup}
                                title={this.state.title}
                                items={this.state.photos}
                                handleClose={this.handleClose}
                                maxWidth="sm"
                            >
                                <PhotoForm
                                    photo={this.state.photo}
                                    error={this.state.error_messages}
                                    onFileChange={this.onFileChange}
                                    submit={this.handleSubmit}
                                    onchange={this.handleChange}
                                    handleCheck={this.handleCheck}
                                />
                            </SimplePopUp>

                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openDeletePopup}
                                item={this.state.photo}
                                delete={this.handleDeleteItem}
                                handleDeleteClose={this.handleClose}
                                model={this.state.model}
                            />
                            <AlertMessage
                                notify={this.state.notify}
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />
                        </div>
                    ) : (<ProgressBar model={this.state.model} />)
                }
            </>
        )
    }
}

export default PhotoGalleries
