// eslint-disable-next-line

import React from 'react'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './Employees.module.css'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import moment from 'moment'
import { Autocomplete } from '@material-ui/lab'
import { Grid, Avatar, Button, TextField } from '@material-ui/core'

import PropTypes from 'prop-types'
import ApprovingOfficerForm from './ApprovingOfficerForm'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import AttachmentForm from './AttachmentForm'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import SalaryHistoryForm from './SalaryHistoryForm'
import EmploymentStatusForm from './EmploymentStatusForm'
import RecurrenceForm from '../Recurrences/RecurrenceForm'
import AnnouncementTable from '../Announcements/AnnouncementTable'
import PayrollEmployeeInfo from '../PayrollList/PayrollEmployeeInfo'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import AlertMessage from '../../shared/Notify/AlertMessage'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

class EmployeeShow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Employee',
            value: 0,
            employees: [],
            selectedEmployees: [],
            employmentStatuses: [],
            attachments: [],
            approvingOfficers: [],
            memos: [],
            recurrences: [],
            salaries: [],
            payrollDetails: [],
            employee: {},
            attachment: {},
            openAttachmentForm: false,
            approvingOfficer: {},
            openApprovingOfficerForm: false,
            deleteAttachmentPopup: false,
            salaryHistory: {},
            openSalaryHistoryForm: false,
            openSalaryHistoryDelete: false,
            salaryFormTitle: '',
            kinds: [],
            states: [],
            openEmploymentForm: false,
            openDeleteEmployment: false,
            employmentStatus: {},
            employmentTitle: '',
            accounts: [],
            openRecurrencePopUp: false,
            recurrence: {},
            openRecurrenceDeletePopup: false,
            selectedRecurrenceEmployee: {},
            selectedRecurrenceEmployees: [],
            selectedAccount: {},
            employeePermissions: [],
            permissions: [],
            openPermissionForm: false,
            selectedEmployeePermissions: [],
            redirectToMemoShow: false,
            memo: {},
            openPayrollDetails: false,
            payrollList: {},
            payrollAdjustment: [],
            payrollEmployee: {},
            employeeId: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleCreateAttachment = this.handleCreateAttachment.bind(this)
        this.handleCreateApprovingOfficer = this.handleCreateApprovingOfficer.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSubmitApprovingOfficers = this.handleSubmitApprovingOfficers.bind(this)
        this.handleApprovingOfficers = this.handleApprovingOfficers.bind(this)
        this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this)
        this.handleOpenDeleteAttachment = this.handleOpenDeleteAttachment.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.handleAttachmentSubmit = this.handleAttachmentSubmit.bind(this)
        this.handleAttachmentInputChange = this.handleAttachmentInputChange.bind(this)
        this.handleCreateorUpdateSalaryHistory = this.handleCreateorUpdateSalaryHistory.bind(this)
        this.handleOpenDeleteSalaryHistory = this.handleOpenDeleteSalaryHistory.bind(this)
        this.handleSalaryRateChange = this.handleSalaryRateChange.bind(this)
        this.handleSalaryChange = this.handleSalaryChange.bind(this)
        this.handleSubmitSalary = this.handleSubmitSalary.bind(this)
        this.handleDeleteSalaryHistory = this.handleDeleteSalaryHistory.bind(this)
        this.handleCreateOrUpdateEmploymentStatus = this.handleCreateOrUpdateEmploymentStatus.bind(this)
        this.handleOpenDeleteEmploymentStatus = this.handleOpenDeleteEmploymentStatus.bind(this)
        this.handleSubmitEmployment = this.handleSubmitEmployment.bind(this)
        this.handleEmploymentChange = this.handleEmploymentChange.bind(this)
        this.handleDeleteEmployment = this.handleDeleteEmployment.bind(this)
        this.handleSubmitRecurrence = this.handleSubmitRecurrence.bind(this)
        this.handleDeleteRecurrence = this.handleDeleteRecurrence.bind(this)
        this.handleCreateorUpdateRecurrence = this.handleCreateorUpdateRecurrence.bind(this)
        this.handleRecurrenceChange = this.handleRecurrenceChange.bind(this)
        this.handleOpenDeleteRecurrence = this.handleOpenDeleteRecurrence.bind(this)
        this.handleRecurrenceAutoComplete = this.handleRecurrenceAutoComplete.bind(this)
        this.handleCheckRecurrence = this.handleCheckRecurrence.bind(this)
        this.handleAmountRecurrenceChange = this.handleAmountRecurrenceChange.bind(this)
        this.handleNumberRecurrenceChange = this.handleNumberRecurrenceChange.bind(this)
        this.handleRecurrenceAccount = this.handleRecurrenceAccount.bind(this)
        this.handleClickPermission = this.handleClickPermission.bind(this)
        this.handleSubmitPermission = this.handleSubmitPermission.bind(this)
        this.handlePermissionAutoComplete = this.handlePermissionAutoComplete.bind(this)
        this.rowClick = this.rowClick.bind(this)
        this.handleShowPopUpPayroll = this.handleShowPopUpPayroll.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
    }

    componentDidMount() {
        if(this.props.employeeId !== undefined){
            
            this.setState({employeeId: this.props.employeeId.toString()})
            this.loadEmployee(process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.props.employeeId.toString())
        }else{
            if(this.props.match){
                if(this.props.match.params){
                    if(this.props.match.params.id){
                        this.setState({employeeId: this.props.match.params.id.toString()})
                        this.loadEmployee(process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.props.match.params.id.toString())
                    }
                }

            }
        }
    }

    loadAttachments(url) {
        axios({
            method: 'get',
            url: url + '/attachments',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ attachments: resp.data })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'
                })}
        })
    }
    loadPayslips(url) {
        axios({
            method: 'get',
            url: url + '/payroll_details',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ payrollDetails: resp.data })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})
            }
        })
    }

    loadSalaryHistory(url) {
        axios({
            method: 'get',
            url: url + '/salaries',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ salaries: resp.data })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})
            }
        })
    }

    loadEmploymentHistory(url) {
        axios({
            method: 'get',
            url: url + '/employment_statuses',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employmentStatuses: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})
            }
        })
    }

    loadRecurrences(url) {
        axios({
            method: 'get',
            url: url + '/recurrences',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ recurrences: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_accounts',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ accounts: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    loadMemos(url) {
        axios({
            method: 'get',
            url: url + '/memos',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ memos: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    loadPermissions(url) {
        axios({
            method: 'get',
            url: url + '/permissions',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employeePermissions: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/permissions',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ permissions: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }


    handleChange(event, newValue) {
        const url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employeeId 
        if(newValue === 1){
            this.loadAttachments(url)
        }else if(newValue === 2){
            this.loadPayslips(url)
        }else if(newValue === 3){
            this.loadSalaryHistory(url)
        }else if(newValue === 4){
            this.loadEmploymentHistory(url)
        }else if(newValue === 5){
            this.loadRecurrences(url)
        }else if (newValue === 6){
            this.loadMemos(url)
        }else if(newValue === 7){
            this.loadPermissions(url)
        }
        this.setState({ value: newValue })
    }

    handleCreateApprovingOfficer() {
        var officers = []
        if (this.state.approvingOfficers !== null) {
            if (this.state.approvingOfficers.length > 0) {
                this.state.approvingOfficers.map((officer) => {
                    officers.push(officer.officer)
                })
            }
        }
        this.setState({ selectedEmployees: officers })
        this.setState({ openApprovingOfficerForm: true })
        this.setState({ approvingOfficer: {} })
    }

    handleApprovingOfficers(event, values) {
        this.setState({
            selectedEmployees: values
        })
    }

    loadApprovingOfficers(url){
        if(this.props.employeeId){
            axios({
                method: 'get',
                url: url + '/approving-officers',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ approvingOfficers: resp.data })
            }).catch(error => {
                if (error.response.status === 422) {
                    this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
                } else {
                    this.setState({
                        isOpen: true,
                        message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                        type: 'error'
                    })
                    this.setState({ openPopup: false })
                }
            })
        }
    }

    

    loadEmployee(url) {
        
        if(this.props.employeeId !== null &&localStorage.getItem('employee') !== null){
            if(JSON.parse(localStorage.getItem('employee')).is_employee){
                this.setState({ employee: JSON.parse(localStorage.getItem('employee')) })
                this.setState({ load: true })
            }else{
                axios({
                    method: 'get',
                    url: url,
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    this.setState({ employee: resp.data })
                    this.setState({ load: true })
                }).catch(() => {
                    this.setState({
                        isOpen: true,
                        message:" Unexpected Error Problem Occurred",
                        type: 'error'
                    })
                })
            }
        }else{
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ employee: resp.data })
                this.setState({ load: true })
            }).catch(() => {
                this.setState({
                    isOpen: true,
                    message:" Unexpected Error Problem Occurred",
                    type: 'error'
                })
            })
        }
        if(this.props.employeeId && localStorage.getItem('approvingOfficers')){
            this.setState({ approvingOfficers: JSON.parse(localStorage.getItem('approvingOfficers')) })
        }else{
            this.loadApprovingOfficers(url)
        }

        
        
        if(!this.props.employeeId){
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?is_active=true',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ employees: resp.data.employees })
    
            }).catch(error => {
                if (error.response.status === 422) {
                    this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
                } else {
                    this.setState({
                        isOpen: true,
                        message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                        type: 'error'
                    })
                    this.setState({ openPopup: false })
                }
            })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employment_statuses/kinds',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ kinds: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employment_statuses/states',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ states: resp.data })

        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
        }
    }


    handleCreateAttachment() {
        this.setState({ openAttachmentForm: true })

    }

    handleClose() {
        this.setState({ openApprovingOfficerForm: false })
        this.setState({ openAttachmentForm: false })
        this.setState({ openSalaryHistoryDelete: false })
        this.setState({ deleteAttachmentPopup: false })
        this.setState({ openSalaryHistoryForm: false })
        this.setState({ openEmploymentForm: false })
        this.setState({ openDeleteEmployment: false })
        this.setState({ openRecurrencePopUp: false })
        this.setState({ openRecurrenceDeletePopup: false })
        this.setState({ openPermissionForm: false })
        this.setState({ openPayrollDetails: false })
    }

    handleAttachmentInputChange(e) {
        this.setState({
            attachment: {
                ...this.state.attachment,
                [e.target.name]: e.target.value
            }
        })
    }

    handleEmploymentChange(e) {
        this.setState({
            employmentStatus: {
                ...this.state.employmentStatus,
                [e.target.name]: e.target.value
            }
        })
    }

    onFileChange(e) {
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            attachment: {
                ...this.state.attachment,
                file: e.target.files[0],
                title: e.target.files[0].name
            }
        })
    }

    onAvatarChange(e) {
        e.stopPropagation()
        e.preventDefault()
        const emp = this.state.employee
        emp['image'] = e.target.files[0]
        axios({
            method: 'put',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employeeId,
            data: emp,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employee: resp.data })
        })

    }

    handleAttachmentSubmit() {
        const id = this.state.employeeId
        const item = this.state.attachment
        const file = item.file
        const description = item.description
        const formdata = new FormData()
        formdata.append('file', file)
        formdata.append('description', description)
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + id + '/attachments',
            data: (formdata),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                attachments: this.state.attachments.filter(attach => attach.id !== item.id)
            })
            this.setState({ attachments: [...this.state.attachments, newData] })

            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
            this.setState({ openAttachmentForm: false })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleOpenDeleteAttachment(item) {
        this.setState({ deleteAttachmentPopup: true })
        this.setState({ attachment: item })
    }

    handleDeleteAttachment() {
        const DeleteItemId = this.state.attachment.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/attachments/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                attachments: this.state.attachments.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ deleteAttachmentPopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleDeleteSalaryHistory() {
        const DeleteItemId = this.state.salaryHistory.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/salaries/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                salaries: this.state.salaries.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ openSalaryHistoryDelete: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleSubmitApprovingOfficers() {
        var employee = this.state.employee
        var appOfficers = []
        if (this.state.selectedEmployees.length > 0) {
            this.state.selectedEmployees.map((emp) => { appOfficers.push(emp.id) })
        }
        employee["approving_officer_ids"] = appOfficers
        axios({
            method: 'put',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id,
            data: (employee),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employee: resp.data })
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/employee_officers',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ approvingOfficers: resp.data })
            })
            this.setState({ openApprovingOfficerForm: false })

            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }
    handleCreateorUpdateSalaryHistory(item, isAdd) {
        var textTitle = ''
        if (isAdd) {
            textTitle = 'Create Salary History'
        } else {
            textTitle = 'Edit Salary History'
        }
        this.setState({ openSalaryHistoryForm: true })
        this.setState({ salaryHistory: item })
        this.setState({ salaryFormTitle: textTitle })
    }

    handleSalaryChange(e) {
        this.setState({
            salaryHistory: {
                ...this.state.salaryHistory,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSalaryRateChange(e) {
        this.setState({
            salaryHistory: {
                ...this.state.salaryHistory,
                rate: e.floatValue
            }
        })
    }

    handleSubmitSalary() {
        var item = this.state.salaryHistory
        item["employee_id"] = this.state.employee.id
        var method = ''
        var url = ''
        if (item.id === '') {
            method = 'post'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/salaries'
        } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/salaries/' + item.id
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                salaries: this.state.salaries.filter(salary => salary.id !== item.id)
            })
            this.setState({ salaries: [...this.state.salaries, newData] })
            this.setState({ openSalaryHistoryForm: false })

            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleOpenDeleteSalaryHistory(item) {
        this.setState({ openSalaryHistoryDelete: true })
        this.setState({ salaryHistory: item })
    }

    handleCreateOrUpdateEmploymentStatus(item, isAdd) {
        var textTitle = ''

        if (isAdd) {
            textTitle = 'Create Salary History'
        } else {
            textTitle = 'Edit Salary History'

        }
        this.setState({ openEmploymentForm: true })
        this.setState({ employmentStatus: item })
        this.setState({ employmentTitle: textTitle })
    }
    handleOpenDeleteEmploymentStatus(item) {

        this.setState({ employmentStatus: item })
        this.setState({ openDeleteEmployment: true })
    }

    handleSubmitEmployment() {
        var item = this.state.employmentStatus
        item["employee_id"] = this.state.employee.id
        var method = ''
        var url = ''
        if (item.id === '') {
            method = 'post'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/employment_statuses'
        } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/employment_statuses/' + item.id
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                employmentStatuses: this.state.employmentStatuses.filter(salary => salary.id !== item.id)
            })
            this.setState({ employmentStatuses: [...this.state.employmentStatuses, newData] })
            this.setState({ openEmploymentForm: false })

            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleDeleteEmployment() {
        const DeleteItemId = this.state.employmentStatus.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/employment_statuses/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                employmentStatuses: this.state.employmentStatuses.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ openDeleteEmployment: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleSubmitRecurrence() {
        const item = this.state.recurrence
        var method = ''
        var url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/'
        var empId = ''
        item["account_id"] = this.state.selectedAccount.id
        if (item.id === '' || item.id === undefined) {
            method = 'post'
        } else {
            method = 'put'
        }
        item["employee_ids"] = [this.state.employee.id]
        axios({
            method: method,
            url: url + this.state.employee.id + '/recurrences/' + item.id,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + empId,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                newData["employee"] = resp.data
                newData["account"] = this.state.selectedAccount
                this.setState({
                    recurrences: this.state.recurrences.filter(recurrence => recurrence.id !== item.id)
                })
                this.setState({ recurrences: [...this.state.recurrences, newData] })
            })
            this.setState({ openRecurrencePopUp: false })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
        this.setState({ openPopup: false })
    }

    handleDeleteRecurrence() {
        const DeleteItemId = this.state.recurrence.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.recurrence.employee.id + '/recurrences/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                recurrences: this.state.recurrences.filter(item => item.id !== DeleteItemId)
            })
            this.setState({ openRecurrenceDeletePopup: false })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleCreateorUpdateRecurrence(item, isAdd, model) {
        var textTitle = ''
        var selectedEmployees = []
        var account = {}
        var employee = {}
        if (isAdd) {
            textTitle = 'Create ' + model
        } else {
            textTitle = 'Edit ' + model
            account = item.account
            employee = item.employee
            this.setState({ selectedAccount: account })
        }
        this.setState({ selectedRecurrenceEmployee: employee })
        this.setState({ selectedRecurrenceEmployees: selectedEmployees })
        this.setState({ openRecurrencePopUp: true })
        this.setState({ recurrence: item })
        this.setState({ recurrenceTitle: textTitle })
    }

    handleRecurrenceChange(e) {
        if (e.target.name === "loan_date") {
            this.setState({
                recurrence: {
                    ...this.state.recurrence,
                    [e.target.name]: e.target.value,
                    date_of_effect: e.target.value
                }
            })
        } else {
            this.setState({
                recurrence: {
                    ...this.state.recurrence,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    handleOpenDeleteRecurrence(item) {
        this.setState({ model: 'Recurrence' })
        this.setState({ recurrence: item })
        this.setState({ openRecurrenceDeletePopup: true })
    }

    handleRecurrenceAutoComplete(event, values) {
        if (this.state.recurrence.id !== undefined && this.state.recurrence.id !== "") {
            this.setState({ selectedRecurrenceEmployee: values })
        } else {
            this.setState({ selectedRecurrenceEmployees: values })
        }
    }

    handleRecurrenceAccount(event, values) {
        this.setState({ selectedAccount: values })
    }

    handleNumberRecurrenceChange(e) {
        this.setState({
            recurrence: {
                ...this.state.recurrence,
                principal_amount: e.value
            }
        })
    }

    handleAmountRecurrenceChange(e) {
        this.setState({
            recurrence: {
                ...this.state.recurrence,
                payable_amount: e.value
            }
        })
    }

    handleCheckRecurrence(e) {
        this.setState({
            recurrence: {
                ...this.state.recurrence,
                active: e.target.checked
            }
        })
    }

    handleClickPermission() {
        this.setState({ selectedEmployeePermissions: this.state.employeePermissions })
        this.setState({ openPermissionForm: true })
    }

    handleSubmitPermission() {
        var employee = this.state.employee
        var permissions = []
        if (this.state.selectedEmployeePermissions.length > 0) {
            this.state.selectedEmployeePermissions.map((permission) => { permissions.push(permission.id) })
        }
        employee["permission_ids"] = permissions
        axios({
            method: 'put',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id,
            data: (employee),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ employee: resp.data })
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.employee.id + '/permissions',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ employeePermissions: resp.data })
            })
            this.setState({ openPermissionForm: false })

            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handlePermissionAutoComplete(event, values) {
        this.setState({ selectedEmployeePermissions: values })
    }

    rowClick(item) {
        this.setState({ memo: item })
        this.setState({ redirectToMemoShow: true })
    }

    handleShowPopUpPayroll(item) {

        this.setState({ payrollEmployee: item })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_details/' + item.id + '/payroll_adjustments/',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ payrollAdjustment: resp.data })
            this.setState({ openPayrollDetails: true })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })


    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({isOpen: false})
      }
      
    render() {
        const { avatar } = this.state.employee.avatar ? this.state.employee.avatar : {};
        let filename = null;
        filename = avatar
            ? (<span><b>File Selected</b> - {avatar.name}</span>)
            : (<span>Drag your files here or click in this area.</span>);

        const profile = this.state.employee.profile_attributes
        const employmentStatus = this.state.employee.employment_status
        const emp = this.state.employee

        if (this.state.redirectToMemoShow) {
            return (<Redirect to={{
                pathname: '/memos/' + this.state.memo.id
            }} />)
        }

        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <div className={styles.headers}>
                                        <div><b> Employee No </b></div>
                                        <div>:&emsp; {profile.reference_no}</div>
                                    </div>
                                    <div className={styles.headers}>
                                        <div><b> Employee Name </b></div>
                                        <div>:&emsp; {profile.name}</div>
                                    </div>
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={2}>
                                    {
                                        this.state.employee.image ? (
                                            <Avatar
                                                className={styles.avatar}
                                                variant="square"
                                                src={this.state.employee.image ? this.state.employee.image.default : ''}
                                                alt=""
                                            />
                                        ) : (
                                            <form>
                                                <div className={styles.formBody}>

                                                    <input
                                                        id="file"
                                                        type="file"
                                                        name="file"
                                                        className={styles.input}
                                                        onChange={(e) => this.onAvatarChange(e)}
                                                    />
                                                    <label
                                                        className={styles.filelabel}
                                                        htmlFor="file">
                                                        <VerticalAlignBottomIcon fontSize="large" />
                                                        <br />
                                                        {filename}

                                                    </label>

                                                </div>
                                            </form>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenav : styles.tabHead}
                            >
                                <Tab label="Profile" {...a11yProps(0)} />
                                <Tab label="201 Files" {...a11yProps(1)} />
                                <Tab label="Payslips" {...a11yProps(2)} />
                                <Tab label="Salary History" {...a11yProps(3)} />
                                <Tab label="Employment History" {...a11yProps(4)} />
                                <Tab label="Recurrences" {...a11yProps(5)} />
                                <Tab label="Memos" {...a11yProps(6)} />
                                {this.props.employeeId?
                                  null
                                :  <Tab label="Permissions" {...a11yProps(7)} /> }
                            </Tabs>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBodyEmployee} value={this.state.value} index={0}>
                                <>
                                    <div className={styles.showTitleHeader}>
                                        <div className={styles.detailList}>
                                            <h3><b> Personal Information </b></h3>
                                        </div>
                                        <div>
                                            <div className={styles.groupButtonShow}>
                                                <IconButton color="primary" size="small">
                                                    <Link to={{
                                                        pathname: `/employees`,
                                                    }}>
                                                        <ArrowBackIosIcon fontSize="small" />
                                                    </Link>
                                                </IconButton>
                                                <IconButton color="primary" size="small">
                                                    <Link to={{
                                                        pathname: `/employees/${this.state.employee.id}/edit`,
                                                    }}>
                                                        <EditIcon fontSize="small" />
                                                    </Link>
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={this.handleOpenDeletePopup}
                                                >
                                                    {
                                                        this.state.employee.can_delete ? (
                                                            <DeleteIcon />
                                                        ) : null
                                                    }
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className={styles.detailsBody}>
                                        <div className={styles.leftShowDetails}>
                                            <div className={styles.detailList}>
                                                <div><b> Date of birth </b></div>
                                                <div>:&emsp; {moment(profile.birth_date).format('L')}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Gender </b></div>
                                                <div>:&emsp; {profile.gender}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Email </b></div>
                                                <div>:&emsp; {this.state.employee.email}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Civil Status </b></div>
                                                <div>:&emsp; {profile.civil_status}</div>
                                            </div>
                                           
                                            <div className={styles.detailList}>
                                                <div><b> Address </b></div>
                                                <div>:&emsp;{profile.home_no?profile.home_no:''} {profile.address}</div>
                                            </div>
                                        </div>
                                        <div className={styles.rightShowDetails}>
                                            <div className={styles.detailList}>
                                                <div><b> SSS </b></div>
                                                <div>:&emsp; {profile.sss_no}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> PAGIBIG </b></div>
                                                <div>:&emsp; {profile.pagibig_no}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> TIN </b></div>
                                                <div>:&emsp; {profile.tin_no}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Philhealth </b></div>
                                                <div>:&emsp; {profile.philhealth_no}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Contact No. (Mobile) </b></div>
                                                <div>:&emsp; {profile.mobile_no}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Contact No. (Home) </b></div>
                                                <div>:&emsp; {profile.home_no}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.showTitleHeader}>
                                        <div className={styles.detailList}>
                                            <h3><b> Employment Details </b></h3>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className={styles.detailsBody}>
                                        <div className={styles.leftShowDetails}>
                                            <div className={styles.detailList}>
                                                <div><b> Bank Account No. </b></div>
                                                <div>:&emsp; {profile.bank_account_no}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Current Salary </b></div>
                                                <div>:&emsp; {this.state.employee.salary? parseFloat(this.state.employee.salary.rate).toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : '0.0'}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Date Hired </b></div>
                                                <div>:&emsp; {moment(this.state.employee.date_hired).format('L')}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Employment Status </b></div>
                                                <div>:&emsp; {employmentStatus ? employmentStatus.kind : null}</div>
                                            </div>

                                        </div>
                                        <div className={styles.rightShowDetails}>
                                            <div className={styles.detailList}>
                                                <div><b> Branch </b></div>
                                                <div>:&emsp; {emp.branch ? (emp.branch.name) : ("")}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Department </b></div>
                                                <div>:&emsp; {emp.department ? (emp.department.name) : ("")}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Division </b></div>
                                                <div>:&emsp; {emp.division ? (emp.division.name) : ("")}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Position </b></div>
                                                <div>:&emsp; {emp.position ? (emp.position.name) : ("")}</div>
                                            </div>
                                            <div className={styles.detailList}>
                                                <div><b> Tax Exempted </b></div>
                                                <div>:&emsp; {profile.is_tax_exempted ? ("Yes") : ("No")}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <SimpleTable
                                        items={this.state.approvingOfficers}
                                        model="Approving Officer"
                                        headers={['officer.name']}
                                        handleClick={this.handleCreateApprovingOfficer}
                                    />
                                </>
                            </TabPanel>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={1}>
                                <SimpleTable
                                    items={this.state.attachments}
                                    model="Salary History"
                                    noActionHeader={true}
                                    headers={['file_name', 'description']}
                                    withAdd={true}
                                    handleClick={this.handleCreateAttachment}
                                    onOpenDeletePopup={this.handleOpenDeleteAttachment}
                                />
                            </TabPanel>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={2}>
                                <SimpleTable
                                    items={this.state.payrollDetails}
                                    model="Employee Payroll"
                                    noActionHeader={true}
                                    headers={['payroll_period', 'payroll_income', 'gross_income', 'payroll_deductions', 'net_pay']}
                                    nodelete={true}
                                    withShowPopUp={true}
                                    handleShowPopUp={this.handleShowPopUpPayroll}
                                />
                            </TabPanel>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={3}>
                                <SimpleTable
                                    items={this.state.salaries.sort(
                                        (a, b) => new Date(a.date_effective).getTime() - new Date(b.date_effective).getTime()
                                    ).reverse()}
                                    model="Salary History"
                                    noActionHeader={true}
                                    withEdit={true}
                                    headers={['date_effective', 'remarks', 'rate']}
                                    withAdd={true}
                                    initialItem={{ id: '', date_effective: '', rate: '', remarks: '' }}
                                    handleClick={this.handleCreateorUpdateSalaryHistory}
                                    onOpenDeletePopup={this.handleOpenDeleteSalaryHistory}
                                />
                            </TabPanel>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={4}>

                                <SimpleTable
                                    items={this.state.employmentStatuses.sort(
                                        (a, b) => new Date(a.date_effective).getTime() - new Date(b.date_effective).getTime()
                                    ).reverse()}
                                    model="Employment Status"
                                    noActionHeader={true}
                                    withEdit={true}
                                    withAdd={true}
                                    initialItem={{ id: '', date_effective: '', rate: '', remarks: '', kind: '' }}
                                    headers={['date_effective', 'kind', 'remarks', 'state']}
                                    handleClick={this.handleCreateOrUpdateEmploymentStatus}
                                    onOpenDeletePopup={this.handleOpenDeleteEmploymentStatus}
                                />
                            </TabPanel>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={5}>
                                <SimpleTable
                                    items={this.state.recurrences}
                                    model="Recurrence"
                                    noActionHeader={true}
                                    withEdit={true}
                                    withAdd={true}
                                    headers={['account.name', "period", "payable_amount", "principal_amount", "loan_date", "maturity_date", "date_of_effect", "active"]}
                                    initialItem={{ id: '', active: true, period: '' }}
                                    handleClick={this.handleCreateorUpdateRecurrence}
                                    onOpenDeletePopup={this.handleOpenDeleteRecurrence}
                                />
                            </TabPanel>
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBodyMemo} value={this.state.value} index={6}>
                                <AnnouncementTable
                                    items={this.state.memos}
                                    withOutActions={true}
                                    rowClick={this.rowClick}
                                />
                            </TabPanel>

                            {this.props.employeeId?  null:
                            <TabPanel className={localStorage.getItem('openSideNav') === 'false' ? styles.openSidenavTabBody : styles.tabBody} value={this.state.value} index={7}>
                                <SimpleTable
                                    items={this.state.employeePermissions}
                                    model="Permissions"
                                    noActionHeader={true}
                                    headers={['name']}
                                    withEdit={false}
                                    withAdd={true}
                                    handleClick={this.handleClickPermission}
                                />
                            </TabPanel>
                            }
                            <SimplePopUp
                                openPopup={this.state.openApprovingOfficerForm}
                                title="Update Approving Officers"
                                handleClose={this.handleClose}
                                maxWidth={this.state.sm}
                            >
                                <ApprovingOfficerForm
                                    handleApprovingOfficers={this.handleApprovingOfficers}
                                    submit={this.handleSubmitApprovingOfficers}
                                    employees={this.state.employees.filter(employee => employee.id !== this.state.employee.id)}
                                    selectedEmployees={this.state.selectedEmployees}
                                    handleAutocomplete={this.handleAutocomplete}
                                />
                            </SimplePopUp>

                            <SimplePopUp
                                openPopup={this.state.openAttachmentForm}
                                title="Add 201 File"
                                handleClose={this.handleClose}
                                maxWidth={this.state.sm}
                            >
                                <AttachmentForm
                                    attachment={this.state.attachment}
                                    item={this.state.attachment}
                                    onFileChange={this.onFileChange}
                                    submit={this.handleAttachmentSubmit} onchange={this.handleAttachmentInputChange} />
                            </SimplePopUp>
                            <SimpleDeletePopUp
                                openDeletePopup={this.state.deleteAttachmentPopup}
                                item={this.state.attachment}
                                delete={this.handleDeleteAttachment}
                                handleDeleteClose={this.handleClose}
                                model="Attachment"
                            />
                            <SimplePopUp
                                openPopup={this.state.openSalaryHistoryForm}
                                title={this.state.salaryFormTitle}
                                handleClose={this.handleClose}
                                maxWidth="sm"
                            >
                                <SalaryHistoryForm
                                    salaries_attributes={this.state.salaryHistory}
                                    submit={this.handleSubmitSalary}
                                    handleSalaryChange={this.handleSalaryChange}
                                    handleRateChange={this.handleSalaryRateChange}
                                />
                            </SimplePopUp>
                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openSalaryHistoryDelete}
                                item={this.state.salaryHistory}
                                delete={this.handleDeleteSalaryHistory}
                                handleDeleteClose={this.handleClose}
                                model="Salary History"
                            />
                            <SimplePopUp
                                openPopup={this.state.openEmploymentForm}
                                title={this.state.employmentTitle}
                                handleClose={this.handleClose}
                                maxWidth="sm"
                            >
                                <EmploymentStatusForm
                                    handleEmploymentChange={this.handleEmploymentChange}
                                    submit={this.handleSubmitEmployment}
                                    employment_statuses_attributes={this.state.employmentStatus}
                                    kinds={this.state.kinds}
                                    states={this.state.states}

                                />
                            </SimplePopUp>
                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openDeleteEmployment}
                                item={this.state.employmentStatus}
                                delete={this.handleDeleteEmployment}
                                handleDeleteClose={this.handleClose}
                                model="Employment Status"
                            />
                            <SimplePopUp
                                openPopup={this.state.openRecurrencePopUp}
                                title={this.state.recurrenceTitle}
                                items={this.state.recurrences}
                                handleClose={this.handleClose}
                                maxWidth="md"
                            >
                                <RecurrenceForm
                                    error={this.state.error_messages}
                                    item={this.state.recurrence}
                                    submit={this.handleSubmitRecurrence}
                                    handleChange={this.handleRecurrenceChange}
                                    employees={this.state.employees}
                                    selectedEmployees={this.state.selectedRecurrenceEmployees}
                                    selectedEmployee={this.state.selectedRecurrenceEmployee}
                                    handleAutocomplete={this.handleRecurrenceAutoComplete}
                                    handleCheck={this.handleCheckRecurrence}
                                    handleAmountChange={this.handleAmountRecurrenceChange}
                                    handleNumberChange={this.handleNumberRecurrenceChange}
                                    accounts={this.state.accounts}
                                    selectedAccount={this.state.selectedAccount}
                                    handleAccount={this.handleRecurrenceAccount}
                                    withoutEmployeeField={true}
                                />
                            </SimplePopUp>

                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openRecurrenceDeletePopup}
                                item={this.state.recurrence}
                                delete={this.handleDeleteRecurrence}
                                handleDeleteClose={this.handleClose}
                                model="Recurrence"
                            />
                            <SimplePopUp
                                openPopup={this.state.openPermissionForm}
                                title="Update Permissions"
                                items={this.state.recurrences}
                                handleClose={this.handleClose}
                                maxWidth="sm"
                            >
                                <div>
                                    <form>
                                        <Autocomplete
                                            value={this.state.selectedEmployeePermissions}
                                            id="permissions"
                                            multiple
                                            name="permissions"
                                            fullWidth
                                            size="small"
                                            options={(this.state.permissions).sort(function (a, b) {
                                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                                return 0;
                                            })}
                                            getOptionLabel={(option) => option.name || ''}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            onChange={this.handlePermissionAutoComplete}
                                            renderInput={(params) => (
                                                <TextField {...params} name="permissions" variant="outlined" label="Permissions" />
                                            )}
                                        />
                                    </form>
                                    <br />
                                    <div className={styles.actionButton}>
                                        <Button variant="outlined" id="ApprovingOfficer-submit" color="primary" onClick={() => this.handleSubmitPermission()} >Save</Button>
                                    </div>
                                </div>
                            </SimplePopUp>
                            <SimplePopUp
                                openPopup={this.state.openPayrollDetails}
                                title={this.state.employee.name_formal + ' / ' + this.state.employee.reference_no}
                                handleClose={this.handleClose}
                                maxWidth={this.state.maxWidth}
                            >
                                <PayrollEmployeeInfo
                                    payrollList={this.state.payrollEmployee.payroll}
                                    item={this.state.payrollEmployee}
                                    payrollAdjustment={this.state.payrollAdjustment}
                                />
                            </SimplePopUp>
                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
                    <AlertMessage
                        notify={this.state.notify}
                        handleCloseAlert={this.handleCloseAlert}
                        isOpen={this.state.isOpen}
                        type={this.state.type}
                        message={this.state.message}
                    />
            </>
        )
    }
}

export default EmployeeShow
EmployeeShow.propTypes = {
    employeeId: PropTypes.number,
    location: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        })
    })
}
