import React from 'react'
import styles from './Employees.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class ApprovingOfficerForm extends React.Component {
    render() {
        return (
            <div>
                <form>
                    <Autocomplete
                        value={this.props.selectedEmployees}
                        id="employees"
                        multiple
                        name="employees"
                        fullWidth
                        size="small"
                        options={(this.props.employees).sort(function (a, b) {
                            if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
                            if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
                            return 0;
                        })}
                        getOptionLabel={(option) => option.name_formal || ''}
                        getOptionSelected={(option, value) => option.name_formal === value.name_formal}
                        onChange={this.props.handleApprovingOfficers}
                        renderInput={(params) => (
                            <TextField {...params} name="employees" variant="outlined" label="Employees" />
                        )}
                    />
                </form>
                <br />
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="ApprovingOfficer-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
                </div>
            </div>
        )
    }
}
export default ApprovingOfficerForm

ApprovingOfficerForm.propTypes = {
    item: PropTypes.object,
    submit: PropTypes.func,
    selectedEmployees: PropTypes.array,
    employees: PropTypes.array,
    handleApprovingOfficers: PropTypes.func
}
