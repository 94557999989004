// eslint-disable-next-line
// import styles from './Recurrence.module.css'

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import RecurrenceForm from './RecurrenceForm'
import AllInboxIcon from '@material-ui/icons/AllInbox';

import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

class Recurrences extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Recurrence',
      recurrences: [],
      openPopup: false,
      openDeletePopup: false,
      recurrence: {id: ''},
      employees: [],
      selectedEmployees: [],
      selectedEmployee: {},
      initialRecurrence: {
        id: '',
        active: true
      },
      title: '',
      load: false,
      tableHead: ['employee.name_formal', 'account.name', "period", "payable_amount", "principal_amount", "loan_date", "maturity_date", "date_of_effect", "active"],
      withShow: false,
      withEdit: true,
      maxWidth: 'md',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      accounts: [],
      selectedAccount: {},
      errorMessages: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleAmountChange = this.handleAmountChange.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleAccount = this.handleAccount.bind(this)
  }

  componentDidMount() {
    this.loadRecurrences(process.env.REACT_APP_API_DOMAIN + '/v1/recurrences')
  }

  loadRecurrences(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ recurrences: resp.data, load: true })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?is_active=true&per_page=50000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ employees: resp.data.employees })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_accounts',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ accounts: resp.data })
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.recurrence
    var method = ''
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/'
    var empIds = []
    var empId = ''
    item["account_id"] = this.state.selectedAccount.id
    if (item.id === '' || item.id === undefined) {
      method = 'post'
      if (this.state.selectedEmployees.length > 0) {
        this.state.selectedEmployees.map((emp) => {
          empIds.push(emp.id)
        })
      }
      item["employee_ids"] = empIds
    } else {
      method = 'put'

      empIds = [this.state.selectedEmployee.id]
      item["employee_ids"] = [empId]
    }
    if (empIds.length > 0) {
      empIds.map((empId) => {
        axios({
          method: method,
          url: url + empId + '/recurrences/' + item.id,
          data: (item),
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          const newData = resp.data
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + empId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            newData["employee"] = resp.data
            newData["account"] = this.state.selectedAccount
            this.setState({
              recurrences: this.state.recurrences.filter(recurrence => recurrence.id !== item.id)
            })
            this.setState({ recurrences: [...this.state.recurrences, newData] })

          })

          this.setState({
            isOpen: true,
            message: 'Submitted Successfully',
            type: 'success'
          })
        }).catch(error => {
          if (error.response.status === 422) {
            var errors = {}
            Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
              errors[field] = error.response.data.errors[0].detail.errors[field][0]
              if (field.includes('.')) {
                errors[field.split(".")[1]] = error.response.data.errors[0].detail.errors[field][0]
              } else {
                errors[field] = error.response.data.errors[0].detail.errors[field][0]
              }
            })
            this.setState({ errorMessages: errors })
          } else {
            this.setState({
              isOpen: true,
              message: error.response.status.toString() + " Unexpected Error Problem Occurred",
              type: 'error'
            })
            this.setState({ openPopup: false })
          }
        })
      })
    }
    this.setState({ openPopup: false })

  }

  handleDeleteItem() {
    const DeleteItemId = this.state.recurrence.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.state.recurrence.employee.id + '/recurrences/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        recurrences: this.state.recurrences.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    var selectedEmployees = []
    var account = {}
    var employee = {}
    if (isAdd) {
      textTitle = 'Create ' + model

    } else {
      textTitle = 'Edit ' + model
      account = item.account
      employee = item.employee
      this.setState({ selectedAccount: account })
    }

    this.setState({ selectedEmployee: employee })
    this.setState({ selectedEmployees: selectedEmployees })
    this.setState({ openPopup: true })
    this.setState({ recurrence: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    if (e.target.name === "loan_date") {
      this.setState({
        recurrence: {
          ...this.state.recurrence,
          [e.target.name]: e.target.value,
          date_of_effect: e.target.value
        }
      })
    } else {
      this.setState({
        recurrence: {
          ...this.state.recurrence,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ recurrence: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }
  handleAutocomplete(event, values) {
    if (this.state.recurrence.id !== undefined && this.state.recurrence.id !== "") {
      this.setState({ selectedEmployee: values })
    } else {
      this.setState({ selectedEmployees: values })
    }
  }

  handleAccount(event, values) {
    this.setState({ selectedAccount: values })
  }

  handleNumberChange(e) {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        principal_amount: e.value
      }
    })
  }

  handleAmountChange(e) {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        payable_amount: e.value
      }
    })
  }

  handleCheck(e) {
    this.setState({
      recurrence: {
        ...this.state.recurrence,
        active: e.target.checked
      }
    })
  }

  render() {
    const recurrences = this.state.recurrences.sort(function (a, b) {
      if (a.employee.name_formal.toLowerCase() < b.employee.name_formal.toLowerCase()) return -1;
      if (a.employee.name_formal.toLowerCase() > b.employee.name_formal.toLowerCase()) return 1;
      return 0;
    })
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialRecurrence}
                items={recurrences}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<AllInboxIcon fontSize="large" />}

              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                items={this.state.recurrences}
                handleClose={this.handleClose}
                maxWidth={this.state.mdWidth}
              >
                <RecurrenceForm
                  error={this.state.errorMessages}
                  item={this.state.recurrence}
                  submit={this.handleSubmit}
                  handleChange={this.handleInputChange}
                  employees={this.state.employees}
                  selectedEmployees={this.state.selectedEmployees}
                  selectedEmployee={this.state.selectedEmployee}
                  handleAutocomplete={this.handleAutocomplete}
                  handleCheck={this.handleCheck}
                  handleAmountChange={this.handleAmountChange}
                  handleNumberChange={this.handleNumberChange}
                  accounts={this.state.accounts}
                  selectedAccount={this.state.selectedAccount}
                  handleAccount={this.handleAccount}
                />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.recurrence}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default Recurrences
