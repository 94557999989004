import React from 'react'
import styles from './PayrollList.module.css'

import PropTypes from 'prop-types'

import { Grid
} from '@material-ui/core'
import moment from "moment";
class PayrollEmployeeInfo extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div className={styles.employeeLabel}><b>Employee Information</b></div>
          <div className={styles.employeeDetailBody}>
            <Grid container spacing={1} className={styles.employeeInfo}>
              <Grid item xs={6} className={styles.value}>
                <div className={styles.detailList}>
                  <div><b>Department</b></div>
                  <div>:&emsp; {this.props.item.employee.department.name} </div>
                </div>
                <div className={styles.detailList}>
                  <div><b> Payroll Period </b></div>
                  <div>:&emsp; {moment(this.props.payrollList.date_from).format('MMM DD')}-{moment(this.props.payrollList.date_to).format('DD YYYY')}</div>
                </div>
              </Grid>
              <Grid item xs={6} className={styles.value}>
                <div className={styles.detailList}>
                  <div><b>Position</b></div>
                  <div className={styles.positionBody}> <div>:&emsp;</div> {this.props.item.position}</div>
                </div>
              </Grid>
            </Grid>

            <div className={styles.employeeLabel}><b>Work Hours</b></div>

            <Grid container spacing={1}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={6} className={styles.deductionBody}>
                <div className={styles.detailList}>
                  <div><b>Regular Hours</b></div>
                  <div>({this.props.payrollList.work_hours})
                  </div>
                </div>
              </Grid>
              <Grid item xs={4} className={styles.valueDeduction}>
                <div> {parseFloat(this.props.item.basic_income).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
              </Grid>
            </Grid>
            <div className={styles.employeeLabel}><b>Other Income</b></div>
            <Grid container spacing={1}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={6} className={styles.deductionBody}>
                {this.props.payrollAdjustment.map((account) => (
                  account.is_deduction === false ? (
                    <div key={account.id}><b>
                      {account.account_name}:</b></div>
                  ):null
                ))}
                <div className={styles.totalNetPayValue}><b>Gross Income:</b></div>
              </Grid>

              <Grid item xs={4} className={styles.valueDeduction}>
                {this.props.payrollAdjustment.map((account) => (
                  account.is_deduction === false ? (
                    <div key={account.id}><b>
                      {parseFloat(account.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b></div>
                  ):null
                ))}
                <div className={styles.totalNetPayValue}>{parseFloat(this.props.item.gross_income).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
              </Grid>
            </Grid>
            <div className={styles.employeeLabel}><b>Deductions</b></div>

            <Grid container spacing={1}>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={6} className={styles.deductionBody}>
                <div><b>SSS:</b></div>
                <div><b>PAGIBIG:</b></div>
                <div><b>PHILHEALTH:</b></div>
                <div><b>WITHOLDING TAX	:</b></div>
                {this.props.payrollAdjustment.map((account) => (
                  account.is_deduction === true ? (
                  <div key={account.id}><b>
                    {account.account_name}:</b></div>
                  ):null
                ))}
                <div className={styles.totalDeduction}><b>Total Deduction:</b></div>
                <div className={styles.totalNetPayValue}><b>Net Pay:</b></div>
              </Grid>
              <Grid item xs={4} className={styles.valueDeduction}>
                <div>{parseFloat(this.props.item.employee_sss).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
                <div>{parseFloat(this.props.item.employee_pagibig).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
                <div>{parseFloat(this.props.item.employee_philhealth).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
                <div>{parseFloat(this.props.item.employee_bir).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
                {this.props.payrollAdjustment.map((account) => (
                  account.is_deduction === true ? (
                  <div key={account.id}>{parseFloat(account.amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
                  ):null
                ))}
                <div className={styles.totalDeduction}>{this.props.item.total_deductions.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
                <div className={styles.totalNetPay}>{parseFloat(this.props.item.net_pay).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</div>
              </Grid>
            </Grid>
          </div>


        </div>
      </div>
    )
  }
}

export default PayrollEmployeeInfo

PayrollEmployeeInfo.propTypes = {
  item: PropTypes.object,
  payrollList: PropTypes.object,
  payrollAdjustment: PropTypes.array,
}
