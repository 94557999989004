// eslint-disable-next-line


import React from 'react'
import PropTypes from 'prop-types'
import SimpleTableGroupBy from '../../shared/SimpleTable/SimpleTableGroupBy'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import {withRouter} from 'react-router-dom'
import moment from "moment";

export const Component = withRouter(() => {
})
import LeaveRecordsSearch from './LeaveRecordsSearch'
import SimpleTable from "../../shared/SimpleTable/SimpleTable";

class LeaveRecords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Leave Record',
      leaveApplications: [],
      accounts: [],
      leaveApplicationTypes: [],
      showLeaveRecordHistory: [],
      branches: [],
      officers: [],
      leaveTypes: [],
      employees: [],
      departments: [],
      updateAccount: {},
      openPopup: false,
      showPopUp: false,
      openDeletePopup: false,
      leaveApplication: {},
      initialProduct: {
        id: '',
        account_id: '',
        name: '',
        short_name: '',
        amount: '',
        unit: '',
        leaveApplication_type_id: '',
        expirable: false,
        tax_exempt: false,
      },
      title: '',
      searchLeave: {
        starts_on_from: moment().startOf('year').format("YYYY-MM-DD"),
        starts_on_to: moment().endOf('year').format("YYYY-MM-DD"),
      },
      withResultsLabel: false,
      withPagination: true,
      withShowPopUp: true,
      load: false,
      tableHead: ['leave_type.name', 'inclusive_dates', 'without_pay', 'reason', 'officer.name', 'request_status'],
      groupLabel: 'Employee Name',
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
  }


  componentDidMount() {
    var url = ''
    var starts_on_from  = 'starts_on_from=' + this.state.searchLeave.starts_on_from
    var starts_on_to  = '&starts_on_to=' + this.state.searchLeave.starts_on_to
    if (this.props.location.search === '') {
      url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/search?' + starts_on_from + starts_on_to
      this.setState({withResultsLabel: false})
    } else {
      var searchItem = {}
      url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/search?'
      const query = new URLSearchParams(this.props.location.search);
      var urlOptions = []
      if (query.get('name') !== null && query.get('name') !== '') {
        searchItem["name"] = query.get('name')
        urlOptions.push("name=*" + query.get('name') + '*')
      }
      if (query.get('starts_on_to') !== null && query.get('starts_on_to') !== '') {
        searchItem["starts_on_to"] = query.get('starts_on_to')
        urlOptions.push("starts_on_to=" + query.get('starts_on_to'))
      }
      if (query.get('starts_on_from') !== null && query.get('starts_on_from') !== '') {
        searchItem["starts_on_from"] = query.get('starts_on_from')
        urlOptions.push("starts_on_from=" + query.get('starts_on_from'))
      }
      if (query.get('leave_type_id') !== null && query.get('leave_type_id') !== '') {
        searchItem["leave_type_id"] = query.get('leave_type_id')
        urlOptions.push("leave_type_id=" + query.get('leave_type_id'))
      }

      if (query.get('branch_id') !== null && query.get('branch_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          resp.data.map((branch) => {
            if (branch.id.toLocaleString() === query.get('branch_id')) {
              searchItem["branch_id"] = branch
              urlOptions.push("branch_id=" + branch.id)
            }
          })
        })
      }
      if (query.get('employee_id') !== null && query.get('employee_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/employees',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          resp.data.map((employee) => {
            if (employee.id.toLocaleString() === query.get('employee_id')) {
              searchItem["employee_id"] = employee
              urlOptions.push("employee_id=" + employee.id)
            }
          })
        })
      }
      if (query.get('officer_id') !== null && query.get('officer_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/employee_officers',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          resp.data.map((officer) => {
            if (officer.id.toLocaleString() === query.get('officer_id')) {
              searchItem["officer_id"] = officer
              urlOptions.push("officer_id=" + officer.id)
            }
          })
        })
      }
      if (query.get('department_id') !== null && query.get('department_id') !== '') {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          resp.data.map((department) => {
            if (department.id.toLocaleString() === query.get('department_id')) {
              searchItem["department_id"] = department
              urlOptions.push("department_id=" + department.id)
            }
          })
        })
      }
      if (query.get('page') !== null && query.get('page') !== '') {
        urlOptions.push("page=" + query.get('page'))
      }
      this.setState({currentPage: parseInt(query.get('page'))})
      this.setState({withResultsLabel: true})

      if (urlOptions.length > 0) {
        urlOptions.map((urlOption, idx) => {
          if (idx > 0) {
            url = url + "&" + urlOption
          } else {
            url = url + urlOption
          }
        })
      }
      this.setState({searchLeave: searchItem})
    }
    this.loadLeaveRecords(url)
  }

  loadLeaveRecords(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      var leaveRecord = resp.data.leave_applications,
      result = leaveRecord.reduce(function (r, a) {
        r[a.employee_name] = r[a.employee_name] || [];
        r[a.employee_name].push(a);
        return r;
      }, Object.create(null));
      this.setState({leaveApplications: result})
      this.setState({totalPages: resp.data.meta.total_pages})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/accounts',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({accounts: resp.data})
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/employee_officers',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({officers: resp.data})
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            this.setState({branches: resp.data})
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_types',
              headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
            }).then(resp => {
              this.setState({leaveTypes: resp.data})
              axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/employees',
                headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
              }).then(resp => {
                this.setState({employees: resp.data})
                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
                  headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
                }).then(resp => {
                  this.setState({departments: resp.data})
                  this.setState({load: true})
                }).catch(error => console.log(error.response))
              }).catch(error => console.log(error.response))
            }).catch(error => console.log(error.response))
          }).catch(error => console.log(error.response))
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.leaveApplication
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        leaveApplications: this.state.leaveApplications.filter(leaveApplication => leaveApplication.id !== item.id)
      })
      this.setState({leaveApplications: [...this.state.leaveApplications, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.leaveApplication.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        leaveApplications: this.state.leaveApplications.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
    this.setState({showPopUp: false})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({updateAccount: item.account})
    this.setState({openPopup: true})
    this.setState({leaveApplication: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      leaveApplication: {
        ...this.state.leaveApplication,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({leaveApplication: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      leaveApplication: {
        ...this.state.leaveApplication,
        [event.target.name]: value
      }
    })
  }

  handleAutocomplete(event, values) {
    if (values !== null) {
      this.setState({updateAccount: values})
      this.setState({
        leaveApplication: {
          ...this.state.leaveApplication,
          account_id: values.id
        }
      })
    }
  }

  handleShowPopUp(item) {
    var Id = item.employee.id
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + Id + '/leave_applications',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({showLeaveRecordHistory: resp.data})
      this.setState({showPopUp: true})
    }).catch(error => console.log(error.response))
  }

  amountChange(e) {
    this.setState({
      leaveApplication: {
        ...this.state.leaveApplication,
        amount: e.floatValue
      }
    })
  }

  handleSearch() {
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/search?'
    var searchUrl = ''
    var solrUrl = ''
    var options = []
    var searchOpts = []
    var withResultsLabel = false
    if (this.state.searchLeave.name !== undefined && this.state.searchLeave.name !== '') {
      options.push("name=" + this.state.searchLeave.name)
      searchOpts.push("name=*" + this.state.searchLeave.name + '*')
      withResultsLabel = true
    }
    if (this.state.searchLeave.starts_on_from !== undefined && this.state.searchLeave.starts_on_from !== '') {
      options.push("starts_on_from=" + this.state.searchLeave.starts_on_from)
      searchOpts.push("starts_on_from=" + this.state.searchLeave.starts_on_from)
      withResultsLabel = true
    }
    if (this.state.searchLeave.starts_on_to !== undefined && this.state.searchLeave.starts_on_to !== '') {
      options.push("starts_on_to=" + this.state.searchLeave.starts_on_to)
      searchOpts.push("starts_on_to=" + this.state.searchLeave.starts_on_to)
      withResultsLabel = true
    }
    if (this.state.searchLeave.leave_type_id !== undefined && this.state.searchLeave.leave_type_id !== '') {
      options.push("leave_type_id=" + this.state.searchLeave.leave_type_id)
      searchOpts.push("leave_type_id=" + this.state.searchLeave.leave_type_id)
      withResultsLabel = true
    }
    if (this.state.searchLeave.employee_id !== undefined && this.state.searchLeave.employee_id !== '') {
      options.push("employee_id=" + this.state.searchLeave.employee_id)
      searchOpts.push("employee_id=" + this.state.searchLeave.employee_id)
      withResultsLabel = true
    }
    if (this.state.searchLeave.branch_id !== undefined && this.state.searchLeave.branch_id !== '') {
      options.push("branch_id=" + this.state.searchLeave.branch_id.id)
      searchOpts.push("branch_id=" + this.state.searchLeave.branch_id.id)
      withResultsLabel = true
    }
    if (this.state.searchLeave.request_status !== undefined && this.state.searchLeave.request_status !== '') {
      options.push("request_status=" + this.state.searchLeave.request_status)
      searchOpts.push("request_status=" + this.state.searchLeave.request_status)
      withResultsLabel = true
    }
    if (this.state.searchLeave.department_id !== undefined && this.state.searchLeave.department_id !== '') {
      options.push("department_id=" + this.state.searchLeave.department_id)
      searchOpts.push("department_id=" + this.state.searchLeave.department_id)
      withResultsLabel = true
    }


    options.map((option, idx) => {
      if (idx > 0) {
        searchUrl = searchUrl + "&" + option
      } else {
        searchUrl = searchUrl + option
      }
    })
    searchOpts.map((option, idx) => {
      if (idx > 0) {
        solrUrl = solrUrl + "&" + option
      } else {
        solrUrl = solrUrl + option
      }
    })

    url = url + solrUrl
    axios({
      method: "get",
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      var leaveRecord = resp.data.leave_applications,
        result = leaveRecord.reduce(function (r, a) {
          r[a.employee_name] = r[a.employee_name] || [];
          r[a.employee_name].push(a);
          return r;
        }, Object.create(null));
      this.setState({leaveApplications: result})
      this.setState({withResultsLabel: withResultsLabel})
      this.setState({totalResults: resp.data.meta.total_count})
      this.setState({totalPages: resp.data.meta.total_pages})
    }).catch(error => console.log(error.response))

    const {history} = this.props
    const params = new URLSearchParams()
    params.append("/search", searchUrl)
    history.push({search: searchUrl})
  }

  handleSearchInputChange(e) {
    var lastDay = null
    if (e.target.name === 'starts_on_from') {
      lastDay = moment(e.target.value).endOf('month').format("YYYY-MM-DD")
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          [e.target.name]: e.target.value,
          starts_on_to: lastDay
        }
      })
    } else {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const {history} = this.props
    history.push({search: ''})
    this.setState({withResultsLabel: false})
    this.loadLeaveRecords(process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/search')
    this.setState({searchLeave: {request_status: '', branch_id: {}, leave_type_id: {}, employee_id: '' , officer_id: {}, department_id: {} }})
    this.setState({leaveApplicationTypes: []})
    this.setState({branches: [] })
    this.setState({employees: []})
    this.setState({officers: []})
  }

  handlePageChange(event, value) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/search' + '?page=' + value,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({leaveApplications: resp.data.leave_applications})
      this.setState({currentPage: value})
    })

    const {history} = this.props
    const params = new URLSearchParams()
    params.append("page", value)
    history.push({search: params.toString()})
  }

  handleAutocompleteSearch(event, values) {
    if (event.target.id.includes("branch_id")) {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          branch_id: values
        }
      })
    } else if (event.target.id.includes("employee_id")) {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          employee_id: values.id
        }
      })
    }
    else if (event.target.id.includes("officer_id")) {
      this.setState({
        searchLeave: {
          ...this.state.searchLeave,
          officer_id: values.id
        }
      })
    }
    else {
      if (values !== null) {
        this.setState({
          searchLeave: {
            ...this.state.searchLeave,
            department_id: values.id
          }
        })
      }
    }
  }


  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>

              <LeaveRecordsSearch
                clearSearch={this.clearSearch}
                item={this.state.searchLeave}
                branches={this.state.branches}
                leaveTypes={this.state.leaveTypes}
                employees={this.state.employees}
                officers={this.state.officers}
                departments={this.state.departments}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleAutocompleteSearch={this.handleAutocompleteSearch}
                handleKeyPress={this.handleKeyPress}
              />

              <SimpleTableGroupBy
                initialItem={this.state.initialProduct}
                items={this.state.leaveApplications}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShowPopUp={this.state.withShowPopUp}
                handleShowPopUp={this.handleShowPopUp}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                groupLabel={this.state.groupLabel}
                handlePageChange={this.handlePageChange}
                icon={<ExitToAppOutlinedIcon fontSize="large"/>}
              />

              <SimplePopUp
                openPopup={this.state.showPopUp}
                title="Leave History"
                handleClose={this.handleClose}
                maxWidth='md'
              >
                <SimpleTable
                  model={this.state.model} headers={['leave_type.name', 'inclusive_dates', 'without_pay', 'reason', 'request_status']}
                  items={this.state.showLeaveRecordHistory.sort(
                    (a, b) => new Date(a.starts_on).getTime() - new Date(b.ends_on).getTime()
                  ).reverse()}
                  removeLabel={true}
                />

              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.leaveApplication}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(LeaveRecords)
LeaveRecords.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
