import React from 'react'
import styles from './Banks.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button, Grid
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class BanksForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                required
                size="small"
                name="name"
                fullWidth="true"
                label="Name"
                onChange={this.props.onchange}
                value={this.props.item.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="address"
                fullWidth="true"
                label="Address"
                onChange={this.props.onchange}
                value={this.props.item.address}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="bank_account_number"
                fullWidth="true"
                label="Bank Account Number"
                onChange={this.props.onchange}
                value={this.props.item.bank_account_number}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={this.props.updateAccount}
                id="account_id"
                size="small"
                fullWidth
                className={styles.textFields}
                name="account_id"
                options={this.props.accounts.sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                renderInput={(params) => (
                  <TextField {...params} name="user_ids" variant="outlined" label="Account Name"/>
                )}
              />

            </Grid>
          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"
                  disabled={!this.props.item.name}
                  id="bank-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default BanksForm

BanksForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  amountChange: PropTypes.func,
  submit: PropTypes.func,
}
