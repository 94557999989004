import React from 'react'
import styles from './LeaveRecords.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid, InputLabel
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";

class PayeesSearch extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              type="date"
              variant="outlined"
              size="small"
              name="starts_on_from"
              fullWidth={true}
              label="Start Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.starts_on_from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              type="date"
              size="small"
              name="starts_on_to"
              fullWidth={true}
              label="End Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.starts_on_to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name="branch_id"
              id="branch_id"
              className={styles.textFields}
              size="small"
              value={this.props.item.branch_id}
              options={this.props.branches}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteSearch}
              fullWidth="true"
              renderInput={(params) => <TextField {...params} label="Branch Name" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth="true" className={styles.textFields} size="small" variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Leave Type</InputLabel>
              <Select
                fullWidth
                label="Leave Type"
                variant="outlined"
                value={this.props.item.leave_type_id}
                onChange={this.props.handleChange}
                name="leave_type_id"
                inputProps={{
                  id: 'outlined-age-native-simple'
                }}
              >
                {
                  this.props.leaveTypes.length > 0 ? (
                    this.props.leaveTypes.map((type) => (
                      <MenuItem
                        key={type}
                        value={type.id}
                      >{type.name}
                      </MenuItem>
                    ))
                  ) : (null)
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name="employee_id"
              id="employee_id"
              className={styles.textFields}
              size="small"
              options={this.props.employees}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteSearch}
              fullWidth="true"
              value={this.props.item.employee_id}
              renderInput={(params) => <TextField {...params} label="Employee Name" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name="officer_id"
              id="officer_id"
              className={styles.textFields}
              size="small"
              options={this.props.officers}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteSearch}
              fullWidth="true"
              value={this.props.item.officer_id}
              renderInput={(params) => <TextField {...params} label="Officer Name" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name="department_id"
              id="department_id"
              className={styles.textFields}
              size="small"
              options={this.props.departments}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocompleteSearch}
              fullWidth="true"
              value={this.props.item.department_id}
              renderInput={(params) => <TextField {...params} label="Department" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              select
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="request_status"
              fullWidth={true}
              label="Request Status"
              onChange={this.props.handleChange}
              value={this.props.item.request_status || ''}
            >
              <MenuItem value=""> </MenuItem>
              <MenuItem value="approve">Approve</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </TextField>
          </Grid>

        </Grid>

				<div className={styles.actionButton}>
					<Button variant="outlined" id="product-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" id="product-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>

			</div>
		)
	}
}
export default PayeesSearch

PayeesSearch.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
  branches: PropTypes.array,
  leaveTypes: PropTypes.array,
  employees: PropTypes.array,
  officers: PropTypes.array,
  departments: PropTypes.array,
	search: PropTypes.func,
	clearSearch: PropTypes.func,
  handleAutocompleteSearch: PropTypes.func,
	handleKeyPress: PropTypes.func
}
