// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import PositionsForm from './PositionsForm'
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

class Positions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Position',
      positions: [],
      branches: [],
      departments: [],
      updateBranch: { id: '' },
      updateDepartment: {},
      openPopup: false,
      openDeletePopup: false,
      position: {},
      initialPositions: {
        id: '',
        branch_id: '',
        department_id: '',
        name: '',
        short_name: '',
        description: '',
        rank: '',
      },
      title: '',
      load: false,
      tableHead: ['branch.name', 'department.name', 'Name', 'short_name', 'description', 'Rank'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      selectedDepartments: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
  }

  componentDidMount() {
    this.loadPositions(process.env.REACT_APP_API_DOMAIN + '/v1/positions')
  }

  loadPositions(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ positions: resp.data })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ branches: resp.data })
        this.setState({ load: true })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.position
    item['branch_id'] = this.state.updateBranch.id
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/positions/'
      if (this.state.selectedDepartments.length > 0) {
        this.state.selectedDepartments.map(department => {
          item['branch_department_id'] = department.id
          this.submitPosition(item, url, method)
        },500)
      }
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/positions/' + item.id
      item['branch_department_id'] = this.state.updateDepartment.id
      this.submitPosition(item, url, method)
    }
  }

  submitPosition(item, url, method) {
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = resp.data
      this.setState({
        positions: this.state.positions.filter(position => position.id !== item.id)
      })
      this.setState({ positions: [...this.state.positions, newData] })
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.position.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/positions/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        positions: this.state.positions.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: '' })
    this.setState({ updateDepartment: [] })
    this.setState({ updateBranch: [] })
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.setState({ updateBranch: {} })
      this.setState({ updateDepartment: {} })
    } else {
      this.setState({ selectedDepartments: [] })
      textTitle = 'Edit ' + model
      this.setState({ updateBranch: item.branch })
      this.setState({ updateDepartment: item.department })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + item.branch.id + '/branch_departments',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        var depts = []
        resp.data.map((item) => {
          var newItem = item.department
          newItem['id'] = item.id
          depts.push(newItem)
        })
        this.setState({ departments: depts })

      }).catch(error => console.log(error.response))
    }
    this.setState({ openPopup: true })
    this.setState({ position: item })
    this.setState({ title: textTitle })
  }

  handleInputChange(e) {
    this.setState({
      position: {
        ...this.state.position,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({ model: model })
    this.setState({ position: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleAutocomplete(event, values) {
    if (event.target.id.includes("branch_id")) {

      this.setState({ updateBranch: values })
      this.setState({ updateDepartment: '' })
      this.setState({
        position: {
          ...this.state.position,
          branch_department_id: ''
        }
      })
      if (values !== null) {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + values.id + '/branch_departments',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {

          var depts = []
          resp.data.map((item) => {
            var newItem = item.department
            newItem['id'] = item.id
            depts.push(newItem)
          })

          this.setState({ departments: depts })

        }).catch(error => console.log(error.response))
      } else {
        this.setState({ departments: [] })
      }
    } else {
      if (this.state.position.id === '') {
        this.setState({ selectedDepartments: values })
      } else {
        this.setState({ updateDepartment: values })
      }

      // if(values!==null){
      //   this.setState({
      //     position: {
      //       ...this.state.position,
      //       branch_department_id: values.id
      //     }
      //   })
      // }else{
      //   this.setState({
      //     position: {
      //       ...this.state.position,
      //       branch_department_id: ''
      //     }
      //   })
      // }

    }
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialPositions}
                items={this.state.positions}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<RecentActorsOutlinedIcon fontSize="large" />}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <PositionsForm
                  error={this.state.error_messages}
                  item={this.state.position}
                  branches={this.state.branches}
                  departments={this.state.departments}
                  updateBranch={this.state.updateBranch}
                  updateDepartment={this.state.updateDepartment}
                  handleAutocomplete={this.handleAutocomplete}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}
                  selectedDepartments={this.state.selectedDepartments}
                />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.position}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default Positions
