// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import axios from 'axios'
import {Redirect} from 'react-router'
import { Link, withRouter} from 'react-router-dom'
import styles from './PayrollList.module.css'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {IconButton, Button, Tooltip} from '@material-ui/core'
import PropTypes from 'prop-types'
import JournalEntriesDetailTab from "./PayrollsTab/JournalEntriesDetailTab";
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import LocalPrintshopOutlinedIcon from '@material-ui/icons/LocalPrintshopOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PayrollEmployeeInfo from "./PayrollEmployeeInfo";
import moment from "moment";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import UndoIcon from '@material-ui/icons/Undo';
import ExposureOutlinedIcon from '@material-ui/icons/ExposureOutlined';
import ReactToPrint from "react-to-print";
export const Component = withRouter(() => {
})

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class PayrollListShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payroll List',
      payrollLists: [],
      journalEntries: [],
      payrollAccounts: [],
      payrollAdjustment: [],
      value: 0,
      updateAccount: {},
      openPopup: false,
      openDelete: false,
      openSend: false,
      openApprove: false,
      openNotify: false,
      openReDraft: false,
      openReCompute: false,
      cancelJournalOpenPopup: false,
      postJournalOpenPopup: false,
      redirect: false,
      reload: false,
      payrollList: {},
      initialPayrollAccounts: {
        id: '',
        date_from: '',
        date_to: '',
        bir: false,
        pagibig: false,
        philhealth: false,
        sss: false
      },
      title: '',
      load: false,
      showPopUp: false,
      openAdjustment: false,
      tableHead: ['employee.reference_no','employee.name_formal', 'payroll_income', 'gross_income', 'payroll_deductions', 'net_pay'],
      withShow: false,
      withEdit: true,
      withShowPopUp: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      forPrint: false,
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
    this.handleOpenSendPopup = this.handleOpenSendPopup.bind(this)
    this.submitSend = this.submitSend.bind(this)
    this.handleOpenApprovePopup = this.handleOpenApprovePopup.bind(this)
    this.submitNotify = this.submitNotify.bind(this)
    this.handleOpenNotify = this.handleOpenNotify.bind(this)
    this.submitReDraft = this.submitReDraft.bind(this)
    this.handleOpenReDraftPopup = this.handleOpenReDraftPopup.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.postJournalSubmit = this.postJournalSubmit.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.handleOpenRecomputePopup = this.handleOpenRecomputePopup.bind(this)
    this.loadPayrollLists = this.loadPayrollLists.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
  }

  componentDidMount() {
    this.loadPayrollLists(process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id + '/payroll_details')
  }

  loadPayrollLists(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      var newDataSort = resp.data.sort(function (a, b) {
        if (a.employee.name_formal.toLowerCase() < b.employee.name_formal.toLowerCase()) return -1;
        if (a.employee.name_formal.toLowerCase() > b.employee.name_formal.toLowerCase()) return 1;
        return 0;
      })
      this.setState({payrollLists: newDataSort, load: true })
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({payrollList: resp.data})
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id + '/journal_entries',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({journalEntries: resp.data})
      }).catch(error => console.log(error.response))
  }

  handleChange(event, newValue) {
    this.setState({value: newValue})
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.payrollList.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        payrollLists: this.state.payrollLists.filter(item => item.id !== DeleteItemId)
      })
      this.setState({redirect: true})
      this.setState({openDelete: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  submitSend() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + itemId + '/submit',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({openSend: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 500) {
        this.setState(
          {reload: true},
          () => this.setState({reload: false})
        )
      }
    })
  }

  submitApprove() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + itemId + '/approve',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({openApprove: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  submitNotify() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + itemId + '/notify',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({openNotify: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  submitReDraft() {
    const itemId = this.state.payrollList.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + itemId + '/redraft',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({openReDraft: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
      if (error.response.status === 500) {
        this.setState(
          {reload: true},
          () => this.setState({reload: false})
        )
      }
    })
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openAdjustment: false})
    this.setState({showPopUp: false})
    this.setState({openSend: false})
    this.setState({submitReDraft: false})
    this.setState({openNotify: false})
    this.setState({postJournalOpenPopup: false})
    this.setState({cancelJournalOpenPopup: false})
    this.setState({error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({openPopup: true})
    this.setState({payrollList: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      payrollList: {
        ...this.state.payrollList,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDelete() {
    this.setState({openDelete: true})
  }

  handleOpenSendPopup() {
    this.setState({openSend: true})
  }

  handleOpenApprovePopup() {
    this.setState({openApprove: true})
  }

  handleOpenReDraftPopup() {
    this.setState({openReDraft: true})
  }

  handleOpenRecomputePopup() {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id + '/recompute',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      setTimeout(() => {
        this.setState({recompute: resp.data})
      }, 3000)
      this.loadPayrollLists(process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id + '/payroll_details')
    }).catch(error => console.log(error.response))
    this.setState({openReCompute: true})
  }

  handleOpenNotify() {
    this.setState({openNotify: true})
  }

  handleDeleteClose() {
    this.setState({openDelete: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      payrollList: {
        ...this.state.payrollList,
        [event.target.name]: value
      }
    })
  }

  handleShowPopUp(item){
    this.setState({payrollEmployee: item})
    axios({
    method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payroll_details/' + item.id + '/payroll_adjustments/',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({payrollAdjustment: resp.data})
    }).catch(error => console.log(error.response))
    this.setState({title: item.employee.name_formal + ' / ' + item.employee.reference_no})
    this.setState({showPopUp: true})
  }

  buttonClick(renderAction){
    var redirectTo = ''
    var isRedirect = false
    if (renderAction === "back") {
      redirectTo = "/payrolls"
      isRedirect = true
    }  else {
      this.setState({ openDelete: true })
    }
    this.setState({ redirect: isRedirect })
    this.setState({ pathName: redirectTo })
  }

  cancelJournalSubmit() {
    const itemId = this.state.payrollList.journal.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + itemId + '/unpost',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then((resp) => {
        this.setState({payrollList: resp.data})
        this.setState({cancelJournalOpenPopup: false})
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })

      })
    })
  }

  postJournalSubmit() {
    const itemId = this.state.payrollList.journal.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + itemId + '/post',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({payrollList: this.state.payrollList})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/payrolls/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then((resp) => {
        this.setState({payrollList: resp.data})
        this.setState({
          isOpen: true,
          message: 'Submitted Successfully',
          type: 'success'
        })
        this.setState({postJournalOpenPopup: false})
      })
    })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint}, () => resolve());
    });
  } 

  activeJournal() {
    this.setState({postJournalOpenPopup: true})
  }

  cancelJournal() {
    this.setState({cancelJournalOpenPopup: true})
  }


  render() {
    const {redirect} = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/payrolls'
        }}/>)
    var getBasic = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.basic_income);
    }, 0)
    var overTime = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.over_time);
    }, 0)
    var otherIncome = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.other_income);
    }, 0)
    var gross = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.gross_income);
    }, 0)
    var hdmf = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_pagibig);
    }, 0)
    var philHealth = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_philhealth);
    }, 0)
    var sss = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_sss);
    }, 0)
    var bir = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.employee_bir);
    }, 0)
    var net = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.net_pay);
    }, 0)
    var otherDec = this.state.payrollLists.reduce((sum, currentValue) => {
      return sum + parseFloat(currentValue.other_deductions);
    }, 0)

    const printPdf = <ReactToPrint
      trigger={() => {
        return <IconButton color="primary" ><LocalPrintshopOutlinedIcon/></IconButton>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div ref={el => (this.componentRef = el)}  className={this.state.forPrint ? styles.tableBodyHeight : null}>
              <div className={styles.showTitleHeader}>
                <div><h2>Payroll Details</h2></div>
                <div className={this.state.forPrint ? styles.noDisplay : null}>
                  <div className={styles.groupButtonShow}>
                  <Tooltip title="Back" arrow>
                    <IconButton
                      color="primary"
                      onClick={() => this.buttonClick("back")}
                    >
                      <ArrowBackIosOutlinedIcon />
                    </IconButton>
                    </Tooltip>
                    {this.state.payrollList.status === "draft" ? (
                      <Tooltip title="Delete" arrow>
                      <IconButton
                        color="secondary"
                        onClick={() => this.buttonClick("delete")}
                      >
                        <DeleteIcon/>
                      </IconButton>
                      </Tooltip>
                    ):null}
                    {this.state.payrollList.status === 'draft' ? (
                      <Tooltip title="Cancel" arrow>
                      <IconButton
                        // onClick={this.handleOpenDeletePopup}
                      >
                        <CancelOutlinedIcon/>
                      </IconButton>
                      </Tooltip>
                    ) :null}
                    {this.state.payrollList.status === 'draft' ? (
                      <Tooltip title="Submit" arrow>
                      <IconButton
                        onClick={this.handleOpenSendPopup}
                      >
                        <SendOutlinedIcon/>
                      </IconButton>
                      </Tooltip>
                    ) :null}
                    {this.state.payrollList.status === 'for_approval' ? (
                       <Tooltip title="Approve" arrow>
                      <IconButton
                        onClick={this.handleOpenApprovePopup}
                      >
                        <CheckCircleOutlineIcon/>
                      </IconButton>
                      </Tooltip>
                    ) :null}
                    {this.state.payrollList.status === 'for_approval' ? (
                       <Tooltip title="Redraft" arrow>
                      <IconButton
                        onClick={this.handleOpenReDraftPopup}
                      >
                        <UndoIcon/>
                      </IconButton>
                      </Tooltip>
                    ) :null}
                    {this.state.payrollList.status === 'approved' ? (
                      <Tooltip title="Notify" arrow>
                      <IconButton
                        onClick={this.handleOpenNotify}
                      >
                        <EmailOutlinedIcon/>
                      </IconButton>
                      </Tooltip>
                    ) :null}
                    <Tooltip title="Recompute Payroll Detail" arrow>
                    <IconButton
                      onClick={this.handleOpenRecomputePopup}
                    >
                      <ExposureOutlinedIcon/>
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Print Payroll Detail" arrow>
                    <IconButton
                    >
                      {printPdf}
                      {/* <a href={`/payrolls/${this.state.payrollList.id}/details.pdf`} target="_blank" rel="noopener noreferrer"> */}
                      {/* <LocalPrintshopOutlinedIcon/> */}

                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Download Excel File" arrow>
                    <IconButton
                      // onClick={this.handleOpenDeletePopup}
                    >
                      <CloudDownloadOutlinedIcon/>
                    </IconButton>
                    </Tooltip>
                    {this.state.payrollList.status === 'draft' ? (
                      <Tooltip title="Payroll Adjustment" arrow><IconButton><Link
                        to={{
                          pathname: `/payrolls/${this.state.payrollList.id}/payroll_adjustments/new`,
                        }}
                      >
                        <EqualizerIcon/>
                      </Link>
                      </IconButton></Tooltip>
                    ) :null}
                  </div>
                </div>
              </div>
              <hr/>
              <div className={this.state.forPrint ? styles.noDisplay : styles.detailsBody}>
                <div className={styles.leftShowDetails}>

                  <div className={styles.detailList}>
                    <div><b> Payroll Period</b></div>
                    <div>:&emsp;  {moment(this.state.payrollList.date_from).format('MMM DD')}-{moment(this.state.payrollList.date_to).format('DD YYYY')}
                    </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Status</b></div>
                    <div>:&emsp; {this.state.payrollList.status === 'for_approval' ? 'For Approval' : this.state.payrollList.status} </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Posted </b></div>
                    {this.state.payrollList.status === 'approved' || this.state.payrollList.status === 'notified' ? (
                      <div>:&emsp; {this.state.payrollList.journal.is_posted == true ? 'Posted' :  'Not Posted' } </div>
                    ):null}
                  </div>
                </div>
              </div>
              <div  className={this.state.forPrint ? styles.noDisplay : null}>
              <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="secondary"
                    className={styles.tabBody} aria-label="simple tabs example">
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<PersonOutlineIcon /> */}
                  <span className={styles.tabLabel}>DETAILS</span></div>}{...a11yProps(0)} />
                {this.state.payrollList.sss === true? (
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<MenuBookIcon />*/}
                  <span className={styles.tabLabel}>SSS</span></div>}{...a11yProps(1)} />
                ) : null}
                {this.state.payrollList.pagibig === true? (
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>HDMF</span></div>} {...a11yProps(2)} />
                ) : null}
                {this.state.payrollList.philhealth === true? (
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>PHILHEALTH</span></div>} {...a11yProps(3)} />
                ) : null}
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>BIR</span></div>} {...a11yProps(this.state.payrollList.sss !== true ? 1: 4)} />
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>BANK</span></div>} {...a11yProps(this.state.payrollList.pagibig !== true? 2 : 5)} />
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  {/*<CalendarTodayIcon />*/}
                  <span className={styles.tabLabel}>JOURNAL</span></div>} {...a11yProps(this.state.payrollList.philhealth !== true? 3:6)} />
              </Tabs>
              <hr/>
            </div>
              <TabPanel value={this.state.value} index={0} className={styles.TabBody}>
                <SimpleTable
                  // classNameHeader={true}
                  handleClick={this.handleCreateorUpdateItem}
                  initialItem={this.state.initialPayrollAccounts}
                  items={this.state.payrollLists}
                  model="Payroll Detail"
                  withShowPopUp={this.state.withShowPopUp}
                  headers={this.state.tableHead}
                  removeLabel={true}
                  nodelete={true}
                  ifTotal={true}
                  handleShowPopUp={this.handleShowPopUp}
                  getBasic={getBasic.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  overTime={overTime.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  gross={gross.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  hdmf={hdmf.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  philHealth={philHealth.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  sss={sss.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  bir={bir.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  otherIncome={otherIncome.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  otherDec={otherDec.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                  net={net.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                />
              </TabPanel>
              {this.state.payrollList.philhealth === true? (
                <TabPanel value={this.state.value} index={1} className={styles.TabBody}>
                  <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>SSS</h3>
                  <SimpleTable
                    handleClick={this.handleCreateorUpdateItem}
                    initialItem={this.state.initialPayrollAccounts}
                    items={this.state.payrollLists}
                    model={this.state.model}
                    headers={['employee.name', 'employee_sss_no', 'employee_sss', 'employer_sss', 'employer_ecc', 'total_sss']}
                    removeLabel={true}
                  />
                </TabPanel>
              ):null}
              {this.state.payrollList.pagibig === true? (
                <TabPanel value={this.state.value} index={2} className={styles.TabBody}>
                  <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>HDMF</h3>
                  <SimpleTable
                    initialItem={this.state.initialPayrollAccounts}
                    items={this.state.payrollLists}
                    model={this.state.model}
                    headers={['employee.name', 'employee_pagibig_no','employee_pagibig', 'employer_pagibig', 'total_pagibig']}
                    removeLabel={true}
                  />
                </TabPanel>
              ):null}
              {this.state.payrollList.philhealth === true? (
              <TabPanel value={this.state.value} index={3} className={styles.TabBody}>
                <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>PHILHEALTH</h3>
                <SimpleTable
                  initialItem={this.state.initialPayrollAccounts}
                  items={this.state.payrollLists}
                  model={this.state.model}
                  headers={['employee.name', 'employee_philhealth_no', 'employee_philhealth', 'employer_philhealth', 'total_philhealth']}
                  removeLabel={true}
                />
              </TabPanel>
              ):null}
              <TabPanel value={this.state.value} index={this.state.payrollList.sss !== true ? 1: 4} className={styles.TabBody}>
                <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>BIR</h3>
                <SimpleTable
                  initialItem={this.state.initialPayrollAccounts}
                  items={this.state.payrollLists}
                  model={this.state.model}
                  headers={['employee.name','employee_bir']}
                  removeLabel={true}
                  forPrint={this.state.forPrint}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={this.state.payrollList.pagibig !== true? 2 : 5} className={styles.TabBody}>
                <h3 className={this.state.forPrint ? styles.showDisplayLabel : styles.noDisplay}>Bank</h3>
                <SimpleTable
                  initialItem={this.state.initialPayrollAccounts}
                  items={this.state.payrollLists}
                  model={this.state.model}
                  headers={['employee.name','employee.bank_account_no', 'net_pay']}
                  removeLabel={true}
                  forPrint={this.state.forPrint}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={this.state.payrollList.philhealth !== true? 3:6} className={styles.journalTab}>
              <JournalEntriesDetailTab
                journalEntries={this.state.journalEntries}
                payrollList={this.state.payrollList}
                activeJournal={this.activeJournal}
                cancelJournal={this.cancelJournal}
                forPrint={this.state.forPrint}
                isPayrollDetails={true}
                />
              </TabPanel>
              <SimplePopUp
                openPopup={this.state.showPopUp}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
              <PayrollEmployeeInfo
                payrollList={this.state.payrollList}
                item={this.state.payrollEmployee}
                payrollAdjustment={this.state.payrollAdjustment}
              />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openSend}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to submit this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitSend()}>Submit</Button>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openApprove}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to approve this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitApprove()}>Save</Button>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openNotify}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to Notify Email this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitNotify()}>Submit</Button>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openReDraft}
                title="Payroll"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div> <h3>Would you like to redraft this Payroll?</h3></div>
                <div className={styles.actionButton}>
                  <Button variant="outlined" id="payrollAccount-submit" onClick={this.handleClose}>Cancel</Button>
                  <Button variant="outlined" id="payrollAccount-submit" color="primary" onClick={() => this.submitReDraft()}>Submit</Button>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.postJournalOpenPopup}
                title="Journal"
                items={this.state.payrollList}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <span>
                    <b>Would you like to post this Payroll?</b>
                  </span>
                  <div
                    align="right"
                    className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.postJournalSubmit}
                    >Submit</Button></div>
                </div>
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.cancelJournalOpenPopup}
                title="Journal"
                items={this.state.payrollList}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <span>
                    <b>Would you like to unpost this Payroll?</b>
                  </span>
                  <div
                    align="right"
                    className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.cancelJournalSubmit}
                    >Submit</Button></div>
                </div>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDelete}
                item={this.state.payrollList}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleDeleteClose}
                model="Payroll"
              />

            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(PayrollListShow)

PayrollListShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  history: PropTypes.string
}
