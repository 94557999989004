import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import EmployeeForm from './EmployeeForm'
import { Redirect } from 'react-router'
import AlertMessage from '../../shared/Notify/AlertMessage'

class EmployeeNewAndUpdate extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			load: false,
			employee: {
				id: ''
			},
			position: { id: '' },
			division: { id: '' },
			department: { id: '' },
			branch: { id: '' },
			positions: [],
			divisions: [],
			departments: [],
			branches: [],
			redirect: false,
			profile_attributes: { is_tax_exempted: true },
			employment_statuses_attributes: {},
			salaries_attributes: {},
			kinds: [],
			states: [], errorMessages: {}
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleAutocomplete = this.handleAutocomplete.bind(this)
		this.handleProfileChange = this.handleProfileChange.bind(this)
		this.handleSalaryChange = this.handleSalaryChange.bind(this)
		this.handleEmploymentChange = this.handleEmploymentChange.bind(this)
		this.handleRateChange = this.handleRateChange.bind(this)
		this.getReferenceNo = this.getReferenceNo.bind(this)
	}
	componentDidMount() {
		if (this.props.match.params.id !== undefined ) {
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + this.props.match.params.id,
				headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
			}).then(resp => {
				this.setState({ employee: resp.data })
				this.setState({ branch: resp.data.branch })
				this.setState({ position: resp.data.position })
				this.setState({ division: resp.data.division })
				this.setState({ department: resp.data.department })
				this.setState({ profile_attributes: resp.data.profile_attributes })
				this.setState({ employment_status: resp.data.employment_status })
				this.setState({ salary: resp.data.salary })
				const branchId = resp.data.branch.id
				const departmentId = resp.data.department.id
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + branchId + '/branch_departments',
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {
					const depts = []
					var item = {}
					resp.data.map((dept) => {
						item = dept.department,
							item["id"] = dept.id
						depts.push(item)
					})
					this.setState({ departments: depts })
					axios({
						method: 'get',
						url: process.env.REACT_APP_API_DOMAIN + '/v1/branch_departments/' + departmentId + '/positions',
						headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
					}).then(resp => {
						this.setState({ positions: resp.data })
						axios({
							method: 'get',
							url: process.env.REACT_APP_API_DOMAIN + '/v1/branch_departments/' + departmentId + '/divisions',
							headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
						}).then(resp => {
							this.setState({ divisions: resp.data })
						}).catch(error => this.catchError(error.response))
					}).catch(error => this.catchError(error.response))
				}).catch(error => this.catchError(error.response))
			}).catch(error => this.catchError(error.response))
		}
		this.loadEmployeeForm()
	}

	catchError(error) {
		this.setState({ isOpen: true, message: (error.status ? error.status : '') + "Internal Server Error", type: 'error' })
	}

	loadEmployeeForm() {
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ branches: resp.data })

			if (this.props.match.params.id === undefined || this.props.match.params.id === '') {
				this.getReferenceNo()
				if (resp.data.length > 0) {
					if (resp.data.length === 1) {
						this.setState({ branch: resp.data[0] })
						axios({
							method: 'get',
							url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + resp.data[0].id + '/branch_departments',
							headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
						}).then(response => {
							const depts = []
							var item = {}
							response.data.map((dept) => {
								item = dept.department,
									item["id"] = dept.id
								depts.push(item)
							})
							this.setState({ departments: depts })
						})
					}
				}
			}
		}).catch(() => {
			this.setState({ isOpen: true, message: " Unexpected Error Problem Occurred", type: 'error' })
		})
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/employment_statuses/kinds',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ kinds: resp.data })
		}).catch(() => {
			this.setState({ isOpen: true, message: " Unexpected Error Problem Occurred", type: 'error' })
		})
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/employment_statuses/states',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ states: resp.data })
		}).catch(() => {
			this.setState({ isOpen: true, message: " Unexpected Error Problem Occurred", type: 'error' })
		})
		this.setState({ load: true })
	}

	handleInputChange(e) {
		this.setState({
			employee: {
				...this.state.employee,
				[e.target.name]: e.target.value
			}
		})
	}

	handleEmploymentChange(e) {
		this.setState({
			employment_statuses_attributes: {
				...this.state.employment_statuses_attributes,
				[e.target.name]: e.target.value
			}
		})
	}

	handleSalaryChange(e) {
		this.setState({
			salaries_attributes: {
				...this.state.salaries_attributes,
				[e.target.name]: e.target.value
			}
		})
	}
	handleProfileChange(e) {
		this.setState({
			profile_attributes: {
				...this.state.profile_attributes,
				[e.target.name]: e.target.value
			}
		})
	}


	handleAutocomplete(event, values) {
		if (event.target.id.includes("branch")) {
			var item = {}
			if (values !== null) {
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + values.id + '/branch_departments',
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {
					const depts = []
					resp.data.map((dept) => {
						item = dept.department,
							item["id"] = dept.id
						depts.push(item)
					})
					this.setState({ departments: depts })
				}).catch(error => console.log(error.response))
			} else {
				this.setState({ departments: [] })
			}
			this.setState({ branch: values })
		} else if (event.target.id.includes("department")) {
			if (values !== null) {
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/branch_departments/' + values.id + '/positions',
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {

					this.setState({ positions: resp.data })
					axios({
						method: 'get',
						url: process.env.REACT_APP_API_DOMAIN + '/v1/branch_departments/' + values.id + '/divisions',
						headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
					}).then(resp => {

						this.setState({ divisions: resp.data })
					}).catch(error => console.log(error.response))
				}).catch(error => console.log(error.response))
			} else {
				this.setState({ positions: [] })
				this.setState({ divisions: [] })
			}
			this.setState({ department: values })
		} else if (event.target.id.includes("position")) {
			this.setState({ position: values })
		}
		else if (event.target.id.includes("division")) {
			this.setState({ division: values })
		} else if (event.target.id.includes("taxStatus")) {
			this.setState({ taxStatus: values })
		}
	}


	handleSubmit() {
		const item = this.state.employee
		item["branch"] = { id: this.state.branch ? (this.state.branch.id ? (this.state.branch.id) : "") : "" }
		item["department"] = { id: this.state.department ? (this.state.department.id ? (this.state.department.id) : "") : "" }
		item["division_id"] = this.state.division ? (this.state.division.id ? (this.state.division.id) : "") : ""
		item["position_id"] = this.state.position ? (this.state.position.id ? (this.state.position.id) : "") : ""

		item["profile_attributes"] = this.state.profile_attributes

		var method = ''
		var url = ''
		if (item.id === undefined || item.id === '') {
			method = 'post'
			url = process.env.REACT_APP_API_DOMAIN + '/v1/employees'
			item["salaries_attributes"] = [this.state.salaries_attributes]
			item["employment_statuses_attributes"] = [this.state.employment_statuses_attributes]
		} else {
			method = 'put'
			url = process.env.REACT_APP_API_DOMAIN + '/v1/employees/' + item.id
		}

		axios({
			method: method,
			url: url,
			data: (item),
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then((resp) => {
			this.setState({ employee: resp.data })
			this.setState({ redirect: true })
			this.setState({ openPopup: false })
			this.setState({
				isOpen: true,
				message: 'Submitted Successfully',
				type: 'success'
			})
		}).catch(error => {
			if (error.response.status === 422) {
				var errors = {}
				Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
					errors[field] = error.response.data.errors[0].detail.errors[field][0]
					if (field.includes('.')) {
						errors[field.split(".")[1]] = error.response.data.errors[0].detail.errors[field][0]
					} else {
						errors[field] = error.response.data.errors[0].detail.errors[field][0]
					}
				})
				this.setState({ errorMessages: errors })
			} else {
				this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
			}
		})
	}

	handleRateChange(e) {
		this.setState({
			salaries_attributes: {
				...this.state.salaries_attributes,
				rate: e.floatValue
			}
		})
	}

	getReferenceNo() {
			axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/employees/generate_ref_no', {
				headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
			}).then(resp => {
				if (!resp.data.error) {
					this.setState({
						profile_attributes: {
							...this.state.profile_attributes,
							reference_no: this.padFix(resp.data.reference_no)[0]
						}
					})
				}
				this.setState({ errorMessages: { ...this.state.errorMessages, reference_no: null } })
			})
	}

	padFix(n) {
		return ('00000000' + n.toString()).match(/\S{8}$/);
	}

	render() {
		const { redirect, employee } = this.state

		if (redirect)
			return (<Redirect to={{
				pathname: '/employees/' + employee.id
			}} />)
		return (
			<>
				{this.state.load ? (
					<EmployeeForm
						item={this.state.employee}
						profile_attributes={this.state.profile_attributes}
						employment_statuses_attributes={this.state.employment_statuses_attributes}
						salaries_attributes={this.state.salaries_attributes}
						handleProfileChange={this.handleProfileChange}
						handleEmploymentChange={this.handleEmploymentChange}
						handleSalaryChange={this.handleSalaryChange}
						handleRateChange={this.handleRateChange}
						handleChange={this.handleInputChange}
						submit={this.handleSubmit}
						branches={this.state.branches}
						positions={this.state.positions}
						divisions={this.state.divisions}
						departments={this.state.departments}
						handleAutoComplete={this.handleAutocomplete}
						branch={this.state.branch}
						position={this.state.position}
						division={this.state.division}
						department={this.state.department}
						salary={this.state.salary}
						employment_status={this.state.employment_status}
						kinds={this.state.kinds}
						states={this.state.states}
						error={this.state.errorMessages}
						getReferenceNo={this.getReferenceNo}
					/>
				) : null}
				<AlertMessage
					notify={this.state.notify}
					handleCloseAlert={this.handleCloseAlert}
					isOpen={this.state.isOpen}
					type={this.state.type}
					message={this.state.message}
				/>
			</>
		)
	}
}

export default EmployeeNewAndUpdate

EmployeeNewAndUpdate.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}),
	location: PropTypes.object
}
