import React from 'react'
import styles from './Recurrences.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button, Grid, MenuItem, Checkbox
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import NumberFormat from 'react-number-format'

class RecurrenceForm extends React.Component {
    render() {
        return (
            <div>
                
                    {
                        this.props.withoutEmployeeField ? null : (
                            <div className={styles.autoCompleteField}>
                                <Autocomplete
                                    value={this.props.item.id ? (this.props.selectedEmployee) : (this.props.selectedEmployees)}
                                    id="employees"
                                    multiple={!this.props.item.id}
                                    name="employees"
                                    fullWidth
                                    size="small"
                                    options={(this.props.employees).sort(function (a, b) {
                                        if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
                                        if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
                                        return 0;
                                    })}
                                    getOptionLabel={(option) => option.name_formal || ''}
                                    getOptionSelected={(option, value) => option.name_formal === value.name_formal}
                                    onChange={this.props.handleAutocomplete}
                                    renderInput={(params) => (
                                        <TextField {...params} name="employees" variant="outlined" label="Employees" />
                                    )}
                                />
                                <p className={styles.error}>{ this.props.error.employee? 'Employee' + this.props.error.employee : ''   }</p>
                            </div>
                        )
                    }
                    <br />
                    <Grid className={styles.grid} container spacing={1}>
                        <Grid item xs={6}>
                            <Autocomplete
                                value={this.props.selectedAccount}
                                id="account"
                                disableClearable={!this.props.selectedAccount}
                                name="account"
                                fullWidth
                                className={styles.textFields}
                                size="small"
                                options={(this.props.accounts).sort(function (a, b) {
                                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                    return 0;
                                })}
                                getOptionLabel={(option) => option.name || ''}
                                getOptionSelected={(option, value) => option.name === value.name}
                                onChange={this.props.handleAccount}
                                renderInput={(params) => (
                                    <TextField {...params} name="account" variant="outlined" label="Account" />
                                )}
                            />
                            <p className={styles.error}>{ this.props.error.account ? 'Account'+ this.props.error.account : ''   }</p>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="off"
                                select
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="period"
                                fullWidth={true}
                                label="Period"
                                onChange={this.props.handleChange}
                                value={this.props.item.period}
                                id="period"
                            >
                                <MenuItem value=""><div>&nbsp;</div></MenuItem>
                                <MenuItem value="Daily">Daily</MenuItem>
                                <MenuItem value="Weekly">Weekly</MenuItem>
                                <MenuItem value="Semi-monthly">Semi-monthly</MenuItem>
                                <MenuItem value="Monthly First Half">Monthly First Half</MenuItem>
                                <MenuItem value="Monthly Second Half">Monthly Second Half</MenuItem>
                            </TextField>
                            <p className={styles.error}>{ this.props.error.period ? 'Period'+ this.props.error.period : ''   }</p>
                        </Grid>
                        <Grid item xs={5}>
                            <div className={styles.amountBody}>
                                <NumberFormat
                                    customInput={TextField}
                                    autoComplete="off"
                                    variant="outlined"
                                    className={styles.amountBody}
                                    size="small"
                                    name="principal_amount"
                                    fullWidth
                                    label="Principal Amount"
                                    thousandSeparator={true}
                                    onValueChange={this.props.handleNumberChange}
                                    value={parseFloat(this.props.item.principal_amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                                    decimalSeparator="."
                                    fixedDecimalScale={true}
                                    prefix={'₱'}
                                />
                            </div>
                            <p className={styles.error}>{ this.props.error.principal_amount ? 'Amount'+ this.props.error.principal_amount : ''   }</p>
                        </Grid>
                        <Grid item xs={5}>
                            <div className={styles.amountBody}>
                                <NumberFormat
                                    customInput={TextField}
                                    autoComplete="off"
                                    variant="outlined"
                                    className={styles.amountBody}
                                    size="small"
                                    name="payable_amount"
                                    fullWidth
                                    label="Payable Amount"
                                    thousandSeparator={true}
                                    onValueChange={this.props.handleAmountChange}
                                    value={parseFloat(this.props.item.payable_amount).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                                    decimalSeparator="."
                                    fixedDecimalScale={true}
                                    prefix={'₱'}
                                />
                            </div>
                            <p className={styles.error}>{ this.props.error.payable_amount ? 'Payable'+ this.props.error.payable_amount : ''   }</p>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={styles.amountBody}>
                                <Checkbox
                                    onChange={this.props.handleCheck}
                                    checked={this.props.item.active}
                                    name="active"
                                />Active
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                type="date"
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="loan_date"
                                fullWidth={true}
                                label="Loan Date"
                                onChange={this.props.handleChange}
                                value={this.props.item.loan_date}
                                id="loan_date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                type="date"
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="date_of_effect"
                                fullWidth={true}
                                label="Date of Effect"
                                onChange={this.props.handleChange}
                                value={this.props.item.date_of_effect}
                                id="date_of_effect"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                type="date"
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="maturity_date"
                                fullWidth={true}
                                label="Maturity Date"
                                onChange={this.props.handleChange}
                                value={this.props.item.maturity_date}
                                id="maturity_date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                    </Grid>

                

                <div className={styles.actionButton}>
                    <Button variant="outlined"
                        disabled={!this.props.item.date_of_effect ||
                            !this.props.selectedAccount

                        }
                        id="recurrence-submit"
                        color="primary" onClick={() => this.props.submit()} >Save</Button>
                </div>
            </div>
        )
    }
}
export default RecurrenceForm

RecurrenceForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    children: PropTypes.object,
    submit: PropTypes.func,
    selectedEmployees: PropTypes.array,
    selectedEmployee: PropTypes.object,
    employees: PropTypes.array,
    accounts: PropTypes.array,
    handleAutocomplete: PropTypes.func,
    selectedAccount: PropTypes.object,
    handleNumberChange: PropTypes.func,
    handleAmountChange: PropTypes.func,
    handleCheck: PropTypes.func,
    handleAccount: PropTypes.func,
    withoutEmployeeField: PropTypes.bool,
    error: PropTypes.object,
}
