// eslint-disable-next-line
import style from '../../../CssGlobalVariables/globalCss.css'

import React from 'react'

import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import LeaveApplicationForm from './LeaveApplicationForm'
import axios from 'axios'
import ProgressBar from '../../../shared/ProgressBar/ProgressBar'
import { Redirect } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import SimpleTable from '../../../shared/SimpleTable/SimpleTable'
import ApprovalForm from './ApprovalForm'
import styles from './LeaveApplications.module.css'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import IconButton from '@material-ui/core/IconButton'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import AlertMessage from '../../../shared/Notify/AlertMessage'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab_${index}`,
        'aria-controls': `tabpanel_${index}`,
        name:`tab_${index}`,
    };
}


class LeaveApplications extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Leave Application',
            leaveApplications: [],
            openPopup: false,
            openDeletePopup: false,
            leaveApplication: {},
            employees: [],
            selectedEmployees: [],
            initialLeaveApplication: {
                id: '',
                with_sat: false,
                starts_on: '',
                ends_on: '',

            },
            title: '',
            load: false,
            tableHead: ['title', 'officer_name', "employee_names"],
            withShow: false,
            withEdit: true,
            maxWidth: 'sm',
            error_messages: '',
            notify: {},
            isOpen: false,
            message: '',
            type: '',
            events: [],
            redirect: false,
            leaveCredits: [],
            approvingOfficers: [],
            maxDate: '',
            appliedDays: 0,
            currentEmployee: {},
            attachments: [],
            attachment: {},
            value: 0,
            openApprovalFormPopup: false,
            leaveApplicationsForApproval: [],
            leaveForApproval: {},
            showRejectedLeaves: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
        this.handleDeleteClose = this.handleDeleteClose.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleSwitch = this.handleSwitch.bind(this)
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleEndDateChange = this.handleEndDateChange.bind(this)
        this.getMaxDate = this.getMaxDate.bind(this)
        this.eventClick = this.eventClick.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.cancel = this.cancel.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleApprovalChange = this.handleApprovalChange.bind(this)
        this.handleShowPopUp = this.handleShowPopUp.bind(this)
        this.approve = this.approve.bind(this)
        this.reject = this.reject.bind(this)
        this.forward = this.forward.bind(this)
        this.toggleView = this.toggleView.bind(this)
        this.handleDeleteLeaveApplication = this.handleDeleteLeaveApplication.bind(this)
    }

    componentDidMount() {
        this.loadLeaveApplications(process.env.REACT_APP_API_DOMAIN + '/v1/employees/leave_applications')
    }

    loadLeaveApplications(url) {
        axios({
            method: 'get',
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({
                leaveApplications: resp.data.sort(
                    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                ).reverse()
            })
            const newData = resp.data.filter(item => item.request_status !== "cancelled").map((item) => ({
                color: item.request_status === "pending" ? ("#9e9e9e") : (item.request_status === "rejected" ? ("#d50000") : ("#1b5e20")),
                id: item.id,
                title: item.leave_type.name,
                start: item.starts_on,
                end: item.ends_on
            }))
            this.setState({ events: newData })
            
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/leave_credits',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ leaveCredits: resp.data })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/approving_officers',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ approvingOfficers: resp.data })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/profiles',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ currentEmployee: resp.data })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/leave_applications/received',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({
                leaveApplicationsForApproval: resp.data
                    .sort(
                        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                    ).reverse()
            })
            const newData = resp.data.map((item) => ({
                color: item.request_status === "pending" ? ("#9e9e9e") : (item.request_status === "rejected" ? ("#d50000") : ("#1b5e20")),
                id: item.id,
                title: item.leave_type.name + ' - ' + item.employee_name,
                start: item.starts_on,
                end: item.ends_on
            }))
            this.setState({ events: [...this.state.events, ...newData] })
            
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        this.setState({ load: true })
    }

    handleSubmit() {
        const item = this.state.leaveApplication
        var method = ''
        var url = ''
        item["creditable_type"] = "Caes::LeaveCredit"
        item["employee_id"] = this.state.currentEmployee.id
        item["without_pay"] = false
        item["no_of_days"] = this.state.appliedDays
        item["to_afternoon"] = JSON.parse(item.to_afternoon)
        item["from_morning"] = JSON.parse(item.from_morning)
        item["starts_on"] = new Date(item.starts_on).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
        item["ends_on"] = new Date(item.ends_on).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
        if (item.id === '') {
            method = 'post'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications'
        } else {
            method = 'put'
            url = process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + item.id
        }
        axios({
            method: method,
            url: url,
            data: (item),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            const newData = resp.data
            this.setState({
                leaveApplications: this.state.leaveApplications.filter(leaveApplication => leaveApplication.id !== item.id)
            })
            this.setState({ leaveApplications: [...this.state.leaveApplications, newData] })
            const newEvent = {
                color: "#ff9800",
                id: resp.data.id,
                title: resp.data.reason,
                start: resp.data.starts_on,
                end: resp.data.ends_on
            }
            this.setState({
                events: this.state.events.filter(leaveApplication => leaveApplication.id !== newEvent.id)
            })
            this.setState({ events: [...this.state.events, newEvent] })
            if (this.state.attachment !== null && this.state.attachment.file !== undefined) {
                const attachment = this.state.attachment
                const file = attachment.file
                const formdata = new FormData()
                formdata.append('file', file)
                formdata.append('description', this.state.leaveApplication.reason)
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + resp.data.id + '/attachments',
                    data: (formdata),
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    const newData = resp.data
                    this.setState({
                        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
                    })
                    this.setState({ attachments: [...this.state.attachments, newData] })

                }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
            }



            this.setState({ openPopup: false })
            this.setState({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
            } else {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Problem Occurred",
                    type: 'error'
                })
                this.setState({ openPopup: false })
            }
        })
    }

    handleDeleteItem(id) {
        const DeleteItemId = this.state.leaveApplication.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + DeleteItemId + '/attachments/' + id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                attachments: this.state.attachments.filter(item => item.id !== id), openDeletePopup: false
            })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    }

    handleDeleteLeaveApplication() {
        const DeleteItemId = this.state.leaveApplication.id
        axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + DeleteItemId,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                leaveApplications: this.state.leaveApplications.filter(item => item.id !== DeleteItemId)
                , openDeletePopup: false
            })
            this.setState({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    }

    handleClose() {
        this.setState({ openPopup: false })
        this.setState({ openDeletePopup: false })
        this.setState({ error_messages: '' })
    }

    handleCreateorUpdateItem(item, isAdd) {
        var textTitle = ''
        if (isAdd) {
            textTitle = 'Apply Leave'
        } else {
            textTitle = 'Edit Leave Application'
            this.setState({ appliedDays: item.no_of_days })
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + item.id + '/attachments',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ attachments: resp.data })
            })
            item['from_morning'] = item.from_morning ? "true" : "false"
            item['to_afternoon'] = item.to_afternoon ? "true" : "false"
        }
        this.setState({ openPopup: true })
        this.setState({ leaveApplication: item })
        this.setState({ title: textTitle })
    }

    handleInputChange(e) {
        if(e.target.name === 'creditable_id')
        {this.setState({
            leaveApplication: {
                id: '',
                with_sat: false,
                starts_on: null,
                ends_on: null,
                [e.target.name]: e.target.value

            }, 
            appliedDays: 0
        })}else{this.setState({
            leaveApplication: {
                ...this.state.leaveApplication,
                [e.target.name]: e.target.value
            }
        })}
        
        if (e.target.name === "to_afternoon" || e.target.name === "from_morning") {
            if (e.target.value === "true") {
                this.setState({ appliedDays: this.state.appliedDays + 0.5 })
            } else {
                this.setState({ appliedDays: this.state.appliedDays - 0.5 })
            }
        }
    }

    handleOpenDeletePopup(item, model) {
        this.setState({ model: model })
        this.setState({ leaveApplication: item })
        this.setState({ openDeletePopup: true })
    }

    handleDeleteClose() {
        this.setState({ openDeletePopup: false })
    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ isOpen: false })
    }
    handleAutocomplete(event, values) {
        this.setState({ selectedEmployees: values })
    }
    handleSwitch(e) {

        this.setState({
            leaveApplication: {
                ...this.state.leaveApplication,
                with_sat: e.target.checked,
                starts_on: null,
                ends_on: null
            }
        })
        this.setState({ appliedDays: 0 })
        if (this.state.leaveApplication.starts_on !== '') {
            this.getMaxDate(this.state.leaveApplication.starts_on, e.target.checked)

        }
    }
    handleStartDateChange(e, target) {
        this.setState({
            leaveApplication: {
                ...this.state.leaveApplication,
                starts_on: target,
                ends_on: target,
                from_morning: "true",
                to_afternoon: "true"
            }
        })
        this.getMaxDate(target, this.state.leaveApplication.with_sat)
        this.setState({ appliedDays: 1 })
    }

    handleEndDateChange(e, target) {

        this.setState({
            leaveApplication: {
                ...this.state.leaveApplication,
                ends_on: target,
                to_afternoon: "true"
            }
        })
        if (this.state.leaveApplication.starts_on !== '') {
            this.computeAppliedDays(this.state.leaveApplication.starts_on, target, this.state.leaveApplication.with_sat)
        }
    }

    computeAppliedDays(startDate, endDate, withSat) {
        var remainingDays = 0
        if (this.state.leaveCredits.length > 0) {
            this.state.leaveCredits.map((credit) => {
                if (this.state.leaveApplication.creditable_id === credit.id) {
                    remainingDays = credit.days_remaining
                }
            })
        }

        var count = 0;
        var curDate = new Date(startDate);
        var date = ''
        if (withSat) {
            while (curDate <= new Date(endDate)) {
                date = new Date(curDate)
                if (date.getDay() !== 0) {
                    count++;
                }
                curDate = date.setDate(date.getDate() + 1)
            }
        } else {
            while (curDate <= new Date(endDate)) {
                date = new Date(curDate)
                if (date.getDay() !== 0 && date.getDay() !== 6) {
                    count++;
                }
                curDate = date.setDate(date.getDate() + 1)
            }
        }
        if (count === 0) {
            this.setState({ appliedDays: 1 })
        }
        if (count > remainingDays) {
            this.setState({
                leaveApplication: {
                    ...this.state.leaveApplication,
                    starts_on: '',
                    ends_on: ''
                }
            })
            this.setState({ appliedDays: 0 })
        } else {
            this.setState({ appliedDays: count })
        }

    }

    getMaxDate(leaveStartDate, withSat) {
        var remainingDays = 0
        if (this.state.leaveCredits.length > 0) {
            this.state.leaveCredits.map((credit) => {
                if (this.state.leaveApplication.creditable_id === credit.id) {
                    remainingDays = credit.days_remaining
                }
            })
        }
        var endDate = "", noOfDaysToAdd = remainingDays -1, count = 0;
        var startDate = leaveStartDate
        startDate = new Date(startDate.replace(/-/g, "/"));

        if (withSat === true) {
            while (count < noOfDaysToAdd) {
                endDate = new Date(startDate.setDate(startDate.getDate() + 1));
                if (endDate.getDay() !== 0) {
                    count++;

                }
            }
        } else {
            while (count <= noOfDaysToAdd) {
                endDate = new Date(startDate.setDate(startDate.getDate() + 1));
                if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
                    count++;

                }
            }
        }
        this.setState({ maxDate: endDate })
    }

    eventClick(e) {
        var forApprovalForm = false
        var leaveForApproval = {}

        this.state.leaveApplicationsForApproval.map((forApproval) => {
            if (forApproval.id.toString() === e.event.id.toString()) {
                leaveForApproval = forApproval
                forApprovalForm = true
            }
        })
        this.setState({ leaveForApproval: leaveForApproval })
        this.setState({ openApprovalFormPopup: forApprovalForm })
        console.log(e.event.id)
        if (!forApprovalForm) {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + e.event.id,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {

                var item = resp.data
                item["from_morning"] = JSON.stringify(resp.data.from_morning)
                item["to_afternoon"] = JSON.stringify(resp.data.to_afternoon)
                this.setState({ leaveApplication: resp.data })
                this.setState({ appliedDays: parseFloat(resp.data.no_of_days) })
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + e.event.id + '/attachments',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    this.setState({ attachments: resp.data })
                    this.setState({ openPopup: true })
                }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
            }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
        }

    }

    onFileChange(e) {
        e.stopPropagation()
        e.preventDefault()
        this.setState({
            attachment: {
                ...this.state.attachment,
                file: e.target.files[0],
                desciption: this.state.leaveApplication.reason
            }
        })
    }

    cancel() {
        axios({
            method: 'put',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + this.state.leaveApplication.id + '/cancel',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                leaveApplications: this.state.leaveApplications.filter(item => item.id !== this.state.leaveApplication.id)
            })
            this.setState({
                events: this.state.events.filter(item => item.id !== this.state.leaveApplication.id)
            })
            this.setState({ openPopup: false })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    }

    handleChange(event, newValue) {
        this.setState({ value: newValue })
    }

    handleApprovalChange(e) {
        if (e.target.name === "new_officer_id") {
            this.setState({
                leaveForApproval: {
                    ...this.state.leaveForApproval,
                    officer_id: e.target.value
                }
            })
        } else {
            this.setState({
                leaveForApproval: {
                    ...this.state.leaveForApproval,
                    [e.target.name]: e.target.value
                }
            })
        }

    }

    handleShowPopUp(item) {
        this.setState({ leaveForApproval: item })
        this.setState({ openApprovalFormPopup: true })
    }

    approve() {
        const item = this.state.leaveForApproval
        axios({
            method: 'put',
            data: (item),
            url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + this.state.leaveForApproval.id + '/approve',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') },
        }).then(resp => {
            this.setState({
                leaveApplicationsForApproval: this.state.leaveApplicationsForApproval.filter(item => item.id !== this.state.leaveForApproval.id)
            })
            const newEvent = {
                color: "#357a38",
                id: resp.data.id,
                title: resp.data.reason + resp.data.employee_name,
                start: resp.data.starts_on,
                end: resp.data.ends_on
            }
            this.setState({
                events: this.state.events.filter(leaveApplication => leaveApplication.id !== newEvent.id)
            })
            this.setState({ events: [...this.state.events, newEvent] })
            this.setState({ openApprovalFormPopup: false })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    }

    reject() {
        const item = this.state.leaveForApproval
        axios({
            method: 'put',
            data: (item),
            url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + this.state.leaveForApproval.id + '/reject',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({
                leaveApplicationsForApproval: this.state.leaveApplicationsForApproval.filter(item => item.id !== this.state.leaveForApproval.id)
            })
            this.setState({
                events: this.state.events.filter(item => item.id !== this.state.leaveApplicationsForApproval.id)
            })
            const newData = resp.data
            this.setState({ leaveApplications: [...this.state.leaveApplications, newData] })
            const newEvent = {
                color: "#d50000",
                id: resp.data.id,
                title: resp.data.reason + resp.data.employee_name,
                start: resp.data.starts_on,
                end: resp.data.ends_on
            }
            this.setState({
                events: this.state.events.filter(leaveApplication => leaveApplication.id !== newEvent.id)
            })
            this.setState({ events: [...this.state.events, newEvent] })
            this.setState({ openApprovalFormPopup: false })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    }

    forward() {
        const item = this.state.leaveForApproval
        axios({
            method: 'put',
            data: (item),
            url: process.env.REACT_APP_API_DOMAIN + '/v1/leave_applications/' + this.state.leaveForApproval.id + '/transfer',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({
                leaveApplicationsForApproval: this.state.leaveApplicationsForApproval.filter(item => item.id !== this.state.leaveForApproval.id)
            })
            this.setState({
                events: this.state.events.filter(item => item.id !== this.state.leaveApplicationsForApproval.id)
            })
            this.setState({ openApprovalFormPopup: false })
        }).catch(error => { this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' }) })
    }

    toggleView() {
        this.setState({ showRejectedLeaves: !this.state.showRejectedLeaves })
    }



    render() {

        var eventSources = []
        const eventsWithRejected = this.state.events
        const eventsWithOutRejected = this.state.events.filter(item => item.color !== '#d50000')
        if (this.state.showRejectedLeaves) {
            eventSources = eventsWithRejected
        } else {
            eventSources = eventsWithOutRejected
        }
        if (this.state.redirect) {
            if (this.state.leaveApplication.id === "") {
                return (<Redirect to={{
                    pathname: '/leave_application/new'
                }} />)
            }
        }
        const approvedLeaves = this.state.leaveApplications.filter(item => item.request_status === "approved")
        const pendingLeaves = this.state.leaveApplications.filter(item => item.request_status === "pending").sort(
            (a, b) => new Date(a.starts_on).getTime() - new Date(b.starts_on).getTime()
        )
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <div className={styles.tableTitle}>
                                <div className={styles.groupTileSearch}>
                                    <div className={styles.tileBody}>
                                        {/* {this.props.icon} */}
                                        <h2 className={styles.title}>
                                            <div className={styles.head}>
                                                <div>Leave Applications</div>
                                            </div>
                                        </h2>
                                    </div>

                                </div>
                                <div className={styles.rightAction}>
                                    <div>
                                        <IconButton color="inherit" aria-label="menu"
                                            id={`${this.state.model.toLowerCase().replace(/ /g, '_')}_add`}
                                            name={`${this.state.model.toLowerCase().replace(/ /g, '_')}_add`}
                                            onClick={() => this.handleCreateorUpdateItem(this.state.initialLeaveApplication, true, this.state.model)}>
                                            <AddCircleOutlineIcon fontSize="large" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Calendar" {...a11yProps('calendar')} />
                                    <Tab label="Summary" {...a11yProps('summary')} />
                                    <Tab label="Approved" {...a11yProps('approved')} />
                                    <Tab label="Pending" {...a11yProps('pending')} />
                                    <Tab label="Archived" {...a11yProps('archive')} />
                                    <Tab label="Request" {...a11yProps('request')} />
                                </Tabs>
                                <TabPanel className={styles.tabBody} value={this.state.value} index={0}>
                                    <FullCalendar
                                        initialView="dayGridMonth"
                                        defaultView="timeGridDay"
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        eventColor='#80cbc4'
                                        headerToolbar={{
                                            left: 'title',
                                            right: 'toggleButton,prev,next',

                                        }}
                                        customButtons={{
                                            toggleButton: {
                                                text: this.state.showRejectedLeaves ? ('Hide Rejected') : ('Show Rejected'),
                                                click: this.toggleView
                                            }
                                        }}
                                        events={eventSources}
                                        eventClick={this.eventClick}
                                        nowIndicator='true'
                                    />
                                </TabPanel>
                                <TabPanel className={styles.tabBody} value={this.state.value} index={1}>
                                    <SimpleTable
                                        removeLabel={true}
                                        noActionHeader={true}
                                        handleClick={this.handleCreateorUpdateItem}
                                        items={this.state.leaveCredits}
                                        model="Leave Credit"
                                        nodelete={true}
                                        headers={['leave_type.name', 'no_of_days', 'days_approved', 'days_remaining', 'days_applied']}
                                    />
                                </TabPanel>
                                <TabPanel className={styles.tabBody} value={this.state.value} index={2}>
                                    <SimpleTable
                                        removeLabel={true}
                                        noActionHeader={true}
                                        handleClick={this.handleCreateorUpdateItem}
                                        items={approvedLeaves}
                                        model="Aproved Leave"
                                        nodelete={true}
                                        headers={['leave_type.name', 'without_pay', 'inclusive_dates', 'no_of_days', 'reason', 'officer.name']}
                                    />
                                </TabPanel>
                                <TabPanel className={styles.tabBody} value={this.state.value} index={3}>
                                    <SimpleTable
                                        removeLabel={true}
                                        handleClick={this.handleCreateorUpdateItem}
                                        noActionHeader={true}
                                        onOpenDeletePopup={this.handleOpenDeletePopup}
                                        items={pendingLeaves}
                                        model="Pending Leave"
                                        headers={['leave_type.name', 'inclusive_dates', 'no_of_days']}
                                        withEdit={true}
                                        withDelete={true}
                                    />
                                </TabPanel>
                                <TabPanel className={styles.tabBody} value={this.state.value} index={4}>
                                    <SimpleTable
                                        removeLabel={true}
                                        handleClick={this.handleCreateorUpdateItem}
                                        noActionHeader={true}
                                        nodelete={true}
                                        items={this.state.leaveApplications}
                                        model="Archived Leave"
                                        headers={['leave_type.name', 'without_pay', 'inclusive_dates', 'no_of_days', 'reason', 'officer.name', 'request_status']}

                                    />
                                </TabPanel>
                                <TabPanel className={styles.tabBody} value={this.state.value} index={5}>
                                    <SimpleTable
                                        removeLabel={true}
                                        handleClick={this.handleCreateorUpdateItem}
                                        items={this.state.leaveApplicationsForApproval}
                                        model="Request"
                                        noActionHeader={true}
                                        nodelete={true}
                                        withShowPopUp={true}
                                        handleShowPopUp={this.handleShowPopUp}
                                        headers={['employee_name', 'leave_type.name', 'inclusive_dates', 'no_of_days', 'reason', 'officer.name', 'request_status']}
                                    />
                                </TabPanel>
                            </div>


                            <SimplePopUp
                                openPopup={this.state.openPopup}
                                handleClose={this.handleClose}
                                maxWidth={this.state.maxWidth}
                                title={this.state.title}
                            >
                                <LeaveApplicationForm
                                    error={this.state.error_messages}
                                    item={this.state.leaveApplication}
                                    leaveCredits={this.state.leaveCredits}
                                    submit={this.handleSubmit}
                                    handleChange={this.handleInputChange}
                                    approvingOfficers={this.state.approvingOfficers}
                                    handleSwitch={this.handleSwitch}
                                    handleStartDateChange={this.handleStartDateChange}
                                    handleEndDateChange={this.handleEndDateChange}
                                    maxDate={this.state.maxDate}
                                    appliedDays={this.state.appliedDays}
                                    onFileChange={this.onFileChange}
                                    cancel={this.cancel}
                                    attachments={this.state.attachments}
                                    delete={this.handleDeleteItem}
                                />
                            </SimplePopUp>

                            <SimplePopUp
                                openPopup={this.state.openApprovalFormPopup}
                                handleClose={() => this.setState({ openApprovalFormPopup: !this.state.openApprovalFormPopup })}
                                maxWidth="sm"
                                title="Request Leave"
                            >
                                <ApprovalForm
                                    item={this.state.leaveForApproval}
                                    handleChange={this.handleApprovalChange}
                                    attachments={this.state.attachments}
                                    approvingOfficers={this.state.approvingOfficers}
                                    approve={this.approve}
                                    reject={this.reject}
                                    forward={this.forward}

                                />
                            </SimplePopUp>
                            <SimpleDeletePopUp
                                openDeletePopup={this.state.openDeletePopup}
                                item={this.state.leaveApplication}
                                delete={this.handleDeleteLeaveApplication}
                                handleDeleteClose={this.handleClose}
                                model="leave application"
                            />
                            <AlertMessage
                                notify={this.state.notify}
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />

                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default LeaveApplications
