// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import DivisionsForm from './DivisionsForm'
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

class Divisions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Division',
      divisions: [],
      branches: [],
      departments: [],
      updateBranch: {},
      updateDepartment: {},
      openPopup: false,
      openDeletePopup: false,
      division: {},
      initialDivisions: {
        id: '',
        branch_id: '',
        branch_department_id: '',
        name: '',
        short_name: '',
        description: ''
      },
      title: '',
      load: false,
      tableHead: ['branch.name', 'department.name', 'Name', 'short_name', 'description'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
  }

  componentDidMount() {
    this.loadDivisions(process.env.REACT_APP_API_DOMAIN + '/v1/divisions')
  }

  loadDivisions(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({divisions: resp.data})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({branches: resp.data})
          this.setState({load: true})
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
  }

  handleSubmit() {
    const item = this.state.division
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/divisions/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/divisions/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        divisions: this.state.divisions.filter(division => division.id !== item.id)
      })
      this.setState({divisions: [...this.state.divisions, newData], updateBranch: {}, updateDepartment: {}})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.division.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/divisions/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        divisions: this.state.divisions.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
    this.setState({updateDepartment: []})
    this.setState({updateBranch: []})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      this.setState({updateBranch: item.branch})
      this.setState({updateDepartment: item.department})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + item.branch.id + '/branch_departments',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        var depts =[]
        resp.data.map((item)=>{
          var newItem = item.department
          newItem['id']= item.id
          depts.push(newItem)
        })
        this.setState({ departments: depts })  
      }).catch(error => console.log(error.response))
    }
    this.setState({openPopup: true})
    this.setState({division: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      division: {
        ...this.state.division,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({division: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleAutocomplete(event, values) {
    if (event.target.id.includes("branch_id")) {
      this.setState({ updateBranch: values })
      this.setState({ updateDepartment: '' })
      if (values !== null) {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + values.id + '/branch_departments',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          var depts =[]
          resp.data.map((item)=>{
            var newItem = item.department
            newItem['id']= item.id
            depts.push(newItem)
          })
          this.setState({ departments: depts })
        }).catch(error => console.log(error.response))
        this.setState({
          division: {
            ...this.state.division,
            branch_id: values.id,
            branch_department_id: ''
          }
        })
      }else{
        this.setState({ departments: []})
        this.setState({
          division: {
            ...this.state.division,
            branch_id: '',
            branch_department_id: ''
          }
        })
      }
    } else {
      this.setState({ updateDepartment: values })
      if(values!==null){
        this.setState({
          division: {
            ...this.state.division,
            branch_department_id: values.id
          }
        })
      }else{
        this.setState({
          division: {
            ...this.state.division,
            branch_department_id: ''
          }
        })
      }
      
    }
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialDivisions}
                items={this.state.divisions}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<AccountBoxOutlinedIcon fontSize="large"/>}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <DivisionsForm
                  error={this.state.error_messages}
                  item={this.state.division}
                  branches={this.state.branches}
                  departments={this.state.departments}
                  updateBranch={this.state.updateBranch}
                  updateDepartment={this.state.updateDepartment}
                  handleAutocomplete={this.handleAutocomplete}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.division}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default Divisions
