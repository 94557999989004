import React from 'react'
import styles from './TaxTables.module.css'
import PropTypes from 'prop-types'
import {
  TextField,
  Button,
  MenuItem
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
class TaxForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <div className={styles.groupFields}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="payroll_no"
              fullWidth="true"
              label="Payroll Type"
              onChange={this.props.onchange}
              value={this.props.item.payroll_no}
              select
            >
              <MenuItem value={0}>Daily</MenuItem>
              <MenuItem value={1}>Weekly</MenuItem>
              <MenuItem value={2}>Semi-Monthly</MenuItem>
              <MenuItem value={3}>Monthly</MenuItem>
            </TextField>
            <TextField
              autoComplete="off"
              className={styles.amountBody}
              variant="outlined"
              size="small"
              type="number"
              name="column_no"
              fullWidth="true"
              label="Column Number"
              onChange={this.props.onchange}
              value={this.props.item.column_no}
            />
          </div>
          <div className={styles.groupFields}>
          <TextField
            type="date"
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="starts_at"
            fullWidth={true}
            label="Start Date"
            onChange={this.props.onchange}
            value={this.props.item.starts_at}
            
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            type="date"
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="valid_at"
            fullWidth={true}
            label="Date Effective"
            onChange={this.props.onchange}
            value={this.props.item.valid_at}
            
            InputLabelProps={{
              shrink: true
            }}
          />
          </div>
          <div className={styles.groupFields}>
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              className={styles.amountBody}
              size="small"
              name="exemption"
              fullWidth
              label="Exemption"
              thousandSeparator={true}
              onValueChange={(event) => this.props.handleAmountChanges('exemption', event)}
              value={parseFloat(this.props.item.exemption).toFixed(2)}
              decimalSeparator="."
              fixedDecimalScale={false}
              decimalScale={2}
            />
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              className={styles.amountBody}
              size="small"
              name="over_percentage"
              fullWidth
              label="Over Percentage"
              thousandSeparator={true}
              onValueChange={(event) => this.props.handleAmountChanges('over_percentage', event)}
              value={parseFloat(this.props.item.over_percentage).toFixed(2)}
              decimalSeparator="."
              fixedDecimalScale={false}
              decimalScale={2}
            />
          </div>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined"
            id="divisions-submit" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}
export default TaxForm
TaxForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  handleAccountChange: PropTypes.func,
  handleAmountChanges: PropTypes.func
}
