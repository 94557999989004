import React from 'react'
import styles from './LeaveApplications.module.css'
import PropTypes from 'prop-types'
import {
    TextField,
    Button,
    MenuItem, Grid, Radio, IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from '@material-ui/core/Switch'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class LeaveApplicationForm extends React.Component {
    render() {

        const disableWeekends = (date) => {
            if (this.props.item.with_sat) {
                return date.getDay() === 0;
            } else {
                return date.getDay() === 0 || date.getDay() === 6;
            }
        }
        return (
            <div>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="creditable_id"
                                select
                                fullWidth
                                label="Leave Type"
                                onChange={this.props.handleChange}
                                value={this.props.item.creditable_id || ''}
                                id="leaveApplication-creditable_id"
                            >
                                {
                                    this.props.leaveCredits.length > 0 ? (
                                        this.props.leaveCredits.map((credit) => (
                                            <MenuItem key={credit.id} value={credit.id}>{credit.leave_type_with_days}</MenuItem>
                                        ))
                                    ) : null

                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                select
                                autoComplete="off"
                                className={styles.textFields}
                                variant="outlined"
                                size="small"
                                name="officer_id"
                                fullWidth
                                label="Approving Officer"
                                onChange={this.props.handleChange}
                                value={this.props.item.officer_id || ''}
                                id="leaveApplication-officer_id"

                            >
                                {
                                    this.props.approvingOfficers.length > 0 ? (
                                        this.props.approvingOfficers.map((approvingOfficer) => (
                                            <MenuItem key={approvingOfficer.id} value={approvingOfficer.id}>{approvingOfficer.name}</MenuItem>
                                        ))
                                    ) : null

                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Switch
                                name="leaveApplication_with_sat"
                                checked={this.props.item.with_sat}
                                onChange={this.props.handleSwitch}
                            /><b>Include Saturday :</b>
                        </Grid>


                        <Grid item xs={7}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <KeyboardDatePicker
                                        clearable
                                        autoOk={true}
                                        style={{ width: '50%' }}
                                        format="MM/dd/yyyy"
                                        shouldDisableDate={disableWeekends}
                                        name="starts_on"
                                        invalidDateMessage=""
                                        onChange={this.props.handleStartDateChange}
                                        onInputChange={(date)=>this.props.handleStartDateChange(date)}
                                        value={this.props.item.starts_on}
                                        disabled={!this.props.item.creditable_id}
                                        
                                    />


                                    <RadioGroup disabled={!this.props.item.creditable_id} row
                                        value={this.props.item.from_morning || ''} name="from_morning"
                                        onChange={this.props.handleChange} style={{ marginLeft: '8px' }}>
                                        <FormControlLabel disabled={!this.props.item.creditable_id} value="true" control={<Radio />} label="AM" />
                                        <FormControlLabel disabled={!this.props.item.creditable_id} value="false" control={<Radio />} label="PM" />
                                    </RadioGroup>


                                </div>
                                <div>&nbsp;&nbsp;</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <KeyboardDatePicker
                                        clearable
                                        autoOk={true}
                                        minDate={this.props.item.starts_on}
                                        maxDate={this.props.maxDate}
                                        invalidDateMessage=""
                                        format="MM/dd/yyyy"
                                        style={{ width: '50%' }}
                                        name="ends_on"
                                        onChange={this.props.handleEndDateChange}
                                        value={this.props.item.ends_on}
                                        shouldDisableDate={disableWeekends}
                                        disabled={!this.props.item.creditable_id}
                                    />

                                    <RadioGroup disabled={!this.props.item.creditable_id} row
                                        value={this.props.item.to_afternoon || ''} name="to_afternoon"
                                        onChange={this.props.handleChange} style={{ marginLeft: '8px' }}>

                                        <FormControlLabel disabled={!this.props.item.creditable_id} value="false" control={<Radio />} label="AM" />
                                        <FormControlLabel disabled={!this.props.item.creditable_id} value="true" control={<Radio />} label="PM" />
                                    </RadioGroup>

                                </div>

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={5}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ fontSize: '80px' }}>{this.props.appliedDays ? (this.props.appliedDays.toString()) : ('0')}</div>
                                <div style={{ fontSize: '40px' }}>{this.props.appliedDays > 1 ? ("Days") : ("Day")}</div>
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                    <label >Attach Supporting Document:</label>

                    <input type="file"
                        id="attachment" name="attachment"
                        value={this.props.item.attachment}
                        onChange={this.props.onFileChange}
                    ></input>
                    {
                        this.props.attachments.length > 0 ? (
                            this.props.attachments.map((file, idx) => (
                                <div key={idx}> <Link target="_blank" to={file.file_url}>{file.file_name}</Link> 
                                <IconButton
                                onClick={()=>this.props.delete(file.id)}
                                ><DeleteIcon /></IconButton></div>
                            ))
                        ) : null
                    }
                    <TextField
                        autoComplete="off"
                        className={styles.textFields}
                        variant="outlined"
                        size="small"
                        multiline={true}
                        rows={5}
                        name="reason"
                        fullWidth
                        label="Reason"
                        onChange={this.props.handleChange}
                        value={this.props.item.reason}
                        id="leaveApplication-reason"
                    />
                    <br />
                </form>

                <div className={styles.actionButton}>
                    <Button variant="outlined"
                        disabled={!this.props.item.creditable_id || !this.props.item.officer_id || !this.props.item.reason}
                        id="leaveApplication-submit"
                        color="primary" onClick={() => this.props.submit()}
                    >Save</Button>
                    {
                        this.props.item.id ? (
                            <Button variant="outlined"
                                id="leaveApplication-cancel"
                                color="secondary" onClick={() => this.props.cancel()}
                            >Cancel Application</Button>
                        ) : (null)
                    }
                </div>
            </div>
        )
    }
}
export default LeaveApplicationForm

LeaveApplicationForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    children: PropTypes.object,
    submit: PropTypes.func,
    leaveCredits: PropTypes.array,
    approvingOfficers: PropTypes.array,
    handleSwitch: PropTypes.func,
    handleStartDateChange: PropTypes.func,
    handleEndDateChange: PropTypes.func,
    maxDate: PropTypes.func,
    appliedDays: PropTypes.number,
    onFileChange: PropTypes.func,
    cancel: PropTypes.func,
    attachments: PropTypes.array,
    delete: PropTypes.func
}
