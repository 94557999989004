import React from 'react'
import styles from './Memos.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
// eslint-disable-next-line 
import Trix from "trix"; 

class MemoForm extends React.Component {
	render() {
		return (
			<div>
				<form>
					<TextField
						autoComplete="off"
						className={styles.textFields}
						variant="outlined"
						size="small"
						name="title"
						fullWidth
						label="Title"
						onChange={this.props.handleChange}
						value={this.props.item.title}
						id="memo-title"
					/>
					<div className={styles.autoCompleteField}>
						<Autocomplete
							value={this.props.selectedEmployees}
							id="employees"
							multiple
							name="employees"
							fullWidth
							size="small"
							options={(this.props.employees).sort(function (a, b) {
								if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
								if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
								return 0;
							})}
							getOptionLabel={(option) => option.name_formal || ''}
							getOptionSelected={(option, value) => option.name_formal === value.name_formal}
							onChange={this.props.handleAutocomplete}
							renderInput={(params) => (
								<TextField {...params} name="employees" variant="outlined" label="Employees" />
							)}
						/>
						<p className={styles.error}>{this.props.error.employees ? 'Employee ' + this.props.error.officer : null}</p>
					</div>
					{
						!this.props.currentUser.is_employee?
						<div className={styles.autoCompleteField}>
						<br />
						<Autocomplete
							value={this.props.officer}
							id="officer"
							name="officer"
							fullWidth
							size="small"
							options={(this.props.employees).sort(function (a, b) {
								if (a.name_formal.toLowerCase() < b.name_formal.toLowerCase()) return -1;
								if (a.name_formal.toLowerCase() > b.name_formal.toLowerCase()) return 1;
								return 0;
							})}
							getOptionLabel={(option) => option.name_formal || ''}
							getOptionSelected={(option, value) => option.name_formal === value.name_formal}
							onChange={this.props.handleAutocomplete}
							renderInput={(params) => (
								<TextField {...params} name="officer" variant="outlined" label="Officer" />
							)}
						/>
						<p className={styles.error}>{this.props.error.officer ? 'Officer ' + this.props.error.officer : null}</p>
					</div>:null
					}
					
					<br />
					<ReactTrixRTEToolbar toolbarId="react-trix-rte-editor" />
					<ReactTrixRTEInput
						className={styles.editor}
						toolbarId="react-trix-rte-editor"
						defaultValue={this.props.item.content}
						name="content"
						id="memo-content"
						placeholder="Write your memo here..."
						onChange={this.props.handleEditor}
					/>
					<br />
				</form>

				<div className={styles.actionButton}>
					<Button variant="outlined" disabled={!this.props.item.title || !this.props.item.content} id="memo-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
				</div>
			</div>
		)
	}
}
export default MemoForm

MemoForm.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	children: PropTypes.object,
	submit: PropTypes.func,
	selectedEmployees: PropTypes.array,
	employees: PropTypes.array,
	handleAutocomplete: PropTypes.func,
	handleEditor: PropTypes.func,
	currentUser: PropTypes.object,
	officer: PropTypes.object,
	error: PropTypes.object
}
