// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import BranchesForm from './BranchesForm'
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import styles from "./Branches.module.css"
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import { Grid, Button } from "@material-ui/core";
import BranchDepartmentForm from "./BranchDepartmentForm";
import DepartmentsForm from "../Departments/DepartmentsForm";

class Branches extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Branche',
      branches: [],
      departments: [],
      branchDepartments: [],
      updatedbranchDepartments: [],
      openPopup: false,
      selectedDepartments: [],
      openDeletePopup: false,
      openDeletePopupDepartment: false,
      branch: {},
      department: {},
      intialBranch: {id: '', name: '', sort_name: '', address: '', telephone: '', fax_no: '', email: '', is_active: false, department_id: ''},
      title: '',
      load: false,
      tableHead: ['Name', 'address', 'telephone', 'fax_no', 'email'],
      withShow: false,
      openDepartmentForm: false,
      openAddDepartmentForm: false,
      withEdit: true,
      withShowPopUp: true,
      showPopUp: false,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
    this.handleDepartment = this.handleDepartment.bind(this)
    this.handleSubmitDepartment = this.handleSubmitDepartment.bind(this)
    this.handleOpenDeleteDepartment = this.handleOpenDeleteDepartment.bind(this)
    this.handleDeleteDepartmentItem = this.handleDeleteDepartmentItem.bind(this)
    this.handleCloseDepartmentForm = this.handleCloseDepartmentForm.bind(this)
    this.addDepartment = this.addDepartment.bind(this)
    this.handleDepartmentSubmit = this.handleDepartmentSubmit.bind(this)
    this.handleDepartmentInputChange = this.handleDepartmentInputChange.bind(this)
    this.cloeseDepartment = this.cloeseDepartment.bind(this)
  }

  componentDidMount() {
    this.loadbranches(process.env.REACT_APP_API_DOMAIN + '/v1/branches')
  }

  loadbranches(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({branches: resp.data})
      this.setState({load: true})
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({departments: resp.data})
    })
  }

  handleSubmit() {
    const item = this.state.branch
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/branches'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        branches: this.state.branches.filter(branch => branch.id !== item.id)
      })
      if(this.state.selectedDepartments.length>0){
        this.state.selectedDepartments.map((selected) => {
          var data = selected
          data['department_id'] = selected.id
          axios({
            method: 'post',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + resp.data.id + '/branch_departments',
            data: (data),
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            const newData = resp.data
            this.setState({
              branchDepartments: this.state.branchDepartments.filter(department => department.id !== data.id)
            })
            this.setState({branchDepartments: [...this.state.branchDepartments, newData]})
            this.setState({openDepartmentForm: false })
          }).catch(error => console.log(error.response))
        })
      }

      this.setState({branches: [...this.state.branches, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.branch.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        branches: this.state.branches.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleDeleteDepartmentItem() {
    const DeleteItemId = this.state.branch.id
    const ItemId =this.state.department.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + DeleteItemId + '/branch_departments/' + ItemId ,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        branchDepartments: this.state.branchDepartments.filter(item => item.id !== ItemId)
      })
      this.setState({openDeletePopupDepartment: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => console.log(error.response))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({showPopUp: false})
    this.setState({error_messages: ''})
    this.setState({selectedDepartments: []})
  }

  handleCloseDepartmentForm() {
    this.setState({openDepartmentForm: false})
    this.setState({selectedDepartments: []})
  }

  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + "Branch"
    } else {
      textTitle = 'Edit ' + "Branch"
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + item.id + '/departments',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ selectedDepartments: resp.data })
        
      })
    }
    this.setState({openPopup: true})
    this.setState({branch: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      branch: {
        ...this.state.branch,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({branch: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      branch: {
        ...this.state.branch,
        [event.target.name]: value
      }
    })
  }

  handleShowPopUp(item) {
    this.setState({branch: item})
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + item.id + '/branch_departments',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({branchDepartments: resp.data})
    })
      this.setState({showPopUp: true})
  }

  handleAutocomplete(event, values) {
      this.setState({selectedDepartments: values})
  }

  handleDepartment() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({departments: resp.data})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + this.state.branch.id + '/departments',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        resp.data.map((selected) => {
          this.setState({departments: this.state.departments.filter(department => department.id !== selected.id)})
        })
      }).catch(error => console.log(error.response))
    }).catch(error => console.log(error.response))
    this.setState({openDepartmentForm: true, department: {}})
  }

  handleSubmitDepartment() {
    if(this.state.selectedDepartments.length>0){
      this.state.selectedDepartments.map((selected) => {
        var data = selected
        data['department_id'] = selected.id
        axios({
          method: 'post',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + this.state.branch.id + '/branch_departments',
          data: (data),
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          const newData = resp.data
          this.setState({
            branchDepartments: this.state.branchDepartments.filter(department => department.id !== data.id)
          })
          this.setState({branchDepartments: [...this.state.branchDepartments, newData]})
          this.setState({openDepartmentForm: false })
        }).catch(error => console.log(error.response))
      })
    }
  }


  handleOpenDeleteDepartment(item){
    this.setState({department: item})
    this.setState({openDeletePopupDepartment: true})
  }

  addDepartment() {
    this.setState({ openAddDepartmentForm: true})
  }

  cloeseDepartment() {
    this.setState({ openAddDepartmentForm: false})
  }

  handleDepartmentSubmit() {
    const item = this.state.department
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/departments',
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({selectedDepartments: [...this.state.selectedDepartments, resp.data],departments: [...this.state.departments,resp.data],
         openAddDepartmentForm: false, isOpen: true, message: 'Submitted Successfully', type: 'success'})
    })
  }

  handleDepartmentInputChange(e) {
      this.setState({ department: { ...this.state.department, [e.target.name]: e.target.value } })
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.intialBranch}
                items={this.state.branches}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                withShowPopUp={this.state.withShowPopUp}
                icon={<SettingsInputCompositeOutlinedIcon fontSize="large"/>}
                headerTitle="Branches"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
                handleShowPopUp={this.handleShowPopUp}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <BranchesForm
                  error={this.state.error_messages}
                  item={this.state.branch}
                  submit={this.handleSubmit}
                  handleSwitch={this.handleSwitch}
                  onchange={this.handleInputChange}
                  handleAutocomplete={this.handleAutocomplete}
                  departments={this.state.departments}
                  addDepartment={this.addDepartment}
                  selectedDepartments={this.state.selectedDepartments}
                  handleSubmitDepartment={this.handleSubmitDepartment}
                  />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.showPopUp}
                title="Branch"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <>
                <Grid container spacing={1} className={styles.branchBodyDetails}>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <div><b>Name :</b></div>
                        <div><b>Telephone :</b></div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>{this.state.branch.name}</div>
                        <div>{this.state.branch.telephone}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <div><b>Fax No :</b></div>
                        <div><b>Email :</b></div>
                      </Grid>
                      <Grid item xs={8}>
                        <div>{this.state.branch.fax_no}</div>
                        <div>{this.state.branch.email}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                    <Grid item xs={2}>
                      <div><b>Address :</b></div>
                    </Grid>
                  <Grid item xs={9}>
                      <div>{this.state.branch.address}</div>
                  </Grid>
                </Grid>
                <SimpleTable
                  model="Department"
                  headerTitle="Departments"
                  headers={['department.name', 'department.short_name']}
                  items={this.state.branchDepartments}
                  onOpenDeletePopup={this.handleOpenDeleteDepartment}
                  hasDeleteButton={true}
                  createUsingSimplePopup={true}
                  handleClick={this.handleDepartment}
                />
                </>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openDepartmentForm}
                title="Department"
                handleClose={this.handleCloseDepartmentForm}
                maxWidth={this.state.maxWidth}>
                <>
                <BranchDepartmentForm
                  handleAutocomplete={this.handleAutocomplete}
                  departments={this.state.departments}
                  addDepartment={this.addDepartment}
                  selectedDepartments={this.state.selectedDepartments}
                  
                />
                <div className={styles.actionButton}>
                  <Button variant="outlined"  id="expenseType-submit" color="primary"
                          onClick={() => this.handleSubmitDepartment()}>Save</Button>
                </div>
                </>
              </SimplePopUp>
              
               <SimplePopUp
                openPopup={this.state.openAddDepartmentForm}
                title="Department"
                handleClose={this.cloeseDepartment}
                maxWidth={this.state.maxWidth}>
                <DepartmentsForm
                 item={this.state.department}
                 submit={this.handleDepartmentSubmit}
                 onchange={this.handleDepartmentInputChange}
                />
              </SimplePopUp>



              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopupDepartment}
                item={this.state.department}
                delete={this.handleDeleteDepartmentItem}
                handleDeleteClose={this.handleClose}
                model="Department"
              />
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.branch}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Branch"
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default Branches
